import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import _ from 'lodash';
import { ModalComponent } from '../components/modal/modal.component';
import { IModalData } from '../types/modal-data';
import { httpCodes } from './../constants/http-codes';
import { LocalStorageService } from './local-storage.service';
import { ModalService } from './modal.service';

/**
 * Error Service Wrapper.
 */
@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  constructor(
    private dialog: MatDialog,
    private modalService: ModalService,
    private localStorage: LocalStorageService
  ) {}

  /**
   * This method is used to open (show) error modal and, returns it's current reference.
   * Uses Angular Material dialog.
   */
  openDialog<C>(
    error: any,
    modalData?: IModalData,
    options?: any
  ): MatDialogRef<ModalComponent> {
    modalData = this.getCodeData(error, modalData || ({} as IModalData));
    return this.modalService.openDialog(modalData, options, ModalComponent);
  }

  private getCodeData(error, modalData: IModalData): IModalData {
    const status = _.get(error, 'status', '500');
    modalData.title = httpCodes[status].title;
    modalData.ImageSrc = httpCodes[status].img;
    modalData.message =
      _.get(error, 'error.errors.message', httpCodes[status].message) ||
      _.get(error, 'statusText');
    modalData.displayRedirectButton = _.get(
      modalData,
      'displayRedirectButton',
      false
    );
    return modalData;
  }
}
