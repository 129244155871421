import { get } from 'lodash';

interface IDeserializable {
  deserialize(input: any): this;
}

export class PromotionItem implements IDeserializable {
  heading?: string;
  subHeading?: string;
  name?: string;
  description?: string;

  deserialize(input: any): this {
    this.heading = get(input, 'heading');
    this.subHeading = get(input, 'subHeading');
    this.name = get(input, 'priority');
    this.description = get(input, 'description');

    return this;
  }
}
