import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'myclubrewards-editable-card-container',
  templateUrl: './editable-card-container.component.html',
  styleUrls: ['./editable-card-container.component.scss']
})
export class EditableCardContainerComponent implements OnInit {

 
  configArray = []
  @Input('config') set config(value) {
    this.configArray = [];
    if(value) {
      this.configArray = value
    }
  }

  constructor() {

  }

  ngOnInit(): void { 
  }
}
