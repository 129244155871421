import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from '@myclubrewards/shared';
import { Observable, Subscription } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { PatronEvent } from '../../../models/patron-event';
import { ModifyEventComponent } from '../../modals/modify-event/modify-event.component';
import { PatronBookedOfferService } from './../../../services/patron-booked-offer.service';
import { ProfileService } from '../../../services/profile-service.service';

@Component({
  selector: 'myclubrewards-mcr-patron-offers',
  templateUrl: './patron-offers.component.html',
  styleUrls: ['./patron-offers.component.scss'],
})
export class McrPatronOffersComponent implements OnInit, OnDestroy {
  subscription: Subscription;
  displayLoader: boolean = true;
  bookedOffers$: Observable<PatronEvent[]>;
  context!: any;

  config: any = {
    paths: ['name', 'bookedTickets', 'eventDateFormatted', 'imageSrc'],
    layout: 'horizontalCardsLayout',
    showModifyCancel: true,
    minLength: 6,
  };

  constructor(
    private patronBookedOfferService: PatronBookedOfferService,
    private modalService: ModalService,
    private profileService: ProfileService
  ) {
    this.subscription = new Subscription();
    this.bookedOffers$ = this.patronBookedOfferService.bookedOffers$;
    this.context = this;
  }

  private getBookedOffers() {
    this.subscription.add(
      this.patronBookedOfferService.getBookedOffers().subscribe()
    );

    this.subscription.add(
      this.bookedOffers$
        .pipe(
          catchError((err) => {
            this.displayLoader = false;
            return err;
          }),
          finalize(() => {
            this.displayLoader = false;
          })
        )
        .subscribe()
    );
  }

  ngOnInit(): void {
    this.getBookedOffers();
    this.getUserProfile();
  }

  modifyCancel(item: PatronEvent): void {
    this.modalService.openDialog<ModifyEventComponent>(
      item,
      {},
      ModifyEventComponent
    );
  }

  getUserProfile() {
    this.profileService.getPlayerProfile();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
