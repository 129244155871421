<div class="wrapper">
  <ng-template
    *ngIf="config?.layout === 'horizontalCardsLayout'"
    [ngTemplateOutlet]="horizontalCardsLayout"
  ></ng-template>
</div>

<ng-template #horizontalCardsLayout>
  <button
    *ngIf="data"
    #leftScrollBtn
    class="flip-horizontally scroll-btn left-btn"
  >
    <span class="material-icons">
      play_arrow
    </span>
  </button>
  <div *ngIf="data" class="cards" #wrapper_cards_container>
    <div class="card" *ngFor="let item of data">
      <img class="image" [src]="get(item, 3)" />
      <div class="container">
        <p class="title">{{ get(item, 0) }}</p>
        <p class="points">Casino Rama Resort</p>
        <p class="date">
          {{ item?.eventDateFormatted }}
        </p>
        <p class="points">
          Tickets Left:
          {{
            getMinimumValue([item.patronTicketsRemaining, item.availableSeats])
          }}
        </p>
        <p class="ticketCostPoints">
          ${{ item?.ticketCostPoints
          }}<span class="pl-5 light-text">Per ticket</span>
        </p>
      </div>
      <hr class="seperator" />
      <div
        class="modify-cancel-btn"
        (click)="context?.modifyCancel(item)"
        *ngIf="config?.showModifyCancel"
      >
        MODIFY/CANCEL
      </div>
    </div>
  </div>
  <button
    [ngStyle]="{
      display: data && data?.length >= config.minLength ? '' : 'none'
    }"
    #rightScrollBtn
    class="scroll-btn right-btn"
  >
    <span class="material-icons">
      play_arrow
    </span>
  </button>
  <mat-slider
    (change)="scroller($event)"
    [value]="scrollWidth"
    [ngStyle]="{
      display: data && data?.length >= config.minLength ? '' : 'none'
    }"
  ></mat-slider>
</ng-template>
