<div class="wrapper" [formGroup]="profileForm" *ngIf="!displayLoader">
  <div class="wrapper--header">
    <div class="left">
      <img class="arrow" src="assets/right-arrow.png" alt="right-arrow" />
      <div class="heading">PROFILE</div>
    </div>
    <div class="right" *ngIf="!displayLoader">
      <a (click)="changedPassword()" *ngIf="!showAstext">Change Password</a>
      <a
        (click)="showAstext = !showAstext; disableSpecifields()"
        *ngIf="!showAstext"
        >Edit Profile</a
      >
      <div>
        <button
          class="login-button"
          type="submit"
          *ngIf="showAstext"
          (click)="updateProfilePopup()"
          [disabled]="profileForm.invalid"
          [ngClass]="{
            'is-disabled': profileForm.invalid
          }"
        >
          SUBMIT CHANGES
        </button>
      </div>
      <a *ngIf="showAstext" (click)="cancelFormSubmission()">Cancel</a>
    </div>
  </div>
  <div class="scroller_form">
    <div class="feilds">
      <div class="feild">
        <p class="label">NAME</p>
        <div class="value">
          {{ profileForm.controls.name.value }}
        </div>
      </div>
      <div class="feild">
        <p class="label">ID</p>
        <div class="value">{{ profileForm.controls.id.value }}</div>
      </div>
    </div>

    <ng-container [formGroup]="profileForm.controls.playerAddress">
      <div class="feilds">
        <div class="feild">
          <p class="label">ADDRESS</p>
          <div class="value">
            <span *ngIf="!showAstext">{{
              profileForm.controls.playerAddress.get('addressLine1').value
            }}</span>
            <input
              type="text"
              *ngIf="showAstext"
              formControlName="addressLine1"
              class="input-feild address_edit"
              name="address"
              appTrim
            />
          </div>
          <div
            class="invalidFieldAddress"
            *ngIf="
              profileForm.controls.playerAddress.get('addressLine1').errors
                ?.required && showAstext
            "
          >
            Please enter your Address
          </div>
        </div>
      </div>
      <div
        class="feilds"
        *ngIf="
          !!profileForm.controls.playerAddress.get('addressLine2').value &&
          !showAstext
        "
      >
        <div class="feild">
          <p class="label">ADDRESS LINE 2</p>
          <div class="value">
            <span *ngIf="!showAstext">{{
              profileForm.controls.playerAddress.get('addressLine2').value
            }}</span>
          </div>
        </div>
      </div>
      <div class="feilds" *ngIf="showAstext">
        <div class="feild">
          <p class="label">ADDRESS LINE 2</p>
          <div class="value">
            <input
              type="text"
              *ngIf="showAstext"
              formControlName="addressLine2"
              class="input-feild address_edit"
              name="address"
              appTrim
            />
          </div>
        </div>
      </div>
      <!-- <div class="feilds" *ngIf="!!profileForm.controls.playerAddress.get('addressLine3').value && !showAstext">
        <div class="feild">
          <p class="label">ADDRESS LINE 3</p>
          <div class="value">
            <span *ngIf="!showAstext">{{
              profileForm.controls.playerAddress.get('addressLine3').value
            }}</span>
          </div>
        </div>
      </div>
      <div class="feilds" *ngIf="showAstext">
        <div class="feild">
          <p class="label">ADDRESS LINE 3</p>
          <div class="value">
            <input type="text" *ngIf="showAstext" formControlName="addressLine3" class="input-feild address_edit"
              name="address" appTrim />
          </div>
        </div>
      </div> -->

      <!-- /// -->
      <div class="feilds">
        <div class="feild">
          <p class="label">CITY</p>
          <div class="value">
            <span *ngIf="!showAstext">{{
              profileForm.controls.playerAddress.get('city').value
            }}</span>
            <input
              type="text"
              *ngIf="showAstext"
              formControlName="city"
              class="input-feild address_edit"
              name="city"
              appTrim
            />
          </div>
          <div
            class="invalidFieldAddress"
            *ngIf="
              profileForm.controls.playerAddress.get('city').errors?.required &&
              showAstext
            "
          >
            Please enter your city
          </div>
        </div>
      </div>
      <div class="feilds">
        <div class="feild">
          <p class="label">PROVINCE</p>
          <div class="value">
            <span *ngIf="!showAstext">{{
              profileForm.controls.playerAddress.get('stateProvCode').value
            }}</span>
            <input
              type="text"
              *ngIf="showAstext"
              formControlName="stateProvCode"
              class="input-feild address_edit"
              name="province"
              appTrim
            />
          </div>
          <div
            class="invalidFieldAddress"
            *ngIf="
              profileForm.controls.playerAddress.get('stateProvCode').errors
                ?.required && showAstext
            "
          >
            Please enter your province
          </div>
        </div>
      </div>
      <div class="feilds">
        <div class="feild">
          <p class="label">COUNTRY</p>
          <div class="value">
            <span *ngIf="!showAstext">{{
              profileForm.controls.playerAddress.get('countryCode').value
            }}</span>
            <input
              type="text"
              *ngIf="showAstext"
              formControlName="countryCode"
              class="input-feild address_edit"
              name="countryCode"
              appTrim
            />
          </div>
          <div
            class="invalidFieldAddress"
            *ngIf="
              profileForm.controls.playerAddress.get('countryCode').errors
                ?.required && showAstext
            "
          >
            Please enter your country code
          </div>
        </div>
      </div>
      <div class="feilds">
        <div class="feild">
          <p class="label">POSTAL CODE</p>
          <div class="value">
            <span *ngIf="!showAstext">{{
              profileForm.controls.playerAddress.get('postCode').value
            }}</span>
            <input
              type="text"
              *ngIf="showAstext"
              formControlName="postCode"
              class="input-feild address_edit"
              name="zipCode"
              appTrim
            />
          </div>
          <div
            class="invalidFieldAddress"
            *ngIf="
              profileForm.controls.playerAddress.get('postCode').errors
                ?.required && showAstext
            "
          >
            Please enter your postal code
          </div>
        </div>
      </div>
    </ng-container>

    <div class="feilds feilds--wrap">
      <ng-container [formGroup]="profileForm.controls.playerEmail">
        <div class="feild">
          <p class="label">EMAIL</p>
          <div class="value">
            <span *ngIf="!showAstext">{{
              profileForm.controls.playerEmail.get('emailAddress').value
            }}</span>
            <input
              type="email"
              *ngIf="showAstext"
              formControlName="emailAddress"
              class="input-feild"
              placeholder="john.doe@test.com"
              appTrim
            />
          </div>
          <div
            class="invalidField"
            *ngIf="
              profileForm.controls.playerEmail.get('emailAddress').errors
                ?.pattern && showAstext
            "
          >
            Please enter valid email Id
          </div>
          <!-- <div
          class="invalidField"
          *ngIf="
            profileForm.controls.playerEmail.get('emailAddress').errors
              ?.required && showAstext
          "
        >
          Please enter Email Id
        </div> -->
        </div>
      </ng-container>

      <ng-container [formGroup]="profileForm.controls.playerPhone">
        <div class="feild">
          <p class="label">PHONE NUMBER</p>
          <div class="value">
            <span *ngIf="!showAstext">{{
              profileForm.controls.playerPhone.get('phoneNumber').value
            }}</span>
            <input
              type="tel"
              *ngIf="showAstext"
              formControlName="phoneNumber"
              class="input-feild"
              appAvoidUnnecessaryCharacterDirective
              appTrim
            />
          </div>
          <div
            class="invalidField"
            *ngIf="
              profileForm.controls.playerPhone.get('phoneNumber').errors
                ?.pattern && showAstext
            "
          >
            Please enter valid Phone Number
          </div>
          <!-- <div
          class="invalidField"
          *ngIf="
            profileForm.controls.playerPhone.get('phoneNumber').errors
              ?.required && showAstext
          "
        >
          Please enter Phone Number
        </div> -->
        </div>
      </ng-container>
    </div>
  </div>

  <hr class="seperator" />

  <div class="wrapper--header added-style">
    <img class="arrow" src="assets/right-arrow.png" alt="right-arrow" />
    <div class="heading">COMMUNICATION PREFERENCES</div>
  </div>
  <ul
    class="toggle_list"
    [formGroup]="profileForm.controls.communicationPreferences"
  >
    <li class="item">
      <div class="label">Mail</div>
      <label class="switch">
        <input type="checkbox" formControlName="mail" />
        <span class="slider round"></span>
      </label>
    </li>
    <li class="item item-mail">
      <div class="label">Email</div>
      <label class="switch">
        <input type="checkbox" formControlName="email" />
        <span class="slider round"></span>
      </label>
    </li>
    <li class="item">
      <div class="label">Telephone</div>
      <label class="switch">
        <input type="checkbox" formControlName="telephone" />
        <span class="slider round"></span>
      </label>
    </li>
    <li class="item">
      <div class="label">Notifications</div>
      <label class="switch">
        <input type="checkbox" formControlName="notification" />
        <span class="slider round"></span>
      </label>
    </li>
  </ul>
</div>
<div *ngIf="displayLoader">
  <myclubshared-loader-component></myclubshared-loader-component>
</div>
