import { IEnvironmentConfig, ENVIRONMENT_CONFIG } from '@myclubrewards/shared';
import { Inject, Injectable } from '@angular/core';
import { User } from './../../account/src/lib/models/auth';
import { appConstants } from './../constants/app-constants';
import Cookies from 'js-cookie';

@Injectable({
  providedIn: 'root',
})
export class CookieStorageService {
  constructor(
    @Inject(ENVIRONMENT_CONFIG) private environment: IEnvironmentConfig
  ) {}

  setUserInCookies(user: User) {
    this.setCookies(appConstants.auth.userId, user.id);
    this.setCookies(appConstants.auth.cardType, user.cardType);
    this.setCookies(appConstants.auth.name, user.name);
    this.setCookies(appConstants.auth.accessToken, user.accountToken);
    this.setCookies(appConstants.auth.refreshToken, user.refreshToken);
  }

  setCookies(key: string, value: any) {
    Cookies.set(key, value);
  }

  setItem(key: string, value: any) {
    this.setCookies(key, value);
  }

  getUserIdFromCookies(): string {
    return Cookies.get('id');
  }

  checkLoginFromCookies(): boolean {
    const getUserName = Cookies.get(appConstants.auth.name);
    return !!getUserName ? true : false;
  }

  getItem(key: string) {
    return Cookies.get(key);
  }

  getUser() {
    return Cookies.get(appConstants.auth.name);
  }

  deleteCookie() {
    Cookies.remove(appConstants.auth.userId);
    Cookies.remove(appConstants.auth.cardType);
    Cookies.remove(appConstants.auth.name);
    Cookies.remove(appConstants.auth.accessToken);
    Cookies.remove(appConstants.auth.refreshToken);
  }
}
