import { get, map } from 'lodash';

interface IDeserializable {
  deserialize(input: any): this;
}
export class MembershipList implements IDeserializable {
  key: string;
  deserialize(input: any): this {
    this.key = get(input, 'key');
    return this;
  }
}

export class MembershipDetails implements IDeserializable {
  allRewardItems?: any;
  membershipName?: string;
  imageFr?: any[];
  imageEn?: any[];
  colorCode?: string;
  membershipBenefits?: MembershipList[];

  deserialize(input: any): this {
    this.membershipName = get(input, 'allRewardTypes.name', '-');
    this.imageFr = get(input, 'allRewardTypes.imageFr', []);
    this.imageEn = get(input, 'allRewardTypes.imageEn', []);
    this.colorCode = get(input, 'allRewardTypes.colorCode', '-');
    this.membershipBenefits = this.buildMembership(
      get(input, 'allRewardItems', [])
    );
    return this;
  }
  private buildMembership(input: any[]) {
    if (!input.length) return [];

    const tempCategories: MembershipList[] = map(input, (allRewardItems) => {
      return new MembershipList().deserialize(allRewardItems);
    });
    return tempCategories;
  }
}
