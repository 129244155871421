import {
  Directive,
  ElementRef,
  forwardRef,
  HostListener,
  Input,
  Renderer2,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, DefaultValueAccessor } from '@angular/forms';

const TRIM_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => TrimDirective),
  multi: true,
};

@Directive({
  selector: '[appTrim]',
  providers: [TRIM_VALUE_ACCESSOR],
})
export class TrimDirective extends DefaultValueAccessor {
  private el: ElementRef;
  private renderer: Renderer2;

  constructor(el: ElementRef, renderer: Renderer2) {
    super(renderer, el, true);
    this.el = el;
    this.renderer = renderer;
  }

  @HostListener('blur', ['$event.target.value', '$event'])
  ngOnChange = (val: string, e: any) => {
    switch (this.el.nativeElement.type) {
      case 'text':
        this.el.nativeElement.value = val.trim();
        this.onChange(val.trim());
        break;
      case 'password':
        this.el.nativeElement.value = val.trim();
        this.onChange(val.trim());
        break;
      case 'email':
        this.el.nativeElement.value = '';
        this.el.nativeElement.value = val.trim();
        this.onChange(val.trim());
        break;
      case 'tel':
        this.el.nativeElement.value = val.trim();
        this.onChange(val.trim());
        break;
      default:
        break;
    }
  };
}
