import { OfferMenuTab } from "./../../../models/offer-tab-model";
import { EventCategory } from "./../../../models/event-categories";
import { DOCUMENT } from "@angular/common";
import {
  AfterViewInit,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MomentDateAdapter,
} from "@angular/material-moment-adapter";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { ActivatedRoute, Router } from "@angular/router";
import { HomeMcrLocationService, HomeService } from "@myclubrewards/home";
import { DATE_FORMATS, LocalStorageService } from "@myclubrewards/shared";
import * as moment from "moment";
import {
  Subscription,
  Observable,
  BehaviorSubject,
  throwError,
  of,
  forkJoin,
} from "rxjs";
import {
  catchError,
  filter,
  finalize,
  map,
  switchMap,
  tap,
} from "rxjs/operators";
import { ExclusiveOfferService } from "../../../services/exclusive-offer.service";
import { get } from "lodash";
import { OfferDetails } from "../../../models/offer-modal";
import { PatronEvent } from "../../../models/patron-event";
import { ModalService } from "libs/shared/material/src/lib/services/modal.service";
import { BookEventComponent } from "../../modals/book-event/book-event.component";
import { BookPatronEventDTO } from "../../../dto/book-patron-event.dto";
import { BookingConfirmationComponent } from "../../modals/booking-confirmation/booking-confirmation.component";
import { PatronBookedOfferService } from "../../../services/patron-booked-offer.service";
import { DefaultComponent } from "../../modals/default/default.component";
import { TriggerEmailService } from "../../../services/trigger-email.service";

interface IOfferFilter {
  offerTypeCode: string;
  startDate?: any;
  endDate?: any;
  locations?: string[];
  eventCategories?: string[];
}

@Component({
  selector: "myclubrewards-accounts-exclusive-offer",
  templateUrl: "./accounts-exclusive-offer.component.html",
  styleUrls: ["./accounts-exclusive-offer.component.scss"],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: DATE_FORMATS },
  ],
})
export class AccountsExclusiveOfferComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild("filterMenu") private filterMenu: ElementRef;
  @ViewChild("exclusiveOfferContent") private exclusiveOfferContent: ElementRef;
  private subscription: Subscription;
  public selectedOfferType: string;
  selectedOfferTypeCode: string;
  private userId: string;
  public userSelectedLanguage: string;
  private applyFiltersSubj: BehaviorSubject<IOfferFilter>;

  config: any = {
    heading: "EXCLUSIVE OFFERS",
    subHeading: "Experience all we have to offer",
  };
  displayFilter: boolean = false;
  offerFilterForm: FormGroup;
  exclusiveOfferMenus$: Observable<OfferMenuTab[]>;
  exclusiveOffers$: Observable<OfferDetails[] | PatronEvent[]>;
  displayLoader: boolean;
  showLoader$: Observable<boolean>;
  isDataLoaded?: boolean;
  profile: any;

  minDate: Date;
  isBookButtonClicked: boolean[] = [];
  loader: boolean[] = [];
  countNumber: number = 0;
  loading = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private localStorageService: LocalStorageService,
    private homeLocatonService: HomeMcrLocationService,
    private homeService: HomeService,
    private fb: FormBuilder,
    private renderer: Renderer2,
    private offerService: ExclusiveOfferService,
    @Inject(DOCUMENT) private _document: Document,
    private modalService: ModalService,
    private patronBookedOfferService: PatronBookedOfferService,
    private triggerEmailService: TriggerEmailService
  ) {
    this.minDate = new Date();
    this.subscription = new Subscription();
    this.applyFiltersSubj = new BehaviorSubject<IOfferFilter>(undefined);
    this.exclusiveOfferMenus$ = this.offerService.OfferMenuTab$;
    this.exclusiveOffers$ = this.offerService.exclusiveOffers$;
    this.displayLoader = true;
    this.showLoader$ = this.offerService.showLoader$;
    this.isDataLoaded = false;
  }

  ngOnInit(): void {
    this.userId = this.localStorageService.getUserId();
    this.initFilterForm();
    this.registerListeners();
    this.getAllOfferFiltersTab();
    this.getAllLocations();
    this.allLocationsValueChange();
  }

  ngAfterViewInit(): void {
    this.detectClickOutside();
  }

  counter(index: number, type: string) {
    for (let i = 0; i <= index; i++) {
      if (type === "increment" && index === i) {
        this.countNumber++;
      } else {
        this.countNumber--;
      }
    }
  }

  /**
   *
   * @param index number rows
   */
  bookConcertTicket(index: number) {
    this.isBookButtonClicked[index] = true;
  }

  //#region private methods
  private initFilterForm(): void {
    this.offerFilterForm = this.fb.group({
      startDate: [moment(), [Validators.required]],
      endDate: [moment(), [Validators.required]],
      chooseAllLocations: [false],
      locationsFormArray: this.fb.array([]),
      eventsAndCategoriesFormArray: this.fb.array([]),
    });
  }

  private getAllOfferFiltersTab(): void {
    this.subscription.add(
      this.offerService.getAllOfferFiltersTab().subscribe()
    );
  }

  private getUpdatedLanguage(): void {
    this.userSelectedLanguage = localStorage.getItem("wglang") ?? "en";
  }

  private detectClickOutside(): void {
    const matDatePickerLoaded = this._document.getElementsByTagName(
      "mat-datepicker-content"
    );
    this.renderer.listen(
      this.exclusiveOfferContent?.nativeElement,
      "click",
      (e) => {
        if (
          !this.filterMenu?.nativeElement.contains(e.target) &&
          !matDatePickerLoaded.length
        ) {
          this.displayFilter = false;
        } else {
          e.stopPropogation && e.stopPropogation();
        }
      }
    );
  }

  categoriesFromService;

  private populateEventCategoriesFA(arr: EventCategory[]): void {
    if (!arr.length) return;

    this.categoriesFromService = arr;

    const eventFormArray = this.offerFilterForm.get(
      "eventsAndCategoriesFormArray"
    ) as FormArray;

    arr.forEach((item) => {
      eventFormArray.push(
        this.fb.group({
          name: get(item, "eventCategoryName"),
          isChecked: true,
          type: get(item, "eventCategoryType"),
        })
      );
    });

    this.selectedOfferType =
      this.localStorageService.getSavedFilterTab() ?? "F";
    this.getSavedFilters();
    this.applyOfferFilter();
  }

  private populateLocationsFA(arr: any[]): void {
    if (!arr.length) return;

    const locationsFA = this.offerFilterForm.get(
      "locationsFormArray"
    ) as FormArray;

    arr.forEach((data: any) => {
      locationsFA.push(
        this.fb.group({
          name: get(data, "name"),
          isChecked: false,
          locationCode: get(data, "locationCode"),
        })
      );
    });

    this.getSavedFilters();
    this.applyOfferFilter();
  }

  private getAllLocations(): void {
    this.homeService.queryHomePage();
  }

  private allLocationsValueChange(event?: any): void {
    this.subscription.add(
      this.offerFilterForm
        .get("chooseAllLocations")
        .valueChanges.pipe(
          tap((checked: boolean) => {
            const locationsFA = this.offerFilterForm.get(
              "locationsFormArray"
            ) as FormArray;

            locationsFA?.controls?.forEach((control: FormControl) => {
              control.patchValue({
                ...control.value,
                isChecked: checked,
              });
            });
          })
        )
        .subscribe()
    );
  }

  private maintainDifferenceBetweenDates(): void {
    const date: any = moment(this.offerFilterForm.value.startDate).add(
      3,
      "month"
    );
    this.offerFilterForm.patchValue({
      endDate: new Date(date),
    });
  }

  private saveFilterInSession(): void {
    this.localStorageService.setSessionStorage(
      this.offerFilterForm.getRawValue()
    );
  }

  private applyOfferFilter(): void {
    const selectedLocationsForFilteration = [];
    const isLocationSelected = this.offerFilterForm
      .get("locationsFormArray")
      .value.some((item: any) => !!item.isChecked);

    if (isLocationSelected) {
      this.offerFilterForm
        .get("locationsFormArray")
        .value.forEach((element) => {
          if (element.isChecked) {
            selectedLocationsForFilteration.push(element.name);
          }
        });
    }

    // this.displayLoader = true;

    this.applyFiltersSubj.next({
      offerTypeCode: this.mapOfferTypeToCode(
        get(this.route.snapshot.params, "offerType", "")
      ),
      startDate: this.offerFilterForm.value.startDate,
      endDate: this.offerFilterForm.value.endDate,
      locations: this.getSelectedLocations(),
      eventCategories: this.getSelectedEventCategories(),
    });
  }

  private mapOfferTypeToCode(offerType: string): string {
    return this.offerService.mapOfferTypeToCode(offerType);
  }

  private getSavedFilters(): void {
    const getSavedFilters = this.localStorageService.getSavedFilter();

    if (!!getSavedFilters) {
      this.offerFilterForm.patchValue({
        startDate: new Date(getSavedFilters["startDate"]),
        endDate: new Date(getSavedFilters["endDate"]),
        chooseAllLocations: getSavedFilters["chooseAllLocations"],
        locationsFormArray: getSavedFilters.locationsFormArray,
        eventsAndCategoriesFormArray: this.compareEventCategoriesWithFilters(
          getSavedFilters.eventsAndCategoriesFormArray,
          this.categoriesFromService
        ),
      });
      this.saveFilterInSession();
    } else {
      this.maintainDifferenceBetweenDates();
      this.offerFilterForm.get("chooseAllLocations").patchValue(true);
      this.selectAllEvents();
      this.saveFilterInSession();
    }
  }

  private compareEventCategoriesWithFilters(filterCategories, apiCategories) {
    if (apiCategories) {
      for (let i = 0; i < apiCategories.length; i++) {
        let temp = filterCategories.findIndex(
          (x) =>
            x?.name?.toLowerCase() ==
            apiCategories[i]?.eventCategoryName?.toLowerCase()
        );
        if (temp < 0) {
          let category = {
            isChecked: true,
            name: apiCategories[i]?.eventCategoryName,
            type: apiCategories[i]?.eventCategoryType,
          };
          filterCategories.push(category);
        } else {
          filterCategories[temp].type = apiCategories[i]?.eventCategoryType;
        }
      }
    }
    return filterCategories;
  }

  private selectAllEvents(event?: any): void {
    this.offerFilterForm
      .get("eventsAndCategoriesFormArray")
      .value.forEach((control: FormControl) => {
        control.patchValue({
          ...control.value,
          isChecked: event?.target?.checked ?? true,
        });
      });
  }

  private saveSelectedTab(data: string): void {
    this.localStorageService.setSessionStorageTab(data);
  }

  private registerListeners() {
    this.subscription.add(
      this.offerService
        .getOffersAndEvents()
        .pipe(
          catchError((err) => {
            this.isDataLoaded = true;
            this.setBookButtonClicked();
            return err;
          }),
          finalize(() => {
            // this.displayLoader = false;
            this.isDataLoaded = true;
            this.setBookButtonClicked();
          })
        )
        .subscribe((res) => {
          if (res) {
            this.applyOfferFilter();
          }
        })
    );

    this.subscription.add(
      this.exclusiveOfferMenus$
        .pipe(
          filter((items) => !!items),
          tap((items: OfferMenuTab[]) => {
            this.selectedExclusiveOffer({
              ...items.find(
                (x) => x.offerType === this.route.snapshot.params.offerType
              ),
            } as OfferMenuTab);
          })
        )
        .subscribe()
    );

    this.subscription.add(
      this.offerService.getAllEventCategories().subscribe((res) => {
        if (res) {
          this.populateEventCategoriesFA(res?.eventCategoriesList ?? []);
        }
      })
    );

    this.subscription.add(
      this.homeLocatonService.homeMcrLocation$
        .pipe(
          map((res) => res.locations),
          filter((res) => !!res.length),
          tap((res) => {
            this.populateLocationsFA(res || []);
          })
        )
        .subscribe((res) => {})
    );

    this.subscription.add(
      this.route.params
        .pipe(
          tap((val) => {
            this.displayLoader = true;
            this.applyFiltersSubj.next({
              offerTypeCode: this.mapOfferTypeToCode(
                get(this.route.snapshot.params, "offerType", "")
              ),
              startDate: this.offerFilterForm.value.startDate,
              endDate: this.offerFilterForm.value.endDate,
              locations: this.getSelectedLocations(),
              eventCategories: this.getSelectedEventCategories(),
            });
          })
        )
        .subscribe()
    );

    this.subscription.add(
      this.applyFiltersSubj
        .pipe(
          filter((filters) => !!filters),
          tap((filters: IOfferFilter) => {
            this.offerService.getFilteredOffers(
              filters.offerTypeCode,
              filters.startDate,
              filters.endDate,
              filters.locations,
              filters.eventCategories
            );
          }),
          catchError((err) => throwError(err))
        )
        .subscribe()
    );

    this.subscription.add(
      this.showLoader$.subscribe((val: boolean) => {
        this.displayLoader = val;
      })
    );
  }

  /**
   * method to return selected-location-codes as array
   */
  private getSelectedLocations(): string[] {
    const locations: string[] = [];
    this.offerFilterForm.controls?.locationsFormArray?.value?.forEach(
      (x: any) => {
        if (x?.isChecked) {
          locations.push(x.locationCode);
        }
      }
    );
    return locations;
  }

  /**
   * method to return selected-location-codes as array
   */
  private getSelectedEventCategories(): string[] {
    const names: string[] = [];
    this.offerFilterForm.controls?.eventsAndCategoriesFormArray?.value?.forEach(
      (x: any) => {
        if (x?.isChecked) {
          names.push(x.name);
        }
      }
    );
    return names;
  }
  //#endregion private methods

  openExclusiveOfferFilter(): void {
    var currentDate = moment();
    var futureMonth = moment(currentDate).add(1, "M");
    var futureMonthEnd = moment(futureMonth).endOf("month");
    if (
      currentDate.date() != futureMonth.date() &&
      futureMonth.isSame(futureMonthEnd.format("YYYY-MM-DD"))
    ) {
      futureMonth = futureMonth.add(1, "d");
    }
    this.displayFilter = !this.displayFilter;
    this.getUpdatedLanguage();
    this.getSavedFilters();
  }

  checkIfLocationIsEmpty() {
    if (
      this.offerFilterForm
        .get("locationsFormArray")
        .value.filter((x) => x.isChecked == true).length == 0
    ) {
      this.offerFilterForm.setErrors({ invalid: true });
    } else {
      this.offerFilterForm.setErrors(null);
    }
  }

  selectLocations(control: FormControl): void {
    control.patchValue({
      ...control.value,
      isChecked: !control.value.isChecked,
    });

    this.checkIfLocationIsEmpty();

    const isAllChecked = this.offerFilterForm
      .get("locationsFormArray")
      .value.every((item: any) => !!item.isChecked);

    !!isAllChecked
      ? this.offerFilterForm.get("chooseAllLocations").patchValue(true, {
          onlySelf: true,
          emitEvent: true,
        })
      : this.offerFilterForm.get("chooseAllLocations").patchValue(false, {
          onlySelf: true,
          emitEvent: false,
        });
  }

  updateMinDate(event: any): void {
    if (
      !!event.value &&
      +this.offerFilterForm.value.startDate >
        +this.offerFilterForm.value.endDate
    ) {
      this.offerFilterForm.patchValue({
        endDate: this.offerFilterForm.value.startDate,
      });
    }
  }

  saveAndApplyFilter(): void {
    this.displayFilter = false;
    // this.displayLoader = true;
    this.applyFiltersSubj.next({
      offerTypeCode: this.mapOfferTypeToCode(
        get(this.route.snapshot.params, "offerType", "")
      ),
      startDate: this.offerFilterForm.value.startDate,
      endDate: this.offerFilterForm.value.endDate,
      locations: this.getSelectedLocations(),
      eventCategories: this.getSelectedEventCategories(),
    });
    this.saveFilterInSession();
    this.applyOfferFilter();
  }

  selectedExclusiveOffer(tempOffer?: OfferMenuTab): void {
    this.selectedOfferType = tempOffer?.offerType;
    this.selectedOfferTypeCode = tempOffer?.offerTypeCode;

    this.saveSelectedTab(this.selectedOfferType);
    this.applyOfferFilter();

    this.router.navigate([
      "account",
      this.userId,
      "offers",
      tempOffer.offerType,
    ]);
  }

  selectEvents(control: FormControl): void {
    control.patchValue({
      ...control.value,
      isChecked: !control.value.isChecked,
    });
  }

  bookTicket(item: PatronEvent): void {
    if (item.totalTickets <= 0) return;
    const dialogRef = this.modalService.openDialog(BookEventComponent, {
      data: item,
    });
  }

  setBookButtonClicked() {
    this.exclusiveOffers$.subscribe((response) => {
      this.isBookButtonClicked = [];
      this.loader = [];
      response.forEach((item) => {
        this.isBookButtonClicked.push(item.bookedTickets > 0);
        this.loader.push(false);
      });
    });
  }

  updateBookedTicket(event) {
    this.loader[event.index] = true;
    this.loading = true;
    this.subscription.add(
      this.offerService
        .bookPatronEvent(
          this.userId,
          new BookPatronEventDTO(
            this.userId,
            event.data.eventSessionGuid,
            event.value
          )
        )
        .pipe(
          catchError((err) => throwError(err)),
          switchMap(() => {
            this.triggerEmailService.sendEmail(this.userId, event);
            return forkJoin([
              this.patronBookedOfferService.getBookedOffers(),
              this.offerService.getOffersAndEvents(),
            ]);
          }),
          finalize(() => {
            this.displayLoader = false;
            this.loader[event.index] = false;
            this.loading = false;
          })
        )
        .subscribe(
          () => {
            this.modalService.openDialog(BookingConfirmationComponent, {
              data: {
                title: "BOOKING CONFIRMATION",
                ...event.data,
                currentBookingCount: event.value,
              },
            });
          },
          ({ error }) => {
            let data = {};
            if (error.errors) {
              if (error.errors[0]?.code === -125) {
                data = {
                  title: "Insufficient Resort Rewards",
                  message: `<p>Unfortunately, we were unable to process your ticket request for ${event.data.name}.  At this time, you do not have a sufficient amount in your Resort Rewards account.  </p><p>Please contact us at 1-800-832-7529 if you have any questions or require further assistance with this booking.
                  In order to receive email confirmations regarding your bookings and reservations, please ensure you consent to receiving email communications which is available on the ‘Profile’ tab.</p>`,
                };
              } else if (error.errors[0]?.code === -99) {
                data = {
                  title: "BOOKING MODIFICATION FAILED",
                  message: error.errors[0]?.message,
                };
              } else if (error.errors[0]?.code === -205) {
                data = {
                  title: "BOOKING FAILED",
                  message: `<p>Unfortunately, we were unable to process your ticket request for ${event.data.name}. At this time, the event does not have the sufficient number of available seats to meet your request. You may attempt a new request with a lower number of seats or try again at your convenience.</p>
                  <p>Please contact us at 1-800-832-7529 if you have any questions or require further assistance with this booking.
                  In order to receive email confirmations regarding your bookings and reservations please ensure you consent to receiving email communications which is available on the ‘Profile’ tab.</p>`,
                };
              } else {
                data = {
                  title: "ERROR",
                  message: error.errors[0]?.message,
                };
              }
              this.modalService.openDialog(DefaultComponent, { data });
            }
          }
        )
    );
  }

  getMinimumValue(arr) {
    return Math.min(...arr);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
