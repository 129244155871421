import { Component, Inject, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import { HomeMcrLocation } from "../../models/home-mcr-location";
import { fromEvent, Subscription } from "rxjs";
import { HomeMcrLocationService } from "../../services/home-mcr-location.service";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "myclubrewards-locations",
  templateUrl: "./locations.component.html",
  styleUrls: ["./locations.component.scss"],
})
export class LocationsComponent implements OnInit {
  homeMcrLocation$: Observable<HomeMcrLocation>;

  private subscription: Subscription;
  constructor(
    private homeMcrLocationService: HomeMcrLocationService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.subscription = new Subscription();
    this.homeMcrLocation$ = this.homeMcrLocationService.homeMcrLocation$;
  }

  ngOnInit(): void {
    this.detectLanguageChangeText();
  }

  detectLanguageChangeText(): void {
    this.subscription?.add(
      fromEvent(this._document, "click").subscribe((x) => {
        if (x.target["className"] === "translate-here") {
          // this.openDialog('activate-account');
        } else if (x.target["className"] === "tc-text") {
          // this.router.navigate(['/terms-condition']);
          // this._document.defaultView.open(
          //   `${this._document.defaultView.location.origin}/terms-condition`,
          //   "_blank"
          // );
        }
      })
    );
  }
}
