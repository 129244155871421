import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Headerqueries } from './queries';
import { Header } from '../models/header';

@Injectable({
  providedIn: 'root',
})
export class HeaderService {
  constructor(private apollo: Apollo) {}

  queryHeaders(): Observable<Header> {
    return this.apollo
      .query({
        query: gql`
          ${Headerqueries.allHeaders}
        `,
      })
      .pipe(
        catchError((err) =>{
            return err
        }),
        map((res: any) => {
          return new Header().deserialize(res.data.allHeaders[0]);
        })
      );
  }
}
