export const queries = {
  allPromotions: `
query {
    allPromotionHeaders(where: { isActive: true }) {
      heading
      subHeading
      isActive
      promotions(
        where: { isActive: true }
        sortBy: [promotionType_ASC, priority_ASC]
      ) {
        isActive
        heading
        subHeading
        promotionType
        priority
        hyperLink
        hyperLinkText
        hyperLinkType
        hyperLinkColorCode
        image {
          name
          isActive
          file {
            publicUrl
          }
        }
        isDetailActionActive
        promotionDetails {
            id
        }
      }
    }
}
  `,
  promotionDetail: `query($allPromotionDetailsWhere: PromotionDetailWhereInput) {
    allPromotionDetails(where: $allPromotionDetailsWhere) {
      heading
      subHeading
      isActive
      imageEn {
        name
        isActive
        file {
          publicUrl
        }
      }
      imageFr {
        name
        isActive
        file {
          publicUrl
        }
      }
      items (where: {isActive: true}) {
        name
        heading
        subHeading
        description
      }
      details
    }
}`,
};
