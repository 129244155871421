import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagesComponent } from './containers/pages/pages.component';
import { PagesRoutingModule } from './pages.routing.module';
import { ThemeModule } from '../theme/src';
import { HomeModule } from '../../../../../../libs/home/src/lib/home.module';
import { GraphqlModule } from '@myclubrewards/graphql';

@NgModule({
  declarations: [PagesComponent],
  imports: [
    CommonModule,
    ThemeModule,
    PagesRoutingModule,
    HomeModule,
    GraphqlModule,
  ],
})
export class PagesModule {}
