import {
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { IEnvironmentConfig, ENVIRONMENT_CONFIG } from '@myclubrewards/shared';

@Component({
  selector: 'myclubshared-captcha',
  templateUrl: './captcha.component.html',
  styleUrls: ['./captcha.component.scss'],
})
export class CaptchaComponent implements OnInit, OnDestroy {
  @ViewChild('captchaRef') captcha: ReCaptchaV2.ReCaptcha;
  googleSiteKey: string;
  @Output() emitCaptchaResponse = new EventEmitter<string>();
  // @ViewChild('recaptcha') recaptchaElement: ElementRef;
  constructor(
    @Inject(ENVIRONMENT_CONFIG) private environment: IEnvironmentConfig
  ) {
    this.googleSiteKey = this.environment?.GOOGLE_SITE_KEY;
  }

  ngOnInit(): void {
    // this.addRecaptchaScript()
  }
  ngOnDestroy() {
    const captchaElem = this.captcha['elementRef'].nativeElement;
    captchaElem.parentElement.removeChild(captchaElem);
  }

  public resolved(captchaResponse: string) {
    this.emitCaptchaResponse.emit(captchaResponse);
  }

  public onError(errorDetails: any[]) {}
}
