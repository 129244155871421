import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  CookieStorageService,
  LocalStorageService,
  ModalComponent,
  ModalService,
} from '@myclubrewards/shared';
import { toNumber } from 'lodash';
import { forkJoin, Subscription, throwError } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { BookPatronEventDTO } from '../../../dto/book-patron-event.dto';
import { PatronEvent } from '../../../models/patron-event';
import { ExclusiveOfferService } from '../../../services/exclusive-offer.service';
import { PatronBookedOfferService } from '../../../services/patron-booked-offer.service';
import { BookingConfirmationComponent } from '../booking-confirmation/booking-confirmation.component';
import { DefaultComponent } from '../default/default.component';

@Component({
  selector: 'myclubrewards-book-event',
  templateUrl: './book-event.component.html',
  styleUrls: [
    './book-event.component.scss',
    '../../../../../../shared/components/modal/modal.component.scss',
  ],
})
export class BookEventComponent extends ModalComponent
  implements OnInit, OnDestroy {
  protected subscription: Subscription;
  protected localStorageService: LocalStorageService;

  DECREMENT_STEP: number;
  INCREMENT_STEP: number;
  displayLoader: boolean;
  bookEventFG: FormGroup;
  bookedTickets: number;

  constructor(
    public dialogRef: MatDialogRef<BookEventComponent>,
    protected fb: FormBuilder,
    protected modalService: ModalService,
    protected exclusiveOfferService: ExclusiveOfferService,
    protected storage: CookieStorageService,
    protected patronBookedOfferService: PatronBookedOfferService,
    @Inject(MAT_DIALOG_DATA) public data: PatronEvent,
    localStorage: LocalStorageService
  ) {
    super(data, undefined, localStorage, dialogRef, undefined);
    this.localStorageService = localStorage;
    this.DECREMENT_STEP = 1;
    this.INCREMENT_STEP = 1;
    this.displayLoader = false;
    this.subscription = new Subscription();
  }

  private initBookEventFG(): void {
    this.bookEventFG = this.fb.group({
      imageSrc: [''],
      name: [''],
      eventDateFormatted: [''],
      totalTickets: [0],
      bookingCount: [0],
    });
  }

  protected getRequestedTickets(): number {
    return toNumber(this.bookEventFG.get('bookingCount').value);
  }

  protected getAvailableTickets() {
    return new Promise((resolve, reject) => {
      this.subscription.add(
        this.patronBookedOfferService.getBookedOffers().subscribe(
          (response) => {
            if (response.length) {
              const temp = response.find(
                (x) => x?.eventSessionId == this.data?.eventSessionId
              );
              this.bookedTickets = temp ? temp?.bookedTickets : 0;
            } else {
              this.bookedTickets = 0;
            }
            resolve(true);
          },
          () => resolve(false)
        )
      );
    });
  }

  protected updateEvents(): any {
    return forkJoin([
      this.patronBookedOfferService.getBookedOffers(),
      this.exclusiveOfferService.getOffersAndEvents(),
    ]);
  }

  protected buildBookPatronEventDTO(data: any): BookPatronEventDTO {
    return new BookPatronEventDTO(
      data.accountNumber,
      data.eventSessionGuid,
      data.requestedTickets
    );
  }

  ngOnInit(): void {
    this.initBookEventFG();
    this.bookEventFG.patchValue({
      ...this.data,
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  counter(event: any, fc: AbstractControl): void {
    if (event.target?.value === '-') {
      if (fc.value <= this.DECREMENT_STEP) {
        return;
      } else {
        fc.patchValue(fc.value - this.DECREMENT_STEP);
        return;
      }
    }
    if (event.target?.value === '+') {
      if (fc.value >= this.data.totalTickets) {
        return;
      } else {
        fc.patchValue(fc.value + this.INCREMENT_STEP);
        return;
      }
    }
  }

  confirm(): void {
    this.displayLoader = true;
    this.bookEventFG.disable();
    this.getAvailableTickets().then((value) => {
      this.subscription.add(
        this.exclusiveOfferService
          .bookPatronEvent(
            this.storage.getUserIdFromCookies(),
            this.buildBookPatronEventDTO({
              patronGuid: this.data.patronGuid,
              eventSessionId: this.data.eventSessionId,
              requestedTickets: this.getRequestedTickets() + this.bookedTickets,
            })
          )
          .pipe(
            catchError((err) => {
              return throwError(err);
            }),
            switchMap(() => {
              return this.updateEvents();
            }),
            finalize(() => {
              this.displayLoader = false;
              this.bookEventFG.enable();
            })
          )
          .subscribe(
            (res) => {
              this.close();
              this.modalService.openDialog<BookingConfirmationComponent>(
                {
                  title: 'BOOKING CONFIRMATION',
                  ...this.data,
                },
                {},
                BookingConfirmationComponent
              );
            },
            ({ error }) => {
              this.close();
              this.modalService.openDialog<DefaultComponent>(
                {
                  title: 'BOOKING FAILED',
                  ...error.errors,
                },
                {},
                DefaultComponent
              );
            }
          )
      );
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
