
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'myclubrewards-editable-card-header',
  templateUrl: './editable-card-header.component.html',
  styleUrls: ['./editable-card-header.component.scss'],
})
export class EditableCardHeaderComponent implements OnInit {
  private _defaultValue = {
    heading: '',
    title: '',
    updatedDate: '',
  };

  private window: Window;
  private _config;

  @Input('config') set config(value) {
    this._config = { ...this._defaultValue, ...value };
    this._config.title = value.title || value.subHeading;
    this._config.updatedDate = value.updatedDate || value.updatedAt;
    this._config.dateFormat = value.dateFormat || 'short';
  }

  get config() {
    if (this._config !== undefined) {
      return { ...this._defaultValue, ...this._config };
    } else {
      return this._defaultValue;
    }
  }

  routePath;

  constructor(private router : Router, private activatedRoute: ActivatedRoute,){
    this.routePath = this.router.url.toString().split('/')[1];
  }

  ngOnInit(): void {}

}
