import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { HomeReward } from '../../models/home-reward';
import { HomeRewardsService } from '../../services/home-rewards.service';

@Component({
  selector: 'myclubrewards-rewards',
  templateUrl: './rewards.component.html',
  styleUrls: ['./rewards.component.scss'],
})
export class RewardsComponent implements OnInit {
  homeRewards$: Observable<HomeReward>;

  constructor(private homeRewardsService: HomeRewardsService) {
    this.homeRewards$ = this.homeRewardsService.homeRewards$;
  }

  ngOnInit(): void {}

  scroll() {
    window.scrollTo(0, 0);
  }
}
