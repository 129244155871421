<div class="wrapper">
  <div class="header">
    <div class="icon">
      <img src="assets/down-arrow.png" alt="down-arrow" />
      <img mat-dialog-close src="assets/close.png" class="close" alt="close" />
    </div>
    <p class="heading">CANCEL CONCERT BOOKING</p>
  </div>
  <hr class="seperator" />
  <div class="content">
    <p class="content-text">
      By selecting 'Cancel Booking', your reservation for '{{ data.name }}' will
      be canceled. If you do not wish to cancel this reservation, please
      select 'DO NOT CANCEL' to return to MY RESERVATIONS.
    </p>
  </div>
  <hr class="seperator" />
  <div class="wrapper--footer">
    <div class="button-loader">
      <button
        class="cancel-btn"
        type="button"
        (click)="cancelBooking()"
        [ngClass]="{
          'is-disabled': displayLoader
        }"
      >
        CANCEL BOOKING
      </button>
      <div class="loader" *ngIf="displayLoader">
        <myclubshared-loader-component></myclubshared-loader-component>
      </div>
    </div>
    <div>
      <button class="apply-btn" type="button" (click)="close()">
        DO NOT CANCEL
      </button>
    </div>
  </div>
</div>
