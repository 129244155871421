import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { User } from '@myclubrewards/account';
import { appConstants } from '../constants/app-constants';
import { LANGUAGES } from '../constants/languages';
import { CookieStorageService } from './cookie-storage.service';

/**
 * Local Storage Wrapper service.
 */
@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private localStorage: Storage;
  constructor(
    @Inject(DOCUMENT) private document: Document,
    private cookieStorage: CookieStorageService
  ) {
    this.localStorage = this.document.defaultView.localStorage;
  }

  /**
   * Returns current loggedin user's name
   */
  getUser() {
    // return this.localStorage.getItem(appConstants.auth.name);
    return this.cookieStorage?.getItem(appConstants.auth.name);
  }

  /**
   * This method sets loggedin user's details in localStorage
   */
  setUser(user: User) {
    this.setItem(appConstants.auth.userId, user.id);
    this.setItem(appConstants.auth.cardType, user.cardType);
    this.setItem(appConstants.auth.name, user.name);
    this.setItem(appConstants.auth.accessToken, user.accountToken);
    this.setItem(appConstants.auth.refreshToken, user.refreshToken);
  }

  checkLogin(): boolean {
    // return this.localStorage.getItem(appConstants.auth.name) ? true : false;
    return this.cookieStorage?.getItem(appConstants.auth.name) ? true : false;
  }

  /**
   * This method clears user's details from localStorage.
   * Used to implement logout functionality.
   */
  removeUser() {
    this.localStorage?.clear();
  }

  getItem(key: string): string {
    return this.localStorage?.getItem(key);
  }

  getUserId(): string {
    // return this.localStorage?.getItem(appConstants.auth.userId);
    return this.cookieStorage?.getItem(appConstants.auth.userId);
  }

  getUserCardType(): string {
    const cardType = this.cookieStorage?.getItem(appConstants.auth.cardType);

    if (
      !cardType ||
      cardType === '' ||
      cardType === undefined ||
      cardType === 'undefined' ||
      cardType === null ||
      cardType === 'null'
    ) {
      return appConstants.DEFAULT_USER_CARD_TYPE;
    }

    return cardType;
  }

  /**
   * Returns selected Weglot's language.
   * Weglot updates user selected language in localStorage.
   */
  getWeglotLanguage(): string {
    return (
      this.localStorage?.getItem('wglang') || LANGUAGES.EN
    )?.toLowerCase();
  }

  clear() {
    this.localStorage?.clear();
    this.cookieStorage?.deleteCookie();
  }

  setItem(key: string, value: any) {
    this.localStorage?.setItem(key, value);
  }

  setSessionStorage(data: any): void {
    this.localStorage?.setItem('filters', JSON.stringify(data));
  }

  getSavedFilter(): any {
    return JSON.parse(this.localStorage?.getItem('filters'));
  }

  setSessionStorageTab(data: any): void {
    this.localStorage?.setItem('filtersTab', JSON.stringify(data));
  }

  getSavedFilterTab(): any {
    return JSON.parse(this.localStorage?.getItem('filtersTab'));
  }
}
