export const queries = {
  homePageData: `
  query {
    allBanners(
      where: {
      isActive: true
    }) {
      heading
      subHeading
      id
      isActive
      imagesEn(where: { isActive: true }) {
        name
        file {
          publicUrl
        }
      }
      imagesFr(where: { isActive: true }) {
        name
        file {
          publicUrl
        }
      }
      cardsImageEn(where: { isActive: true }) {
        id
        name
        file {
          id
          filename
          publicUrl
        }
      }
      cardsImageFr(where: { isActive: true }) {
        id
        name
        file {
          id
          filename
          publicUrl
        }
      }
      gatewayImage(where: { isActive: true }) {
        id
        name
        file {
          id
          filename
          publicUrl
        }
      }
      page
      button {
        id
        isActive
        name
        url
      }
      modal {
        id
        name
        title
        bodyHeading
        bodySubHeading
        isActive
      }
    }
    allNotices(where: { isActive: true }) {
      id
      isActive
      name
      description
      hyperLink
      hyperLinkText
      hyperLinkType
      hyperLinkColorCode
    }
    allMCRLocations(where: { isActive: true }) {
      id
      isActive
      heading
      subHeading
      locationHeading
      imagesEn {
        isActive
        name
        file {
          publicUrl
        }
      }
      imagesFr {
        isActive
        name
        file {
          publicUrl
        }
      }
      location(where: { isActive: true }, orderBy: "priority") {
        id
        isActive
        name
        hyperLink
        priority
        locationCode
      }
    }
    allHomeRewards(where: { isActive: true }) {
      id
      isActive
      heading
      subHeading
      content(where: { isActive: true }) {
        id
        isActive
        heading
        subHeading
        imagesEn {
          name
          isActive
          file {
            publicUrl
          }
        }
        imagesFr {
          name
          isActive
          file {
            publicUrl
          }
        }
      }
      footer {
        id
        isActive
        body
        button {
          id
          isActive
          name
          url
        }
      }
    }
  }
`,
};
