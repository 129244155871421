import {
  AfterViewInit,
  Component,
  Renderer2,
  Inject,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { environment } from '../../src/environments/environment';
import { appConstants, AssetsPreloaderService } from '@myclubrewards/shared';

@Component({
  selector: 'myclubrewards-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterViewInit {
  constructor(
    private _renderer: Renderer2,
    @Inject(DOCUMENT) private _document: Document,
    @Inject(PLATFORM_ID) private platformId,
    private assetsPreloaderService: AssetsPreloaderService
  ) {}
  ngOnInit() {}

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId)) {
      const scriptEl = this._renderer.createElement('script');
      scriptEl.type = 'text/javascript';
      scriptEl.src = 'https://cdn.weglot.com/weglot.min.js';
      this._renderer.appendChild(this._document.body, scriptEl);
      scriptEl.onload = () => {
        this.afterScriptAdded();
      };

      // preload all images in background
      this.assetsPreloaderService.preloadAssets(appConstants.PRELOAD_ASSETS);
    }
  }

  afterScriptAdded() {
    window['Weglot'].initialize({
      api_key: environment.WEGLOT_API_KEY,
      hide_switcher: true,
    });
  }
}
