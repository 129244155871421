import { Component, Inject, OnInit } from '@angular/core';
import { ModalComponent } from '@myclubrewards/shared';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PatronEvent } from '../../../models/patron-event';

@Component({
  selector: 'myclubrewards-cancel-confirmation',
  templateUrl: './cancel-confirmation.component.html',
  styleUrls: [
    './cancel-confirmation.component.scss',
    '../../../../../../shared/components/modal/modal.component.scss',
  ],
})
export class CancelConfirmationComponent extends ModalComponent
  implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CancelConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PatronEvent | any
  ) {
    super(data, undefined, undefined, dialogRef, undefined);
  }

  ngOnInit(): void {}

  amountCancelled() {
    return this.data.bookedTickets * this.data.ticketCostPoints;
  }
}
