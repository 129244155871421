import { get } from 'lodash';

interface IDeserializable {
  deserialize(input: any): this;
}

export class PromotionDetails implements IDeserializable {
  promotionHeading: string;
  promotionSubHeading: string;
  imageFr: any[];
  imageEn: any[];
  promotionFooter: string;
  isActive: boolean;

  deserialize(input: any): this {
    this.promotionHeading = get(input, 'allDashboardPromotions.heading');
    this.imageFr = get(input, 'allDashboardPromotions.imagesFr', []);
    this.imageEn = get(input, 'allDashboardPromotions.imagesEn', []);
    this.promotionSubHeading = get(input, 'allDashboardPromotions.subHeading');
    this.promotionFooter = get(input, 'allDashboardPromotions.footer.body');
    this.isActive = get(input, 'allDashboardPromotions.isActive');
    return this;
  }
}
