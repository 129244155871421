<div
  class="wrapper"
  *ngIf="membership$ | async as membershipDetails; else displayLoader"
>
  <div class="wrapper-upper">
    <div
      class="color-bar"
      [ngStyle]="{
        'background-color': !!membershipDetails?.colorCode
          ? membershipDetails?.colorCode
          : ''
      }"
    ></div>
    <p class="heading">{{ patron_name }}</p>
    <p class="sub-heading">
      <b> {{ memberName | titlecase }} &nbsp;MEMBER</b>
    </p>
  </div>
  <div class="image-wrapper">
    <div class="image">
      <img
        *ngIf="
          !!membershipDetails?.imageEn && membershipDetails?.imageEn.length > 0
        "
        [src]="
          (membershipDetails?.imageEn)[0]?.file?.publicUrl | safeHtml: 'url'
        "
        alt="{{(membershipDetails?.imageEn)[0]?.name}}"
      />
      <img
        *ngIf="
          !!membershipDetails?.imageFr && membershipDetails.imageFr.length > 0
        "
        [src]="
          (membershipDetails?.imageFr)[0]?.file?.publicUrl | safeHtml: 'url'
        "
        alt="{{(membershipDetails?.imageFr)[0]?.name}}"
      />
    </div>
  </div>
  <hr class="seperator" />
  <div class="wrapper-lower">
    <div
      class="heading"
      *ngIf="membershipDetails.membershipBenefits.length > 0"
    >
      <img class="arrow" src="assets/right-arrow.png" alt="right-arrow" /> YOUR
      LOYALTY BENEFITS INCLUDE:
    </div>
    <div *ngIf="membershipDetails.membershipBenefits.length > 0">
      <ul class="list">
        <li
          class="list-item"
          *ngFor="let reward of membershipDetails.membershipBenefits"
        >
          {{ reward?.key }}
        </li>
      </ul>
    </div>
  </div>
</div>
<ng-template #displayLoader>
  <myclubshared-loader-component
  ></myclubshared-loader-component>
</ng-template>
