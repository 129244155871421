import { CookieStorageService } from './../../../../shared/services/cookie-storage.service';
import { Inject, Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { queries } from './queries';
import { catchError, map } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { MembershipDetails } from '../models/membershipDetails';
import { PromotionDetails } from '../models/promotion-model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { OffersAndEvents } from '../models/offer-modal';
import { DashboardDetails } from '../models/dashboard-modal';
import { UserProfile } from '../models/user-profile-modal';
import {
  ENVIRONMENT_CONFIG,
  ErrorService,
  IEnvironmentConfig,
  LocalStorageService,
} from '@myclubrewards/shared';
import { get } from 'lodash';

@Injectable({
  providedIn: 'root',
})
export class AccountsService {
  private userMembershipSubj = new BehaviorSubject<MembershipDetails>(
    undefined
  );
  membershipDetails$ = this.userMembershipSubj.asObservable();
  private allPromotionsSubj = new BehaviorSubject<PromotionDetails>(undefined);
  promotionDetails$ = this.allPromotionsSubj.asObservable();
  private allOffersSubj = new BehaviorSubject<OffersAndEvents>(undefined);
  userSpecificOffers$ = this.allOffersSubj.asObservable();
  private accountsDashboardSuj = new BehaviorSubject<DashboardDetails>(
    undefined
  );
  dashboardDetails$ = this.accountsDashboardSuj.asObservable();
  private accountProfileSuj = new BehaviorSubject<UserProfile>(undefined);
  accountProfile$ = this.accountProfileSuj.asObservable();

  constructor(
    private apollo: Apollo,
    private http: HttpClient,
    private localStorageService: LocalStorageService,
    private cookieStorageService: CookieStorageService,
    @Inject(ENVIRONMENT_CONFIG) private environment: IEnvironmentConfig,
    private errorService: ErrorService
  ) {}

  // query to fetch membership details of user
  getUserMemmbershipDetails() {
    this.apollo
      .query({
        query: gql`
          ${queries.membershipDetails}
        `,
        variables: {
          rewardTypeWhere: {
            isActive: true,
            name_i: `${this.localStorageService.getUserCardType()}`,
          },
          rewardItemWhere: {
            isActive: true,
            rewardType: {
              name_i: `${this.localStorageService.getUserCardType()}`,
            },
            showAfterLogin: true,
          },
        },
      })
      .pipe(catchError((err) => err))
      .subscribe((res: any) => {
        if (res) {
          if (get(res?.data, 'playerProfile.IsBanned', false)) {
            this.errorService.openDialog(
              {
                status: 403,
              },
              { displayRedirectButton: true },
              {
                disableClose: true,
              }
            );
            return;
          } else {
            const temp = new MembershipDetails().deserialize({
              allRewardTypes: res.data.allRewardTypes[0],
              allRewardItems: res.data.allRewardItems,
            });

            this.userMembershipSubj.next(temp);
          }
        }
      });
  }

  //query to fetch promotions
  getAllPromotions() {
    this.apollo
      .query({
        query: gql`
          ${queries.allDashboardPromotions}
        `,
        variables: {
          promotionWhere: {
            isActive: true,
            rewardType: {
              name_i: 'rewards',
            },
            private: true,
          },
        },
      })
      .pipe(catchError((err) => err))
      .subscribe((res: any) => {
        if (res) {
          if (get(res?.data, 'playerProfile.IsBanned', false)) {
            this.errorService.openDialog(
              {
                status: 403,
              },
              { displayRedirectButton: true },
              {
                disableClose: true,
              }
            );
            return;
          } else {
            const temp = new PromotionDetails().deserialize({
              allDashboardPromotions: res.data.allDashboardPromotions[0],
            });
            this.allPromotionsSubj.next(temp);
          }
        }
      });
  }

  // query to fetch specific offers and reservations
  getUserSpecificOffers() {
    const id = this.cookieStorageService.getUserIdFromCookies();

    return this.http
      .get(`${this.environment.BASE_URL}/exp/api/v1/players/${id}/offers/F/RAM`)
      .subscribe((res: any) => {
        if (res) {
          if (get(res?.data, 'playerProfile.IsBanned', false)) {
            this.errorService.openDialog(
              {
                status: 403,
              },
              { displayRedirectButton: true },
              {
                disableClose: true,
              }
            );
            return;
          } else {
            const temp = new OffersAndEvents().deserialize({
              userSpecificOffers: res,
            });
            this.allOffersSubj.next(temp);
          }
        }
      });
  }

  // dashboard api endpoint
  getDashboardDetails() {
    const id = this.cookieStorageService.getUserIdFromCookies();

    return this.http
      .get(`${this.environment.BASE_URL}/exp/api/v1/players/${id}/dashboard`)
      .subscribe((res: any) => {
        if (res) {
          if (get(res?.data, 'playerProfile.IsBanned', false)) {
            this.errorService.openDialog(
              {
                status: 403,
              },
              { displayRedirectButton: true },
              {
                disableClose: true,
              }
            );
            return;
          } else {
            const temp = new DashboardDetails().deserialize({
              dashboardDetails: res.data,
            });
            this.accountsDashboardSuj.next(temp);
          }
        }
      });
  }
}
