import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HomeReward } from '../models/home-reward';

@Injectable({
  providedIn: 'root',
})
export class HomeRewardsService {
  private homeRewardsSubject: Subject<HomeReward>;
  homeRewards$: Observable<HomeReward>;

  constructor() {
    this.homeRewardsSubject = new Subject<HomeReward>();
    this.homeRewards$ = this.homeRewardsSubject.asObservable();
  }

  deserializeHomeRewards(input: any) {
    const temp = new HomeReward().deserialize(input);
    this.homeRewardsSubject.next(temp);
  }
}
