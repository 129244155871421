import { get, map } from 'lodash';

interface IDeserializable {
  deserialize(input: any): this;
}

export class EventCategory implements IDeserializable {
  eventCategoryName: string;
  eventCategoryID: number;
  eventCategoryGUID: string;
  eventCategoryType: string = 'concerts';

  deserialize(input: any): this {
    this.eventCategoryName = get(input, 'eventCategoryName');
    this.eventCategoryID = get(input, 'eventCategoryID');
    this.eventCategoryType =
      input?.offerType[0]?.name === 'special_events'
        ? 'special-events'
        : 'concerts';

    return this;
  }
}
export class EventCategories implements IDeserializable {
  eventCategoriesList?: EventCategory[];

  deserialize(input: any): this {
    this.eventCategoriesList = this.buildOffers(input);
    return this;
  }
  private buildOffers(input: any[]) {
    if (!input.length) return [];

    const tempEventAndCategories: EventCategory[] = map(input, (categories) => {
      return new EventCategory().deserialize(categories);
    });
    return tempEventAndCategories;
  }
}
