<div class="wrapper">
  <div class="header">
    <div class="icon">
      <img src="assets/down-arrow.png" alt="down-arrow" />
      <img mat-dialog-close src="assets/close.png" class="close" alt="close" />
    </div>
    <p class="heading" *ngIf="data.context === 'forgot-password'">
      PASSWORD CHANGED
    </p>
    <p class="heading" *ngIf="data.context === 'activate-account'">
      CONGRATULATIONS
    </p>
    <p class="heading" *ngIf="data.context === 'password-changed'">
      PASSWORD CHANGED
    </p>
    <p class="heading" *ngIf="data.context === 'profile-changed'">
      PROFILE CHANGES
    </p>
  </div>
  <hr class="seperator" />

  <div class="content">
    <ng-container *ngIf="data.context === 'forgot-password'">
      <div class="image">
        <img src="assets/icon-password-changed.png" />
      </div>
      <p class="text">
        You can now login into your account using the new password.
      </p>
    </ng-container>

    <ng-container *ngIf="data.context === 'activate-account'">
      <div class="image">
        <img src="assets/image-logo-header-black.png" />
      </div>
      <p class="text">
        Welcome to My Club Rewards online. Please log in to start experiencing
        all we have to offer.
      </p>
    </ng-container>

    <ng-container *ngIf="data.context === 'password-changed'">
      <div class="image">
        <img src="assets/icon-password-changed.png" />
      </div>
      <p class="text">
        You can now login into your account using the new password.
      </p>
    </ng-container>
    <ng-container *ngIf="data.context === 'profile-changed'">
      <div class="image">
        <img src="assets/icon-profile-changed.png" />
      </div>
      <p class="text">
        Thank you for submitting your Profile Changes. Your request will be
        processed within 3 business days. If you require additional assistance,
        please visit your nearest Gateway Casino Site.
      </p>
    </ng-container>
  </div>
</div>
