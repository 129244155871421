<div class="loader-wrapper">
  <mat-spinner
    class=""
    [diameter]="setLoaderProperties['diameter']"
    [color]="setLoaderProperties['color']"
    [strokeWidth]="setLoaderProperties.strokeWidth"
    [value]="setLoaderProperties.value"
    [mode]="setLoaderProperties.mode"
  ></mat-spinner>
</div>
