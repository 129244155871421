export const httpCodes = {
  0: {
    img: 'assets/icon-password-change-error.png',
    title: 'CONNECTION ERROR',
    message:
      'We seem to be experiencing technical difficulties. Please try again in a few minutes.',
  },
  400: {
    img: 'assets/icon-password-change-error.png',
    title: 'ERROR',
  },
  401: {
    img: 'assets/icon-password-change-error.png',
    title: 'ERROR',
  },
  429: {
    img: 'assets/account-locked-image.PNG',
    title: 'ACCOUNT LOCKED',
    message:
      'Your account is locked. Please visit your nearest Gateway Site for further assistance with your Club ID and PIN Number.',
  },
  500: {
    img: 'assets/icon-password-change-error.png',
    title: 'ERROR',
    message: 'Something is wrong at the server side',
  },
  403: {
    img: 'assets/account-locked-image.PNG',
    title: 'ERROR',
    message:
      'There seems to be an issue with your account.  Please contact a Gateway Representative at 1-800-832-7579 for further assistance.',
    displayBackButton: true,
  },
  404: {
    title: 'ERROR',
    message: 'Not Found',
    img: 'assets/icon-password-change-error.png',
  },
};
