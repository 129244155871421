import { get, map } from 'lodash';

interface IDeserializable {
  deserialize(input: any): this;
}

export class HomeMcrLocation implements IDeserializable {
  id?: string;
  isActive?: boolean;
  heading?: string;
  subHeading?: string;
  locationHeading?: string;
  locations?: Location[];
  imagesEn?: any;
  imagesFr?: any;

  deserialize(input: any): this {
    this.id = get(input, 'id');
    this.isActive = get(input, 'isActive');
    this.heading = get(input, 'heading');
    this.subHeading = get(input, 'subHeading');
    this.locationHeading = get(input, 'locationHeading');
    this.imagesEn = get(input, 'imagesEn');
    this.imagesFr = get(input, 'imagesFr');
    this.locations = this.buildLocations(get(input, 'location', []));

    return this;
  }

  private buildLocations(input: any[]): Location[] {
    if (!input.length) return [];

    const tempContents: Location[] = map(input, (item) => {
      return new Location().deserialize(item);
    });
    return tempContents;
  }
}

export class Location implements IDeserializable {
  id?: string;
  isActive?: boolean;
  name?: string;
  hyperLink?: string;
  priority?: number;
  locationCode?: string;

  deserialize(input: any): this {
    this.id = get(input, 'id');
    this.isActive = get(input, 'isActive');
    this.name = get(input, 'name');
    this.hyperLink = get(input, 'hyperLink');
    this.priority = get(input, 'priority');
    this.locationCode = get(input, 'locationCode');

    return this;
  }
}
