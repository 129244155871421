<div class="wrapper">
    <div class="header">
        <div class="icon">
            <img src="assets/down-arrow.png" alt="down-arrow" />
            <img mat-dialog-close src="assets/close.png" class="close" alt="close" />
        </div>
        <p class="heading">CHANGE PASSWORD</p>
    </div>

    <hr class="seperator" />
    <div class="text-container">
        <p class="heading">Enter Current Password</p>
    </div>
    <form [formGroup]="changePasswordForm" (keydown)="submitEnter($event, changePasswordForm)">
        <div class="feild">
            <label class="input-label" for="currentPassword">Current password</label>
            <div class="warning-wrapper">
                <div class="pass">
                    <input autocomplete="off" placeholder="Enter Old Password" formControlName="currentPassword" [type]="fieldTextTypecur ? 'text' : 'password'" class="input-feild" name="currentPassword" (keyup)="checkPasswords()" />
                    <span class="show" (click)="toggleFieldTextTypecur()">{{
            !!fieldTextTypecur ? 'Hide' : 'Show'
          }}</span>
                </div>
            </div>
        </div>

        <div class="text-container">
            <p class="heading">Enter New Password</p>
        </div>

        <div class="feild">
            <label class="input-label" for="newPassword">New password</label>
            <div class="warning-wrapper">
                <div class="pass">
                    <input autocomplete="off" placeholder="Enter New Password" formControlName="newPassword" [type]="fieldTextType ? 'text' : 'password'" class="input-feild" name="newPassword" (keyup)="checkPasswords()" />
                    <span class="show" (click)="toggleFieldTextType()">{{
            !!fieldTextType ? 'Hide' : 'Show'
          }}</span>
                </div>
                <p class="warning">
                    <span [ngClass]="{
              error:
                form.newPassword?.errors?.minlength ||
                form.newPassword?.errors?.maxlength
            }">8-15 Characters,</span
          >
          <span
            [ngClass]="{
              error: form.newPassword?.errors?.symbol
            }"
            >1 Symbol,</span
          ><br />
          <span
            [ngClass]="{
              error: form.newPassword?.errors?.letter
            }"
            >Uppercase
          </span> and
                    <span [ngClass]="{
              error: form.newPassword?.errors?.number
            }">
            1 Number</span
          >
        </p>
      </div>
    </div>

    <div class="feild">
      <label class="input-label" for="confirmPassword">Confirm Password</label>
      <div class="warning-wrapper">
        <div class="pass">
          <input
            placeholder="Confirm Password"
            type="password"
            [type]="fieldTextTypecon ? 'text' : 'password'"
            formControlName="confirmPassword"
            class="input-feild"
            name="confirmPassword"
            (keyup)="checkPasswords()"
            autocomplete="off"
          />
          <span class="show" (click)="toggleFieldTextTypecon()">{{
            !!fieldTextTypecon ? 'Hide' : 'Show'
          }}</span>
            </div>
            <p class="warning error-match" *ngIf="same">Does Not Match</p>
        </div>
</div>
</form>

<div class="progress-bar">
    <mat-progress-bar class="progess" mode="determinate" [value]="progressbarValue"></mat-progress-bar>
    <div>
        You have
        <span class="timer">
        <countdown
          #cd1
          [config]="{ leftTime: timer - 1, format: 'ss' }"
          (event)="timerCompleted($event)"
        ></countdown>
      </span> <span> seconds to create a new password.</span>
    </div>
</div>

<p *ngIf="invalidCredential" class="invalidCredentials">
    Your curent password is incorrect, Please enter the correct one and try again
</p>

<hr class="seperator added-margin" />

<div class="wrapper--footer">
    <div class="button-loader">
        <button type="submit" (click)="changePassword()" [ngClass]="{
          'is-disabled': changePasswordForm.invalid || displayLoader
        }" [disabled]="changePasswordForm.invalid || displayLoader">
        SUBMIT
      </button>
        <div class="loader" *ngIf="displayLoader">
            <myclubshared-loader-component></myclubshared-loader-component>
        </div>
    </div>
</div>
</div>
