import { catchError, switchMap, tap } from 'rxjs/operators';
import {
  ENVIRONMENT_CONFIG,
  IEnvironmentConfig,
  LocalStorageService,
} from '@myclubrewards/shared';
import { BehaviorSubject, forkJoin, Observable, of, throwError } from 'rxjs';
import { Inject, Injectable } from '@angular/core';
import { PatronEvent } from '../models/patron-event';
import { HttpClient } from '@angular/common/http';
import { Apollo } from 'apollo-angular';
import { BaseOffersService } from './base-offers.service';
import { PatronOffer } from '../models/patron-offer-model';

@Injectable({
  providedIn: 'root',
})
export class PatronBookedOfferService extends BaseOffersService {
  private bookedOffers!: BehaviorSubject<PatronEvent[]>;

  bookedOffers$!: Observable<PatronEvent[]>;

  constructor(
    private localStorageService: LocalStorageService,
    protected http: HttpClient,
    @Inject(ENVIRONMENT_CONFIG) protected environment: IEnvironmentConfig,
    protected apollo: Apollo
  ) {
    super(apollo, http, environment);
    this.bookedOffers = new BehaviorSubject<PatronEvent[]>(undefined);
    this.bookedOffers$ = this.bookedOffers.asObservable();
  }

  getBookedOffers(): Observable<PatronEvent[]> {
    const id = this.localStorageService.getUserId();
    return forkJoin({
      patronBookedOffers: this.http.get<any[]>(
        this.buildUrl(`/exp/api/v1/players/${id}/patronBookedOffers`)
      ),
      cmsEvents: this.buildCMSEventsQuery(),
      events: this.http.get<any[]>(
        this.buildUrl(`/exp/api/v1/events?accountNumber=${id}`)
      ),
    }).pipe(
      catchError((err) => {
        return throwError(err);
      }),
      switchMap((res) => {
        // step 1: find and merge CMS Event/Event Category image
        res = this.mapCMSEventImages(res, 'patronBookedOffers');

        const reservations: PatronOffer[] = [];

        if (res?.patronBookedOffers?.length) {
          res?.patronBookedOffers?.forEach((item: any) => {
            let event = res.events.filter(
              (event) => event.eventSessionGuid === item.eventSessionGuid
            )[0];
            if (event)
              reservations.push(
                new PatronOffer().deserialize({
                  ...item,
                  patronTicketsRemaining: event?.patronTicketsRemaining,
                  availableSeats: event?.availableSeats,
                  patronTicketsAllowedMax: event?.patronTicketsAllowedMax,
                  ticketCostPoints: event?.ticketCostPoints,
                })
              );
          });
        }

        return of(reservations);
      }),
      tap((data: PatronOffer[]) => {
        this.bookedOffers.next(data);
      })
    );
  }
}
