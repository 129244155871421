import { Component, OnInit } from '@angular/core';
import { HomeService } from '../../services/home.service';
import {
  SeoService,
  SEOTags,
  PageTitleService,
  PAGE_TITLES,
} from '@myclubrewards/shared';

@Component({
  selector: 'myclubrewards-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {  
  constructor(
    private homeService: HomeService,
    private seoService: SeoService,
    private pageTitleService: PageTitleService,
  ) {}
  

ngOnInit(): void {
    this.homeService.queryHomePage();
    this.seoService.updateTags(SEOTags.HOME);
    this.pageTitleService.setTitle(PAGE_TITLES.HOME_PAGE_TITLE);
  }
}
