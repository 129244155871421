import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RecaptchaModule } from 'ng-recaptcha';
import { CounterComponent } from './containers/counter/counter.component';
import { SharedMaterialModule } from '../shared/material/src/lib/shared-material.module';
import { CaptchaComponent } from './components/captcha/captcha.component';
import { CardComponent } from './components/card/card.component';
import { EditableCardContainerComponent } from './components/editable-card-container/editable-card-container.component';
import { EditableCardHeaderComponent } from './components/editable-card-header/editable-card-header.component';
import { LoaderComponentComponent } from './components/loader-component/loader-component.component';
import { ModalComponent } from './components/modal/modal.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ToggleButtonComponent } from './components/toggle-button/toggle-button.component';
import { HorizontalScrollerComponent } from './containers/horizontal-scroller/horizontal-scroller.component';
import { AvoidUnnecessaryCharacterDirective } from './directive/avoid-unnecessay-character.directive';
import { TrimDirective } from './directive/trim.directive';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { LoaderComponent } from './components/loader/loader.component';

@NgModule({
    imports: [CommonModule, SharedMaterialModule, RecaptchaModule],
    declarations: [
        CardComponent,
        SafeHtmlPipe,
        EditableCardHeaderComponent,
        EditableCardContainerComponent,
        ModalComponent,
        LoaderComponentComponent,
        PageNotFoundComponent,
        ToggleButtonComponent,
        CaptchaComponent,
        TrimDirective,
        AvoidUnnecessaryCharacterDirective,
        HorizontalScrollerComponent,
        CounterComponent,
        LoaderComponent,
    ],
    exports: [
        CardComponent,
        SharedMaterialModule,
        SafeHtmlPipe,
        EditableCardHeaderComponent,
        EditableCardContainerComponent,
        LoaderComponentComponent,
        CaptchaComponent,
        TrimDirective,
        AvoidUnnecessaryCharacterDirective,
        HorizontalScrollerComponent,
        CounterComponent,
        LoaderComponent,
    ]
})
export class AdminSharedModule {}
