import { PromotionDetails } from './../models/promotion-detail';
import { Promotions, PromotionDetail } from './../models/promotions';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { queries } from './queries';

@Injectable({
  providedIn: 'root',
})
export class PromotionService {
  constructor(private apollo: Apollo) {}

  queryAllPromotions(): Observable<Promotions> {
    return this.apollo
      .query({
        query: gql`
          ${queries.allPromotions}
        `,
      })
      .pipe(
        catchError((err) => err),
        map((res: any) => {
          return new Promotions().deserialize(res.data.allPromotionHeaders[0]);
        })
      );
  }

  getPromotionDetails(id): Observable<PromotionDetails> {
    return this.apollo
      .query({
        query: gql`
          ${queries.promotionDetail}
        `,
        variables: {
          allPromotionDetailsWhere: {
            id: id,
            isActive: true,
          },
        },
      })
      .pipe(
        catchError((err) => {
          console.log(err);
          return err;
        }),
        map((res: any) => {
          return new PromotionDetails().deserialize(
            res.data.allPromotionDetails[0]
          );
        })
      );
  }
}
