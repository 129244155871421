import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'myclubrewards-profile-disclaimer',
  templateUrl: './profile-disclaimer.component.html',
  styleUrls: [
    '../../../../../../shared/components/modal/modal.component.scss',
    './profile-disclaimer.component.scss',
  ],
})
export class ProfileDisclaimerComponent implements OnInit {
  private window: Window;
  acceptNorms: boolean = false;
  mailText: string = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ProfileDisclaimerComponent>,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.window = this._document.defaultView;
  }

  ngOnInit(): void {}

  mailTo(): void {
    this.mailText = 'mailto:info@gatewaycasinos.com?subject=&body=' + '';
    this.window.location.href = this.mailText;
  }
}
