<div class="wrapper">
  <div class="header">
    <div class="icon">
      <img src="assets/down-arrow.png" alt="down-arrow" />
      <img
        *ngIf="data?.displayRedirectButton; else default"
        (click)="redirectUser()"
        class="close"
        src="assets/close.png"
        alt="close"
      />
      <ng-template #default>
        <img
          mat-dialog-close
          class="close"
          src="assets/close.png"
          alt="close"
        />
      </ng-template>
    </div>
    <h1
      class="heading"
      *ngIf="data?.title"
      [innerHTML]="data.title | safeHtml: 'html'"
    ></h1>
  </div>
  <hr class="seperator" />
  <div *ngIf="data?.bodyHeading" class="text-container">
    <span
      class="heading"
      *ngIf="data?.bodyHeading"
      [innerHTML]="data.bodyHeading | safeHtml: 'html'"
    ></span>
    <span
      class="sub-heading"
      *ngIf="data?.bodySubHeading"
      [innerHTML]="data.bodySubHeading | safeHtml: 'html'"
    ></span>
  </div>
  <div *ngIf="data?.ImageSrc" class="content">
    <div *ngIf="data?.ImageSrc" class="image">
      <img [src]="data.ImageSrc" [alt]="data.ImageSrc" />
    </div>
    <p
      *ngIf="data?.message"
      [innerHTML]="data.message | safeHtml: 'html'"
      class="text"
    ></p>
  </div>
  <div *ngIf="data?.displayRedirectButton" class="login-button-container">
    <button class="login-button" type="submit" (click)="redirectUser()">
      OK
    </button>
  </div>
</div>
