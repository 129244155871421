import { get } from 'lodash';

interface IDeserializable {
  deserialize(input: any): this;
}

export class UserProfile implements IDeserializable {
  profile?: string;
  address?: any;
  id?: number;
  email?: any;
  contactNumber?: string;
  mailToAddress?: boolean;
  emailPreference?: boolean;
  phonePreference?: boolean;
  notificationPreference?: boolean;

  deserialize(input: any): this {
    this.profile = get(input, 'userProfileDetails.playerProfile', '-');
    this.address = this.buildPlayerAddress(
      get(input, 'userProfileDetails.playerAddress', [])
    );

    /* this.address = this.getFullAddress(
      get(input, 'userProfileDetails.playerAddress[0]', [])
    ); */

    this.id = get(input, 'userProfileDetails.playerId', 0);
    this.email = this.buildPlayerEmail(
      get(input, 'userProfileDetails.playerEmail', [])
    );
    this.contactNumber = this.buildPlayerContactNo(
      get(input, 'userProfileDetails.playerPhone', [])
    );
    // communication preference modal binding
    this.mailToAddress = get(
      input,
      'userProfileDetails.playerAddress[0].mailToAddress',
      false
    );
    this.emailPreference = get(
      input,
      'userProfileDetails.playerEmail[0].sendToEmail',
      false
    );
    this.phonePreference = get(
      input,
      'userProfileDetails.playerPhone[0].callToPhone',
      false
    );
    this.notificationPreference = get(input, 'true', false);
    return this;
  }

  private buildPlayerAddress(input: any[]) {
    if (!input) return [];
    if (!input.length) return [];
    const primaryAddressPresent = input.find((item) => !!item.primaryAddress);
    if (!!primaryAddressPresent) {
      return primaryAddressPresent;
    } else {
      const secondaryAddressPresent = input.find(
        (item) => !item.primaryAddress
      );
      return secondaryAddressPresent;
    }
  }

  private buildPlayerEmail(input: any[]) {
    if (!input) return [];
    if (!input.length) return [];
    const primaryEmailPresent = input.find((item) => !!item.primaryEmail);
    if (!!primaryEmailPresent) {
      return primaryEmailPresent;
    } else {
      const secondaryEmailPresent = input.find((item) => !item.primaryEmail);
      return secondaryEmailPresent;
    }
  }

  private buildPlayerContactNo(input: any[]) {
    if (!input) return [];
    if (!input.length) return [];
    const primaryContactNoPresent = input.find((item) => !!item.primaryPhone);
    if (!!primaryContactNoPresent) {
      return primaryContactNoPresent;
    } else {
      const secondaryContactNoPresent = input.find(
        (item) => !item.primaryPhone
      );
      return secondaryContactNoPresent;
    }
  }

  /* private getFullAddress(data: any): string {
    let address = '';

    if (get(data, 'addressLine1')) {
      address = address.concat(data.addressLine1);
    }
    if (get(data, 'addressLine2')) {
      address = address.concat(' ').concat(data.addressLine2);
    }
    if (get(data, 'addressLine3')) {
      address = address.concat(' ').concat(data.addressLine3);
    }
    if (get(data, 'city')) {
      address = address.concat(', ').concat(data.city);
    }
    if (get(data, 'stateProvCode')) {
      address = address.concat(', ').concat(data.stateProvCode);
    }
    if (get(data, 'postCode')) {
      address = address.concat(', ').concat(data.postCode);
    }
    if (get(data, 'countryCode')) {
      address = address.concat(', ').concat(data.countryCode);
    }

    return address.trim();
  } */
}
