import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { APOLLO_OPTIONS } from "apollo-angular";
import { ApolloClientOptions, InMemoryCache } from "@apollo/client/core";
import { HttpLink } from "apollo-angular/http";
// import { TransferHttpCacheModule } from '@nguniversal/common';
import { ENVIRONMENT_CONFIG, IEnvironmentConfig } from "@myclubrewards/shared";

export function createApollo(
  httpLink: HttpLink,
  environment: IEnvironmentConfig
): ApolloClientOptions<any> {
  return {
    link: httpLink.create({ uri: environment.GRAPHQL_SERVER }),
    cache: new InMemoryCache(),
    ssrMode: environment.production,
  };
}

@NgModule({
  imports: [CommonModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, ENVIRONMENT_CONFIG],
    },
  ],
})
export class GraphqlModule {}
