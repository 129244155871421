import { get, map } from 'lodash';

interface IDeserializable {
  deserialize(input: any): this;
}

export class HomeReward implements IDeserializable {
  id?: string;
  isActive?: boolean;
  heading?: string;
  subHeading?: string;
  contents?: HomeRewardContent[];
  footer?: HomeRewardFooter;

  deserialize(input: any): this {
    this.id = get(input, 'id');
    this.heading = get(input, 'heading');
    this.subHeading = get(input, 'subHeading');
    this.isActive = get(input, 'isActive');

    this.contents = this.buildContents(get(input, 'content', []));
    this.footer = new HomeRewardFooter().deserialize(get(input, 'footer'));
    return this;
  }

  private buildContents(input: any[]): HomeRewardContent[] {
    if (!input.length) return [];

    const tempContents: HomeRewardContent[] = map(input, (item) => {
      return new HomeRewardContent().deserialize(item);
    });
    return tempContents;
  }
}

export class HomeRewardContent implements IDeserializable {
  id?: string;
  isActive?: boolean;
  heading?: string;
  subHeading?: string;
  imagesEn: any;
  imagesFr: any;

  deserialize(input: any): this {
    this.id = get(input, 'id');
    this.heading = get(input, 'heading');
    this.subHeading = get(input, 'subHeading');
    this.isActive = get(input, 'isActive');
    this.imagesEn = get(input, 'imagesEn');
    this.imagesFr = get(input, 'imagesFr');

    return this;
  }
}

export class HomeRewardFooter implements IDeserializable {
  id?: string;
  isActive?: boolean;
  body?: string;
  button?: HomeRewardFooterButton;

  deserialize(input: any): this {
    this.id = get(input, 'id');
    this.isActive = get(input, 'isActive');
    this.body = get(input, 'body');
    this.button = new HomeRewardFooterButton().deserialize(
      get(input, 'button')
    );

    return this;
  }
}

export class HomeRewardFooterButton implements IDeserializable {
  id?: string;
  isActive?: boolean;
  name?: string;
  url?: any;

  deserialize(input: any): this {
    this.id = get(input, 'id');
    this.isActive = get(input, 'isActive');
    this.name = get(input, 'name');
    this.url = get(input, 'url');

    return this;
  }
}
