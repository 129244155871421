import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AuthorizationService } from '@myclubrewards/account';
import { LocalStorageService } from '../../services/local-storage.service';

@Component({
  selector: 'myclubrewards-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router,
    private localStorage: LocalStorageService,
    public dialogRef: MatDialogRef<ModalComponent>,
    private authService: AuthorizationService
  ) {}

  ngOnInit(): void {}

  redirectUser() {
    if (this.localStorage.checkLogin()) {
      this.authService.logoutUser(0, '');
      this.close();
    } else {
      this.close();
      this.router.navigate(['']);
    }
  }

  close() {
    this.dialogRef.close();
  }
}
