import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';

/**
 * Page Title Update service wrapper.
 */
@Injectable({
  providedIn: 'root',
})
export class PageTitleService {
  constructor(private titleService: Title) {}

  /**
   * This method is used to update page's title.
   * Angular Univeral friendly.
   */
  setTitle(title: string) {
    this.titleService.setTitle(title);
  }
}
