import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  appConstants,
  LocalStorageService,
  ModalService,
} from '@myclubrewards/shared';
import { get, isEqual } from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { UserProfile } from '../../../models/user-profile-modal';
import { CongratulationsComponent } from '../../congratulations/congratulations.component';
import { ProfileDisclaimerComponent } from '../profile-disclaimer/profile-disclaimer.component';
import { ChangeProfilePasswordComponent } from '../../Profile/change-profile-password/change-profile-password.component';
import { ProfileService } from './../../../services/profile-service.service';

@Component({
  selector: 'myclubrewards-mcr-account-profile',
  templateUrl: './account-profile.component.html',
  styleUrls: ['./account-profile.component.scss'],
})
export class McrAccountProfileComponent implements OnInit {
  userProfileDetails$: Observable<UserProfile>;
  subscription: Subscription;
  displayLoader: boolean = true;
  profileForm: FormGroup;
  showAstext: boolean = false;
  private storeFormValue: UserProfile;
  detectFormChangeObj: any;
  savedFormDataForComparison: any;
  getComparisonFromRes: any;

  constructor(
    private modalService: ModalService,
    private localStorage: LocalStorageService,
    private fb: FormBuilder,
    private profileService: ProfileService
  ) {
    this.userProfileDetails$ = this.profileService.accountProfile$;
    this.initialiseProfileForm();
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    this.getUserProfile();
    this.getProfiledata();
    this.disableSpecifields();
    this.detectFormChangesByUser();
  }

  initialiseProfileForm() {
    this.profileForm = this.fb.group({
      id: this.fb.control(['']),
      name: this.fb.control(['']),
      playerAddress: this.fb.group({
        addressLine1: this.fb.control([''], [Validators.required]),
        addressLine2: this.fb.control(['']),
        addressLine3: this.fb.control(['']),
        primaryAddress: this.fb.control(false),
        city: this.fb.control([''], [Validators.required]),
        countryCode: this.fb.control([''], [Validators.required]),
        postCode: this.fb.control([''], [Validators.required]),
        stateProvCode: this.fb.control([''], [Validators.required]),
      }),
      playerEmail: this.fb.group({
        emailAddress: this.fb.control('', [
          Validators.pattern(appConstants.regex.emailRegex),
        ]),
        primaryEmailAddress: this.fb.control(false),
      }),
      playerPhone: this.fb.group({
        phoneNumber: this.fb.control('', [
          Validators.pattern(appConstants.regex.contactNumberRegex),
        ]),
        primaryContactNo: this.fb.control(false),
      }),
      communicationPreferences: this.fb.group({
        mail: this.fb.control(false),
        email: this.fb.control(false),
        notification: this.fb.control(false),
        telephone: this.fb.control(false),
      }),
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  getProfiledata() {
    this.subscription.add(
      this.profileService.accountProfile$.subscribe(
        (res: UserProfile) => {
          this.storeFormValue = res;
          this.patchFormValue(res);
          this.disableSpecifields();
          this.displayLoader = false;
        },
        () => {
          this.displayLoader = false;
        }
      )
    );
  }

  cancelFormSubmission() {
    this.showAstext = false;
    this.patchFormValue(this.storeFormValue);
    this.disableSpecifields();
    this.detectFormChangeObj = null;
  }

  detectFormChangesByUser() {
    this.subscription.add(
      this.profileForm.valueChanges.subscribe((res) => {
        if (res) {
          this.detectFormChangeObj = res;
        }
      })
    );
  }

  patchFormValue(data: UserProfile) {
    const temp = this.profileService.createProfileResponseObject(data);
    this.profileForm.patchValue({ ...temp }, { emitEvent: false });
    this.detectFormChangeObj = { ...temp };
    this.savedFormDataForComparison = { ...temp };
  }

  disableSpecifields() {
    if (this.showAstext) {
      this.profileForm.enable({
        emitEvent: false,
      });
      !this.savedFormDataForComparison?.playerEmail?.emailAddress &&
        this.profileForm.controls.communicationPreferences['controls'][
          'email'
        ].disable({ emitEvent: false });
      !this.savedFormDataForComparison?.playerPhone?.phoneNumber &&
        this.profileForm.controls.communicationPreferences['controls'][
          'telephone'
        ].disable({ emitEvent: false });
      this.detectFormChangesByUser();
    } else {
      this.profileForm.disable({
        emitEvent: false,
      });
    }
  }

  getUserProfile() {
    this.profileService.getPlayerProfile();
  }

  changedPassword() {
    this.modalService.openDialog<ChangeProfilePasswordComponent>(
      undefined,
      undefined,
      ChangeProfilePasswordComponent
    );
  }

  /* checkUserCommunicationPreference(event, communicationType) {
    if (event.target.checked && !!communicationType) {
      const dialog = this.modalService.openDialog<ProfileDisclaimerComponent>(
        undefined,
        undefined,
        ProfileDisclaimerComponent
      );
      dialog.afterClosed().subscribe((res) => {
        if (res) {
          this.profileForm.patchValue({
            [communicationType]: event.target.checked,
          });
        } else {
          this.profileForm.patchValue({
            [communicationType]: this.savedFormDataForComparison[
              communicationType
            ],
          });
        }
      });
    }
  } */

  updateProfilePopup() {
    if (this.profileForm.invalid) {
      this.profileForm.markAllAsTouched();
      return;
    } else {
      this.getComparisonFromRes = this.profileService.fetchDifference(
        this.savedFormDataForComparison,
        this.profileForm.getRawValue()
      );
      const communicationPreferences = get(
        this.getComparisonFromRes,
        'communicationPreferences'
      );
      // let isConsentNeeded: boolean = false;

      // if (communicationPreferences) {
      //   isConsentNeeded = Object.keys(communicationPreferences)
      //     .map((key) => communicationPreferences[key])
      //     .some((x) => x === true);
      // }

      if (
        !!this.detectFormChangeObj &&
        !isEqual(this.savedFormDataForComparison, this.detectFormChangeObj)
      ) {
        if (!!communicationPreferences?.email) {
          this.openDisclaimerDialog();
        } else {
          this.submitProfile();
        }
      } else {
        this.showAstext = false;
        this.disableSpecifields();
      }
    }
  }

  openDisclaimerDialog() {
    const dialog = this.modalService.openDialog<ProfileDisclaimerComponent>(
      undefined,
      undefined,
      ProfileDisclaimerComponent
    );
    dialog.afterClosed().subscribe((res) => {
      if (res) {
        this.submitProfile();
      }
    });
  }

  private submitProfile(): void {
    const patronID = this.localStorage.getUserId();
    let data = {
      ...this.getComparisonFromRes,
      ...{ accountId: this.savedFormDataForComparison.id },
      ...{
        isCasl: !!this.getComparisonFromRes?.communicationPreferences?.email
          ? true
          : false,
      },
    };
    this.displayLoader = true;
    this.subscription.add(
      this.profileService
        .updateUserProfile(patronID, data)
        .pipe(
          finalize(() => {
            this.displayLoader = false;
          })
        )
        .subscribe(
          () => {
            this.modalService.openDialog<CongratulationsComponent>(
              { context: 'profile-changed' },
              undefined,
              CongratulationsComponent
            );
            this.getUserProfile();
            this.showAstext = false;
            this.disableSpecifields();
          },
          () => {
            this.showAstext = true;
          }
        )
    );
  }
}
