<div class="nav-wrapper">
  <img class="arrow" src="assets/right-arrow.png" alt="right-arrow" />
  <div class="nav-item-wrapper">
    <div class="nav-item-left">
      <a
        *ngFor="let item of navBarItems; let i = index"
         (click)="displayAccordingNavigation(item)"
        [ngStyle]="{ color: selectedTabIndex === i ? '#88714d' : '#2a2e34' }"
          [routerLink]="item.link"
      >
        {{ item.name | uppercase }}
      </a>
    </div>
    <div class="nav-item-left-mobile" #movileNavbarMenu>
      <p (click)="toggleNavMenu()" class="text">MENU</p>
      <div *ngIf="showMenu" class="menu-box">
        <div
          (click)="displayAccordingNavigation(item)"
          (click)="toggleNavMenu()"
          [ngStyle]="{ color: selectedTabIndex === i ? '#88714d' : '#2a2e34' }"
          *ngFor="let item of navBarItems; let i = index"
          class="options"
          [routerLink]="item.link"
        >
          <img class="arrow" src="assets/right-arrow.png" alt="right-arrow" />
          <p class="label">
            {{ item.name | uppercase }}
          </p>
        </div>
      </div>
    </div>
    <div class="nav-item-right">
      <div class="notification-wrapper">
        <mat-icon class="material-icon">notifications_none</mat-icon>
        <!-- <div class="dot"></div> -->
      </div>
      <button class="doNotPrint print_box" (click)="onPrint()">
        PRINT
      </button>
    </div>
  </div>
</div>
