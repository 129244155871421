<ng-container *ngIf="homeBanner$ | async as homeBanner">
  <div class="top-section-wrapper">
    <myclubrewards-notice></myclubrewards-notice>
    <div class="banner">
      <div class="banner-left">
        <div class="image">
          <img
            [src]="
              (homeBanner?.cardsImageEn)[0]?.file?.publicUrl ||
                (homeBanner?.cardsImageFr)[0]?.file?.publicUrl | safeHtml: 'url'
            "
            [alt]="
              (homeBanner?.cardsImageEn)[0]?.name ||
              (homeBanner?.cardsImageFr)[0]?.name
            "
          />
        </div>
        <div class="button-wrapper">
          <button
            class="carousel-button"
            *ngIf="homeBanner?.button"
            (click)="openDialog(homeBanner.modal)"
          >
            {{ homeBanner.button.name }}
          </button>
        </div>
      </div>
      <div class="banner-middle">
        <div
          class="heading"
          [innerHTML]="homeBanner.heading | safeHtml: 'html'"
        ></div>
        <div class="membership">
          <img src="assets/right-arrow.png" alt="right-arrow" />
          <div [innerHTML]="homeBanner.subHeading | safeHtml: 'html'"></div>
        </div>
      </div>
      <div class="banner-right">
        <img
          class="banner-image"
          [src]="
            (homeBanner?.imagesEn)[0]?.file?.publicUrl ||
              (homeBanner?.imagesFr)[0]?.file?.publicUrl | safeHtml: 'url'
          "
          [alt]="
            (homeBanner?.imagesEn)[0]?.name || (homeBanner?.imagesFr)[0]?.name
          "
        />
        <img
          class="logo-image"
          [src]="(homeBanner?.gatewayImage)[0]?.file?.publicUrl"
          [alt]="(homeBanner?.gatewayImage)[0]?.name"
        />
      </div>
    </div>

    <!-- <div
      class="banner"
      style="background-image: url({{
        (homeBanner?.imagesEn)[0]?.file?.publicUrl ||
          (homeBanner?.imagesFr)[0]?.file?.publicUrl
      }})"
    >
      <div class="banner-text">
        <p class="label">BIGGER.</p>
        <p class="label">BETTER.</p>
        <p class="label">MORE REWARDS.</p>
        <div class="membership">
          <img src="<img class="arrow" src="assets/right-arrow.png" alt="right-arrow"/>" />
          <p class="membership-text">MEMBERSHIP IS FREE!</p>
        </div>
      </div>
    </div> -->
  </div>
</ng-container>
