import { get } from 'lodash';

interface IDeserializable {
  deserialize(input: any): this;
}

export class HomeBannerNotice implements IDeserializable {
  id?: string;
  isActive?: boolean;
  name?: string;
  description?: string;
  hyperLink?: string;
  hyperLinkText?: string;
  hyperLinkType?: string;

  deserialize(input: any): this {
    this.id = get(input, 'id');
    this.isActive = get(input, 'isActive');
    this.name = get(input, 'name');
    this.description = get(input, 'description');
    this.hyperLink = get(input, 'hyperLink');
    this.hyperLinkText = get(input, 'hyperLinkText');
    this.hyperLinkType = get(input, 'hyperLinkType');

    return this;
  }
}
