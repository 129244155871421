<div class="main-wrapper">
  <div class="container" id="print-section">
    <div class="container-back"></div>
    <div class="left">
      <myclubrewards-mcr-membership-detail-component></myclubrewards-mcr-membership-detail-component>
    </div>
    <div class="right">
      <myclubrewards-mcr-account-navbar> </myclubrewards-mcr-account-navbar>
      <div class="component-wrapper">
        <router-outlet name="primary"></router-outlet>
      </div>
    </div>
  </div>
  <router-outlet name="secondary"></router-outlet>
</div>
