import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { queries } from './queries';
import { HomeRewardsService } from './home-rewards.service';
import { HomeMcrLocationService } from './home-mcr-location.service';
import { HomeBannerService } from './home-banner.service';
import { HomeBannerNoticeService } from './home-banner-notice.service';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  constructor(
    private apollo: Apollo,
    private homeRewardsService: HomeRewardsService,
    private homeMcrLocationService: HomeMcrLocationService,
    private homeBannerService: HomeBannerService,
    private homeBannerNoticeService: HomeBannerNoticeService
  ) {}

  /**
   * This method fetches HomePage data using a single query only and,
   * Distributes data between respective services where,
   * Each service deserializes it and, shares data using Subjects.
   */
  queryHomePage() {
    return this.apollo
      .query({
        query: gql`
          ${queries.homePageData}
        `,
      })
      .pipe(catchError((err) => err))
      .subscribe((values: any) => {
        if (values.data.allBanners[0]) {
          this.homeBannerService.deserializeHomeBanner(
            values.data.allBanners[0]
          );
        }
        if (values.data.allNotices[0]) {
          this.homeBannerNoticeService.deserializeHomeBannerNotice(
            values.data.allNotices[0]
          );
        }
        if (values.data.allMCRLocations[0]) {
          this.homeMcrLocationService.deserializeHomeMcrLocation(
            values.data.allMCRLocations[0]
          );
        }
        if (values.data.allHomeRewards[0]) {
          this.homeRewardsService.deserializeHomeRewards(
            values.data.allHomeRewards[0]
          );
        }
      });
  }
}
