<div class="wrapper">
  <div class="wrapper--back"></div>

  <div class="wrapper-content">
    <div class="wrapper-content--header">
      <p>MEMBER LOGIN</p>
    </div>
    <div class="wrapper-content--form">
      <form [formGroup]="loginForm" (submit)="onSubmit()">
        <div class="form-feilds">
          <img class="arrow" src="assets/right-arrow.png" alt="right-arrow" />
          <label class="input-label" for="accountId">Club ID </label>

          <input
            placeholder="Enter Club ID"
            formControlName="accountId"
            class="input-feild"
            name="accountId"
            (keyup)="resetInvalid()"
          />

          <label class="input-label" for="password">Password </label>
          <div class="pass">
            <input
              autocomplete="off"
              class="input-feild added-style"
              [type]="fieldTextType ? 'text' : 'password'"
              formControlName="password"
              name="password"
              placeholder="Enter Password"
              (keyup)="resetInvalid()"
            />
            <span class="show" (click)="toggleFieldTextType()">{{
              !!fieldTextType ? 'Hide' : 'Show'
            }}</span>
          </div>

          <div class="forgot">
            <a (click)="openDialog('forgot-password')">FORGOT PASSWORD?</a>
          </div>
        </div>
        <p *ngIf="invalidCredential" class="invalidCredentials">
          Invalid Credentials
        </p>

        <hr class="seperation" />
        <div class="login--footer">
          <div class="button-loader">
            <button
              class="login-button"
              type="submit"
              [ngClass]="{
                'is-disabled': loginForm.invalid || displayLoader
              }"
              [disabled]="loginForm.invalid || displayLoader"
            >
              LOGIN
            </button>
            <div class="loader" *ngIf="displayLoader">
              <myclubshared-loader-component
                [loaderConfig]="setLoaderConfig"
              ></myclubshared-loader-component>
            </div>
          </div>
          <p class="login-text">
            First Time Users, Activate Your Account
            <a class="translate-here">HERE</a>
          </p>
          <p class="terms-condition">
            By activating your account, you acknowledge that you have read,
            understand and accept the
            <a class="tc-text" (click)="scroll()" routerLink="/terms-condition">Terms and Conditions</a>
          </p>
        </div>
      </form>
    </div>
  </div>
</div>
