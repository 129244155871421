<div class="wrapper">
    <div class="header">
        <div class="icon">
            <img src="assets/down-arrow.png" alt="down-arrow" />
            <img mat-dialog-close class="close" src="assets/close.png" alt="close" />
        </div>
        <h1 class="heading" *ngIf="data?.title" [innerHTML]="data.title | safeHtml: 'html'"></h1>
    </div>
    <hr class="seperator" />
    <div *ngIf="data?.bodyHeading" class="text-container">
        <div class="progress-bar">
            <!-- <mat-progress-bar class="progress-error" mode="determinate" value="100"></mat-progress-bar> -->
        </div>

        <p class="heading" *ngIf="data?.bodyHeading" [innerHTML]="data.bodyHeading | safeHtml: 'html'"></p>
        <span class="sub-heading" *ngIf="data?.bodySubHeading" [innerHTML]="data.bodySubHeading | safeHtml: 'html'"></span>
    </div>

    <hr class="seperator" />
    <div *ngIf="data?.key" class="btn-container">
        <button (click)="retry(data?.key)">TRY AGAIN</button>
        <div class="loader" *ngIf="displayLoader">
            <myclubshared-loader-component [loaderConfig]="setLoaderConfig"></myclubshared-loader-component>
        </div>
    </div>

</div>