import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './components/home/home.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RewardsComponent } from './components/rewards/rewards.component';
import { LocationsComponent } from './components/locations/locations.component';
import { CarouselComponent } from './components/carousel/carousel.component';
import { SharedMaterialModule } from 'libs/shared/material/src';
import { AdminSharedModule } from '../../../shared/admin-shared.module';
import { NoticeComponent } from './components/notice/notice.component';

export const homeRoutes: Route[] = [
  {
    path: '',
    component: HomeComponent,
    children: [],
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    SharedMaterialModule,
    AdminSharedModule,
  ],
  declarations: [
    HomeComponent,
    RewardsComponent,
    LocationsComponent,
    CarouselComponent,
    NoticeComponent,
  ],
  exports: [HomeComponent,NoticeComponent],
})
export class HomeModule {}
