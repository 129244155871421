import { Inject, Injectable, PLATFORM_ID } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import {
  LocalStorageService,
  ErrorService,
  IModalData,
  ENVIRONMENT_CONFIG,
  IEnvironmentConfig,
} from "@myclubrewards/shared";
import { catchError } from "rxjs/operators";
import { MatDialogConfig } from "@angular/material/dialog";
import _ from "lodash";
import { AuthorizationService } from "../services/authorization.service";

/**
 * HTTP Error Interceptor to show error as popup when,
 * error status is in 0, 403, 429, 500
 */
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private _authService: AuthorizationService,
    @Inject(PLATFORM_ID) private platformId,
    private localStorageService: LocalStorageService,
    @Inject(ENVIRONMENT_CONFIG) private environment: IEnvironmentConfig,
    private errorService: ErrorService
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err) => {
        // if (!isPlatformBrowser(this.platformId)) {
        //   return;
        // }
        if (request.url.includes(this.environment.GRAPHQL_SERVER)) {
          return;
        }

        if (err instanceof HttpErrorResponse) {
          if (
            err.status !== 0 &&
            err.status !== 403 &&
            err.status !== 429 &&
            err.status !== 500
          ) {
            return throwError(err);
          }

          const modalData: IModalData = {};
          const modalOptions: MatDialogConfig = {};

          /**
           * assign custom options and data when err status is 403 and,
           * user is logged-in
           */
          if (err.status === 403 /* && this._authService.isLoggedIn() */) {
            _.assign(modalOptions, modalOptions, {
              disableClose: true,
            } as MatDialogConfig);

            _.assign(modalData, modalData, {
              displayRedirectButton: true,
            } as IModalData);
          }

          this.errorService.openDialog(err, modalData, modalOptions);
          return throwError(err);
        }
        return throwError(err);
      })
    );
  }
}
