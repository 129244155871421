import { Observable } from 'rxjs';
import { PromotionService } from './../../services/promotion.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PromotionDetails } from '../../models/promotion-detail';

@Component({
  selector: 'myclubrewards-promotion-detail',
  templateUrl: './promotion-detail.component.html',
  styleUrls: ['./promotion-detail.component.scss']
})
export class PromotionDetailComponent implements OnInit {

  getPromotionId: any;
  getPromotion$: Observable<PromotionDetails>;
  constructor(
    private promotionService: PromotionService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PromotionDetailComponent>
  ) {
    // this.getPromotionId = this.route?.params['value'].promotionId;
    // this.window = this.document.defaultView;
  }

  ngOnInit(): void {
    this.getPromotionAccordingToId();
  }

  getPromotionAccordingToId() {
    if (!!this.data.id) {
      this.promotionService.getPromotionDetails(this.data.id);
      this.getPromotion$ = this.promotionService.getPromotionDetails(this.data.id);
    }
  }

}
