<ng-container *ngIf="homeRewards$ | async as homeRewards">
  <div class="bottom-section-wrapper">
    <div class="reward-header">
      <p class="heading" [innerHTML]="homeRewards.heading | safeHtml: 'html'">
        {{ homeRewards.heading }}
      </p>
      <p
        class="sub-heading"
        [innerHTML]="homeRewards.subHeading | safeHtml: 'html'"
      ></p>
      <!-- <img src="assets/down-arrow.png" alt="down-arrow"/> -->
    </div>

    <div class="card-box">
      <div class="home-card-back"></div>
      <div class="home-reward-card-box">
      <myclubrewards-card
        class="home-card"
        *ngFor="let content of homeRewards.contents"
        [config]="content"
      ></myclubrewards-card>
    </div>
    </div>
    <div
      class="reward-text-wrapper"
      *ngIf="homeRewards.footer && homeRewards.footer.isActive"
    >
      <button
        *ngIf="homeRewards.footer.button"
        [routerLink]="homeRewards.footer.button.url | safeHtml: 'url'"
        class="custom-button"
        (click)="scroll()"
      >
        {{ homeRewards.footer.button.name }}
      </button>
      <span
        class="box-text"
        [innerHTML]="homeRewards.footer.body | safeHtml: 'html'"
      ></span>
    </div>
  </div>
</ng-container>
