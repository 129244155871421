import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'myclubrewards-counter',
  templateUrl: './counter.component.html',
  styleUrls: ['./counter.component.scss'],
})
export class CounterComponent {
  count: number = 0;
  @Input() data;
  @Input() index: number;
  @Input() loading: boolean;
  @Output() sendCount = new EventEmitter();

  constructor() {}

  ngOnInit() {
    this.count = this.data.patronTicketsUsed;
  }

  increment() {
    this.count += 1;
  }

  decrement() {
    this.count -= 1;
  }

  sendCounterValue() {
    this.sendCount.emit({
      value: this.count,
      data: this.data,
      index: this.index,
    });
  }

  getTicketsRemaining() {
    return (
      this.data.bookedTickets +
      Math.min(this.data.patronTicketsRemaining, this.data.availableSeats)
    );
  }
}
