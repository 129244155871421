<form [formGroup]="bookEventFG" (submit)="confirm()">
  <div class="wrapper">
    <div class="header">
      <div class="icon">
        <img src="assets/down-arrow.png" alt="down-arrow" />
        <img
          mat-dialog-close
          src="assets/close.png"
          class="close"
          alt="close"
        />
      </div>
      <p class="heading">BOOK TICKETS</p>
    </div>
    <hr class="seperator" />
    <div class="content">
      <div class="content-left">
        <img [src]="bookEventFG.get('imageSrc').value" class="content-image" />
      </div>
      <div class="content-right">
        <p class="content-title">{{ bookEventFG.get('name').value }}</p>
        <p class="points">
          Available Tickets: {{ bookEventFG.get('totalTickets').value }}
        </p>
        <p class="points point--size-change">
          {{ bookEventFG.get('eventDateFormatted').value }}
        </p>
        <p class="counter-container">
          <button
            type="button"
            class="counter-btn"
            value="-"
            (click)="counter($event, bookEventFG.get('bookingCount'))"
            [ngClass]="{
              'is-disabled':
                bookEventFG.get('bookingCount').value <= DECREMENT_STEP
            }"
          >
            -
          </button>
          <span class="count">{{ bookEventFG.get('bookingCount').value }}</span>
          <button
            type="button"
            class="counter-btn"
            value="+"
            (click)="counter($event, bookEventFG.get('bookingCount'))"
            [ngClass]="{
              'is-disabled':
                bookEventFG.get('bookingCount').value >=
                bookEventFG.get('totalTickets').value
            }"
          >
            +
          </button>
        </p>
      </div>
    </div>
    <hr class="seperator" />
    <div class="wrapper--footer">
      <div class="button-loader">
        <button
          class="book-btn"
          type="submit"
          [ngClass]="{
            'is-disabled':
              bookEventFG.status === 'DISABLED' || bookEventFG.invalid
          }"
        >
          BOOK
        </button>
        <div class="loader" *ngIf="displayLoader">
          <myclubshared-loader-component></myclubshared-loader-component>
        </div>
      </div>
    </div>
  </div>
</form>
