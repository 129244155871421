import { get } from 'lodash';

interface IDeserializable {
  deserialize(input: any): this;
}

export class Footer implements IDeserializable {
  id?: string;
  logo?: any;
  phoneNumber?: string;
  description?: string;
  playSmart?: any;
  gateWay?: any;
  copyrightText?: string;
  warningText?: string;
  aboutHeading?: string;
  aboutSubHeading?: string;
  termsAndConditionsHeading?: string;
  privacyPolicyHeading?: string;
  queryHeading?: string;
  ninteenPlusImage?: any;
  isActive?: boolean;

  deserialize(input: any): this {
    this.id = get(input, 'id');
    this.logo = get(input, 'logo');
    this.phoneNumber = get(input, 'phoneNumber');
    this.description = get(input, 'description');
    this.playSmart = get(input, 'playSmart');
    this.gateWay = get(input, 'gateWay');
    this.copyrightText = get(input, 'copyrightText');
    this.warningText = get(input, 'warningText');
    this.aboutHeading = get(input, 'aboutHeading');
    this.aboutSubHeading = get(input, 'aboutSubHeading');
    this.termsAndConditionsHeading = get(input, 'termsAndConditionsHeading');
    this.privacyPolicyHeading = get(input, 'privacyPolicyHeading');
    this.queryHeading = get(input, 'queryHeading');
    this.ninteenPlusImage = get(input, 'ninteenPlusImage');
    this.isActive = get(input, 'isActive');

    return this;
  }
}
