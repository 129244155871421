import { MetaDefinition } from '@angular/platform-browser';
import { DEFAULT_TITLE } from './page-titles';

const baseTags = {
  keywords:
    'Ontario, Casino, Club, Rewards, Loyalty, Benefits, Earn, Redeem, Offers, Gateway, Perks, Free Membership',
  news_keywords:
    'Ontario, Casino, Club, Rewards, Loyalty, Benefits, Earn, Redeem, Offers, Gateway, Perks, Free Membership',
};

const HOME: MetaDefinition[] = [
  {
    name: 'keywords',
    content: baseTags.keywords,
  },
  {
    name: 'news_keywords',
    content: baseTags.news_keywords,
  },
  {
    property: 'og:title',
    content: DEFAULT_TITLE,
  },
  {
    name: 'twitter:title',
    content: DEFAULT_TITLE,
  },
];

const REWARDS: MetaDefinition[] = [
  {
    name: 'keywords',
    content: baseTags.keywords,
  },
  {
    name: 'news_keywords',
    content: baseTags.news_keywords,
  },
  {
    property: 'og:title',
    content: DEFAULT_TITLE,
  },
  {
    name: 'twitter:title',
    content: DEFAULT_TITLE,
  },
];

const GIFT_CARDS: MetaDefinition[] = [
  {
    name: 'keywords',
    content: baseTags.keywords,
  },
  {
    name: 'news_keywords',
    content: baseTags.news_keywords,
  },
  {
    property: 'og:title',
    content: DEFAULT_TITLE,
  },
  {
    name: 'twitter:title',
    content: DEFAULT_TITLE,
  },
];

const EARN_REDEEM: MetaDefinition[] = [
  {
    name: 'keywords',
    content: baseTags.keywords,
  },
  {
    name: 'news_keywords',
    content: baseTags.news_keywords,
  },
  {
    property: 'og:title',
    content: DEFAULT_TITLE,
  },
  {
    name: 'twitter:title',
    content: DEFAULT_TITLE,
  },
];

const FAQ: MetaDefinition[] = [
  {
    name: 'keywords',
    content: baseTags.keywords,
  },
  {
    name: 'news_keywords',
    content: baseTags.news_keywords,
  },
  {
    property: 'og:title',
    content: DEFAULT_TITLE,
  },
  {
    name: 'twitter:title',
    content: DEFAULT_TITLE,
  },
];

const ABOUT: MetaDefinition[] = [
  {
    name: 'keywords',
    content: baseTags.keywords,
  },
  {
    name: 'news_keywords',
    content: baseTags.news_keywords,
  },
  {
    property: 'og:title',
    content: DEFAULT_TITLE,
  },
  {
    name: 'twitter:title',
    content: DEFAULT_TITLE,
  },
];

const PRIVACY_POLICY: MetaDefinition[] = [
  {
    name: 'keywords',
    content: baseTags.keywords,
  },
  {
    name: 'news_keywords',
    content: baseTags.news_keywords,
  },
  {
    property: 'og:title',
    content: DEFAULT_TITLE,
  },
  {
    name: 'twitter:title',
    content: DEFAULT_TITLE,
  },
];

const TERMS_CONDITIONS: MetaDefinition[] = [
  {
    name: 'keywords',
    content: baseTags.keywords,
  },
  {
    name: 'news_keywords',
    content: baseTags.news_keywords,
  },
  {
    property: 'og:title',
    content: DEFAULT_TITLE,
  },
  {
    name: 'twitter:title',
    content: DEFAULT_TITLE,
  },
];

export const SEOTags = {
  HOME,
  REWARDS,
  GIFT_CARDS,
  EARN_REDEEM,
  FAQ,
  ABOUT,
  PRIVACY_POLICY,
  TERMS_CONDITIONS,
};
