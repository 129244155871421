<form [formGroup]="modifyEventFG" (submit)="apply()">
  <div class="wrapper">
    <div class="header">
      <div class="icon">
        <img src="assets/down-arrow.png" alt="down-arrow" />
        <img
          mat-dialog-close
          src="assets/close.png"
          class="close"
          alt="close"
        />
      </div>
      <p class="heading">BOOKED CONCERTS</p>
    </div>
    <hr class="seperator" />
    <div class="content">
      <div class="content-left">
        <img
          [src]="modifyEventFG.get('imageSrc').value"
          class="content-image"
        />
      </div>
      <div class="content-right">
        <p class="content-event-name">{{ modifyEventFG.get('name').value }}</p>
        <p>Available Tickets: {{ modifyEventFG.get('totalTickets').value }}</p>
        <p>{{ modifyEventFG.get('eventDateFormatted').value }}</p>
        <p class="counter-container">
          <button
            type="button"
            class="counter-btn"
            value="-"
            (click)="
              counterModify(
                $event,
                modifyEventFG.get('bookingCount'),
                'decrement'
              )
            "
            [ngClass]="{
              'is-disabled':
                modifyEventFG.get('bookingCount').value <= DECREMENT_STEP
            }"
          >
            -
          </button>
          <span class="count">{{
            modifyEventFG.get('bookingCount').value
          }}</span>
          <button
            type="button"
            class="counter-btn"
            value="+"
            (click)="
              counterModify(
                $event,
                modifyEventFG.get('bookingCount'),
                'increment'
              )
            "
            [ngClass]="{
              'is-disabled':
                modifyEventFG.get('bookingCount').value >=
                getMaximumTicketValue()
            }"
          >
            +
          </button>
        </p>
      </div>
    </div>
    <hr class="seperator" />
    <div class="wrapper--footer">
      <div class="button-loader">
        <button class="cancel-btn" type="button" (click)="cancelBooking()">
          CANCEL BOOKING
        </button>
        <div class="loader" *ngIf="displayLoader">
          <myclubshared-loader-component></myclubshared-loader-component>
        </div>
        <button
          class="apply-btn"
          type="submit"
          [ngClass]="{
            'is-disabled':
              modifyEventFG.status === 'DISABLED' || modifyEventFG.invalid
          }"
        >
          APPLY CHANGES
        </button>
      </div>
    </div>
  </div>
</form>
