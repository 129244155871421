import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  appConstants,
  ENVIRONMENT_CONFIG,
  IEnvironmentConfig,
  ModalService,
  regexValidator,
} from "@myclubrewards/shared";
import { CountdownComponent } from "ngx-countdown";
import { interval, Subscription } from "rxjs";
import { AuthorizationService } from "../../../services/authorization.service";
import { CongratulationsComponent } from "../../congratulations/congratulations.component";
import { RetryComponent } from "../../retry/retry.component";

@Component({
  selector: "myclubrewards-change-profile-password",
  templateUrl: "./change-profile-password.component.html",
  styleUrls: [
    "./change-profile-password.component.scss",
    "../../../../../../shared/components/modal/modal.component.scss",
  ],
})
export class ChangeProfilePasswordComponent implements OnInit, OnDestroy {
  @ViewChild("cd1", { static: false }) private countdown: CountdownComponent;
  private subscription: Subscription;
  private timer$: Subscription;
  fieldTextType: Boolean;
  fieldTextTypecon: Boolean;
  fieldTextTypecur: Boolean;
  changePasswordForm: FormGroup;
  same: Boolean = false;
  timer: any;
  stopTime: boolean;
  progressbarValue = 0;
  curSec: number = 0;
  invalidCredential: boolean = false;
  displayLoader: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ChangeProfilePasswordComponent>,
    private modalService: ModalService,
    private fb: FormBuilder,
    private authService: AuthorizationService,
    @Inject(ENVIRONMENT_CONFIG) private environment: IEnvironmentConfig
  ) {
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    this.timer = this.environment.PASSWORD_RESET_TIMEOUT_SEC;
    this.startTimer(parseInt(this.timer) - 2);
    this.initFormGroup();
  }

  private initFormGroup() {
    this.changePasswordForm = this.fb.group({
      currentPassword: this.fb.control("", [Validators.required]),
      newPassword: this.fb.control("", [
        Validators.required,
        Validators.minLength(appConstants.passwordMinLength),
        Validators.maxLength(appConstants.passwordMaxLength),
        regexValidator(new RegExp(appConstants.regex.passwordNumberRegex), {
          number: true,
        }),
        regexValidator(new RegExp(appConstants.regex.passwordSymbolRegex), {
          symbol: true,
        }),
        regexValidator(new RegExp(appConstants.regex.passworedLetterRegex), {
          letter: true,
        }),
      ]),
      confirmPassword: this.fb.control("", [Validators.required]),
    });
  }

  resetInvalid() {
    this.invalidCredential = false;
  }

  submitEnter(e, form) {
    if (e.keyCode === 13 && !form.invalid) {
      this.changePassword();
    }
  }

  timerCompleted(event: any) {
    if (event.status === 3) {
      let res = {
        title: "TIME EXPIRED",
        bodyHeading:
          'Your time to create your password has <b class="error">expired</b>.  Please try again.',
        key: "change-profile-password",
      };
      this.modalService.openDialog<RetryComponent>(res, null, RetryComponent);
      this.close();
    }
  }

  startTimer(seconds: number) {
    this.timer$ = interval(1000).subscribe((sec) => {
      this.progressbarValue = 2 + (sec * 100) / seconds;
      this.curSec = sec;
    });
  }

  stopTimer() {
    this.countdown.stop();
    this.timer$.unsubscribe();
  }

  get form() {
    return this.changePasswordForm.controls;
  }

  regexValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = regex.test(control.value);
      return valid ? null : error;
    };
  }

  checkPasswords() {
    this.resetInvalid();
    if (
      this.changePasswordForm.get("newPassword").value !==
      this.changePasswordForm.get("confirmPassword").value
    ) {
      this.changePasswordForm.setErrors({ invalid: true });
      this.same = true;
    } else {
      this.same = false;
    }
  }

  close() {
    this.dialogRef.close(true);
  }
  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  toggleFieldTextTypecon() {
    this.fieldTextTypecon = !this.fieldTextTypecon;
  }
  toggleFieldTextTypecur() {
    this.fieldTextTypecur = !this.fieldTextTypecur;
  }

  changePassword() {
    if (this.changePasswordForm.invalid) {
      return;
    } else {
      this.stopTime = false;
      this.stopTimer();
      this.displayLoader = true;
      const data = {
        currentPassword: this.changePasswordForm.get("currentPassword").value,
        newPassword: this.changePasswordForm.get("newPassword").value,
      };

      this.subscription.add(
        this.authService.changePassword(data).subscribe(
          () => {
            this.displayLoader = false;
            this.close();
            this.modalService.openDialog<CongratulationsComponent>(
              { context: "password-changed" },
              undefined,
              CongratulationsComponent
            );
          },
          (err) => {
            this.displayLoader = false;
            if (err.status === 400 || err.status === 401) {
              this.stopTime = true;
              this.countdown.restart();
              this.progressbarValue = 0;
              this.startTimer(parseInt(this.timer) - 2);
              this.invalidCredential = true;
            }
          }
        )
      );
    }
  }

  ngOnDestroy() {
    this.timer$.unsubscribe();
    this.subscription.unsubscribe();
  }
}
