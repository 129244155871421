import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HomeBanner } from '../models/home-banner';

@Injectable({
  providedIn: 'root',
})
export class HomeBannerService {
  private homeBannerSubject: Subject<HomeBanner>;
  homeBanner$: Observable<HomeBanner>;

  constructor() {
    this.homeBannerSubject = new Subject<HomeBanner>();
    this.homeBanner$ = this.homeBannerSubject.asObservable();
  }

  deserializeHomeBanner(input: any) {
    const temp: HomeBanner = new HomeBanner().deserialize(input);
    this.homeBannerSubject.next(temp);
  }
}
