import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { HomeMcrLocation } from '../models/home-mcr-location';

@Injectable({
  providedIn: 'root',
})
export class HomeMcrLocationService {
  private homeMcrLocationSubject: Subject<HomeMcrLocation>;
  homeMcrLocation$: Observable<HomeMcrLocation>;

  constructor() {
    this.homeMcrLocationSubject = new Subject<HomeMcrLocation>();
    this.homeMcrLocation$ = this.homeMcrLocationSubject.asObservable();
  }

  deserializeHomeMcrLocation(input: any) {
    const temp = new HomeMcrLocation().deserialize(input);
    this.homeMcrLocationSubject.next(temp);
  }
}
