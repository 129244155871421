import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LayoutOneComponent } from './components/layouts/layout-one/layout-one.component';
import { GraphqlModule } from '../../../../../../../../libs/graphql/src/lib/graphql.module';
import { AdminSharedModule } from '../../../../../../../../libs/shared/admin-shared.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FlexLayoutModule,
    GraphqlModule,
    AdminSharedModule,
  ],
  declarations: [FooterComponent, HeaderComponent, LayoutOneComponent],
  exports: [FooterComponent, HeaderComponent, LayoutOneComponent],
})
export class ThemeModule {}
