import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { HomeBannerNotice } from '../models/home-banner-notice';

@Injectable({
  providedIn: 'root',
})
export class HomeBannerNoticeService {
  private homeBannerNoticeSubj: BehaviorSubject<HomeBannerNotice>;
  homeBannerNotice$: Observable<HomeBannerNotice>;

  constructor() {
    this.homeBannerNoticeSubj = new BehaviorSubject<HomeBannerNotice>(
      undefined
    );
    this.homeBannerNotice$ = this.homeBannerNoticeSubj.asObservable();
  }

  deserializeHomeBannerNotice(input: any) {
    const temp: HomeBannerNotice = new HomeBannerNotice().deserialize(input);
    this.homeBannerNoticeSubj.next(temp);
  }
}
