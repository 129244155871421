import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'myclubshared-loader-component',
  templateUrl: './loader-component.component.html',
  styleUrls: ['./loader-component.component.scss'],
})
export class LoaderComponentComponent implements OnInit {
  setLoaderProperties: any;

  constructor() {}
  private _loaderConfig;

  @Input('loaderConfig') loaderConfig(value: any) {}

  ngOnInit(): void {
    this.setLoaderProperties = {
      diameter: this.loaderConfig['diameter'] ?? '40',
      color: this.loaderConfig['color'] ?? '',
      strokeWidth: this.loaderConfig['strokeWidth'] ?? '',
      value: this.loaderConfig['value'] ?? '20',
      defaultColor: this.loaderConfig['defaultColor'] ?? 'black',
      mode: this.loaderConfig['mode'] ?? 'indeterminate',
    };
  }
}
