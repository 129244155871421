import { CookieStorageService } from './../../../../shared/services/cookie-storage.service';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { CanActivate } from '@angular/router';
import { appConstants, LocalStorageService } from '@myclubrewards/shared';
import { AuthorizationService } from '../services/authorization.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthorizationService,
    private localStorageService: LocalStorageService,
    private cookieStorageService: CookieStorageService,
    @Inject(PLATFORM_ID) private platformId
  ) {}

  canActivate(): boolean {
    // if (isPlatformBrowser(this.platformId)) {
    if (this.authService.isUserLoggedIn()) {
      return true;
    } else {
      const id: number = +this.cookieStorageService.getUserIdFromCookies();
      const accessToken = this.cookieStorageService.getItem(
        appConstants.auth.accessToken
      );

      this.authService.logoutUser(id, accessToken);
      return false;
    }
    // }
  }
}
