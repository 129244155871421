<div
  class="wrapper"
  *ngIf="userPromotionDetails$ | async as userPromotions; else displayLoader"
>
  <div class="header" *ngIf="userPromotions.isActive">
    <p class="heading">{{ userPromotions?.promotionHeading }}</p>
    <div
      class="sub-heading"
      [innerHTML]="userPromotions?.promotionSubHeading | safeHtml: 'html'"
    ></div>
    <img src="assets/down-arrow.png" alt="down-arrow" />
  </div>
  <div class="wrapper--content">
    <div class="image">
      <img
        [src]="
          (userPromotions?.imageEn)[0]?.file?.publicUrl ||
            (userPromotions?.imageFr)[0]?.file?.publicUrl | safeHtml: 'url'
        "
      />
    </div>
    <div
      class="text"
      [innerHTML]="userPromotions?.promotionFooter | safeHtml: 'html'"
    ></div>
  </div>
</div>
<ng-template #displayLoader>
  <myclubshared-loader-component
  ></myclubshared-loader-component>
</ng-template>
