import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import {
  appConstants,
  ENVIRONMENT_CONFIG,
  IEnvironmentConfig,
  LocalStorageService,
} from '@myclubrewards/shared';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoginDataDTO, User } from '../models/auth';
import { CookieStorageService } from './../../../../shared/services/cookie-storage.service';
import { EncryptService } from './../../../../shared/utils/src/lib/encrypt.service';

@Injectable({
  providedIn: 'root',
})
export class AuthorizationService {
  private currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;
  constructor(
    private http: HttpClient,
    private router: Router,
    private localStorageService: LocalStorageService,
    private encryptService: EncryptService,
    @Inject(PLATFORM_ID) private platformId,
    @Inject(ENVIRONMENT_CONFIG) private environment: IEnvironmentConfig,
    private cookieStorageService: CookieStorageService
  ) {
    // if (isPlatformBrowser(this.platformId)) {
    this.currentUserSubject = new BehaviorSubject<any>(
      this.localStorageService.getUser()
    );
    this.currentUser = this.currentUserSubject.asObservable();
    // }
  }

  loginUser(user: LoginDataDTO) {
    user.password = this.encryptService.encryptPassWord(user.password);
    return this.http
      .post(`${this.environment.BASE_URL}/exp/api/v1/token`, user, {
        observe: 'response',
      })
      .pipe(
        tap((res) => {
          this.setLoggedInUser(res);
        })
      );
  }

  public get currentUserValue() {
    return this.currentUserSubject.value;
  }

  public updateCurrentUser(value) {
    this.currentUserSubject.next(value);
  }

  logoutUser(id: number, token: string) {
    this.localStorageService.clear();
    this.updateCurrentUser(null);
    this.cookieStorageService.deleteCookie();
    this.router.navigate(['']);
  }

  isLoggedIn() {
    return this.localStorageService.checkLogin();
  }

  isUserLoggedIn() {
    return this.cookieStorageService.checkLoginFromCookies();
  }

  setLoggedInUser(response: any) {
    const user = new User().deserialize(response);
    // this.localStorageService.setUser(user);
    this.cookieStorageService.setUserInCookies(user);
  }

  resetPassword(data, accountId) {
    data.password = this.encryptService.encryptPassWord(data.password);
    return this.http.post(
      `${this.environment.BASE_URL}/exp/api/v1/players/${accountId}/create-password`,
      data
    );
  }

  forgotPassword(pinNumber: string, accountId: string) {
    return this.http.post(
      `${this.environment.BASE_URL}/exp/api/v1/forgot-password`,
      {
        pinNumber: pinNumber,
        accountId: accountId,
      }
    );
  }

  changePassword(data) {
    const accountId = this.localStorageService.getUserId();

    data.currentPassword = this.encryptService.encryptPassWord(
      data.currentPassword
    );
    data.newPassword = this.encryptService.encryptPassWord(data.newPassword);
    return this.http.post(
      `${this.environment.BASE_URL}/exp/api/v1/players/${accountId}/update-password`,
      data
    );
  }

  refreshToken(data?: any) {
    const id = this.cookieStorageService.getUserIdFromCookies();
    return this.http.post(
      `${this.environment.BASE_URL}/exp/api/v1/players/${id}/refresh-token`,
      data,
      {
        headers: this.addHeaders(),
      }
    );
  }

  private addHeaders() {
    const httpHeaders: HttpHeaders = new HttpHeaders({
      [appConstants.auth.clientId]: this.environment.CLIENT_ID,
      [appConstants.auth.clientSecret]: this.environment.CLIENT_SECRET,
      [appConstants.auth.refreshToken]: this.cookieStorageService.getItem(
        appConstants.auth.refreshToken
      ),
    });

    return httpHeaders;
  }
}
