import { OfferMenuTab, OfferTabs } from './../models/offer-tab-model';
import { EventCategories } from './../models/event-categories';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  OfferDetails,
  OffersAndEvents,
  OFFER_MAPPING,
} from '../models/offer-modal';
import { BehaviorSubject, forkJoin, Observable, of, throwError } from 'rxjs';
import { map, catchError, switchMap, tap } from 'rxjs/operators';
import {
  CookieStorageService,
  DateService,
  ENVIRONMENT_CONFIG,
  ErrorService,
  IEnvironmentConfig,
  LocalStorageService,
} from '@myclubrewards/shared';
import { get } from 'lodash';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { queries } from './queries';
import { PatronEvent } from '../models/patron-event';
import { BookPatronEventDTO } from '../dto/book-patron-event.dto';
import { ActivatedRoute } from '@angular/router';
import { BaseOffersService } from './base-offers.service';
import moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class ExclusiveOfferService extends BaseOffersService {
  private offersAndEvents: OffersAndEvents;
  private OfferMenuTabSubj: BehaviorSubject<OfferMenuTab[]>;
  private exclusiveOffersSubj: BehaviorSubject<OfferDetails[] | PatronEvent[]>;
  private showLoaderSubj: BehaviorSubject<boolean>;
  private eventCategoriesSubj: BehaviorSubject<EventCategories>;
  private subCategoryArray;

  OfferMenuTab$: Observable<OfferMenuTab[]>;
  exclusiveOffers$: Observable<any[]>;
  showLoader$: Observable<boolean>;

  constructor(
    protected dateService: DateService,
    protected http: HttpClient,
    protected errorService: ErrorService,
    @Inject(ENVIRONMENT_CONFIG) protected environment: IEnvironmentConfig,
    protected apollo: Apollo,
    protected cookieStorage: CookieStorageService,
    protected localStorageService: LocalStorageService,
    protected route: ActivatedRoute
  ) {
    super(apollo, http, environment);
    this.OfferMenuTabSubj = new BehaviorSubject<OfferMenuTab[]>(undefined);
    this.exclusiveOffersSubj = new BehaviorSubject<any[]>(undefined);
    this.showLoaderSubj = new BehaviorSubject<boolean>(true);
    this.eventCategoriesSubj = new BehaviorSubject<EventCategories>(undefined);
    this.OfferMenuTab$ = this.OfferMenuTabSubj.asObservable();
    this.exclusiveOffers$ = this.exclusiveOffersSubj.asObservable();
    this.showLoader$ = this.showLoaderSubj.asObservable();
  }

  getOffersAndEvents(): Observable<OffersAndEvents> {
    return forkJoin({
      expOffers: this.getPlayerOffers(),
      events: this.getPatronOffersAvatar(),
      cmsEvents: this.buildCMSEventsQuery(),
    }).pipe(
      switchMap((res) => {
        this.subCategoryArray = res.cmsEvents?.data.allSubCategoriesMappings;
        // step 1.1: map CMS-Offer images with GMS-Offers
        res = this.mapCMSOfferImages(res);
        // step 1.2: map CMS-Event images with Avatar-Offers (Events)
        res = this.mapCMSEventImages(res, 'events');
        return of({
          offers: res.expOffers,
          events: res.events as any[],
        });
      }),
      map((data) => {
        this.offersAndEvents = new OffersAndEvents().deserialize(data);
        const filters = {
          ...this.localStorageService.getSavedFilter(),
        };
        const userSelections = this.getUserSelections(filters);

        this.getFilteredOffers(
          undefined,
          filters.startDate,
          filters.endDate,
          userSelections.selectedLocations,
          userSelections.selectedCategories,
          this.localStorageService.getSavedFilterTab()
        );
        return this.offersAndEvents;
      }),

      catchError((err, caught) => of(err))
    );
  }

  getFilteredOffers(
    offerTypeCode: string,
    startDate?: number,
    endDate?: number,
    locations?: string[],
    eventCategories?: string[],
    offerType?: string
  ): void {
    offerTypeCode = offerTypeCode ?? OFFER_MAPPING[offerType];
    if (
      offerTypeCode?.toLowerCase() === 'se' ||
      offerTypeCode?.toLowerCase() === 'c'
    ) {
      this.filterEvents(startDate, endDate, offerTypeCode, eventCategories);
    } else {
      this.filterGMSOffer(offerTypeCode, startDate, endDate, locations);
    }
  }

  checkIfBetween(startDate, endDate, dateToCheck) {
    return moment(dateToCheck).isBetween(startDate, endDate);
  }

  private filterOfferByDates(data, startDate, endDate) {
    var output = [];
    var offerStart;
    var offerEnd;
    startDate = moment.unix(startDate).format('YYYY-MM-DD');
    endDate = moment.unix(endDate).format('YYYY-MM-DD');

    for (let i = 0; i < data.length; i++) {
      offerStart = moment.unix(data[i]?.validFromTs).format('YYYY-MM-DD');
      offerEnd = moment.unix(data[i]?.expireFromTs).format('YYYY-MM-DD');

      if (this.checkIfBetween(startDate, endDate, offerStart)) {
        output[i] = data[i];
      } else if (moment(offerStart).isSame(startDate)) {
        output[i] = data[i];
      } else if (this.checkIfBetween(startDate, endDate, offerEnd)) {
        output[i] = data[i];
      } else if (moment(offerEnd).isSame(startDate)) {
        output[i] = data[i];
      } else if (this.checkIfBetween(offerStart, offerEnd, startDate)) {
        output[i] = data[i];
      } else if (moment(offerStart).isSame(endDate)) {
        output[i] = data[i];
      } else if (this.checkIfBetween(offerStart, offerEnd, endDate)) {
        output[i] = data[i];
      }
    }
    return output;
  }

  private filterEventsByDates(data, startDate, endDate) {
    var output = [];
    var eventStart;
    var eventEnd;
    startDate = moment.unix(startDate).format('YYYY-MM-DD');
    endDate = moment.unix(endDate).format('YYYY-MM-DD');

    for (let i = 0; i < data.length; i++) {
      eventStart = moment
        .unix(data[i]?.eventSessionStartDate)
        .format('YYYY-MM-DD');
      eventEnd = moment.unix(data[i]?.eventSessionEndDate).format('YYYY-MM-DD');

      if (this.checkIfBetween(startDate, endDate, eventStart)) {
        output[i] = data[i];
      } else if (moment(eventStart).isSame(startDate)) {
        output[i] = data[i];
      } else if (this.checkIfBetween(startDate, endDate, eventEnd)) {
        output[i] = data[i];
      } else if (moment(eventEnd).isSame(startDate)) {
        output[i] = data[i];
      } else if (this.checkIfBetween(eventStart, eventEnd, startDate)) {
        output[i] = data[i];
      } else if (moment(eventStart).isSame(endDate)) {
        output[i] = data[i];
      } else if (this.checkIfBetween(eventStart, eventEnd, endDate)) {
        output[i] = data[i];
      }
    }
    return output;
  }

  private filterGMSOffer(
    offerTypeCode: string,
    startDate?: number,
    endDate?: number,
    locations: any[] = []
  ): void {
    let temp: OfferDetails[] = get(
      this.offersAndEvents,
      ['exclusiveOffers', offerTypeCode],
      []
    );

    if (!temp?.length || !locations?.length) {
      this.showLoaderSubj.next(false);
      this.exclusiveOffersSubj.next(temp);
    } else {
      [startDate, endDate] = [
        Math.floor(this.dateService.getStartOfDayTimestamp(startDate) / 1000),
        Math.floor(this.dateService.getEndOfDayTimestamp(endDate) / 1000),
      ];

      temp = this.filterOfferByDates(temp, startDate, endDate);

      if (locations?.length) {
        temp = temp.filter((x) =>
          locations.some((y) => x.location.includes(y))
        );
      }

      this.showLoaderSubj.next(false);
      this.exclusiveOffersSubj.next(temp);
    }
  }

  private filterEvents(
    startDate?: number,
    endDate?: number,
    offerTypeCode?: string,
    eventCategories?: string[]
  ): void {
    let temp: PatronEvent[] = get(this.offersAndEvents, ['events'], []);
    temp = this.mapEventsWithType(temp);
    temp = temp.filter((event) => event.totalTickets !== 0);

    if (!temp.length) {
      this.showLoaderSubj.next(false);
      this.exclusiveOffersSubj.next(temp);
    } else {
      [startDate, endDate] = [
        Math.floor(this.dateService.getStartOfDayTimestamp(startDate) / 1000),
        Math.floor(this.dateService.getEndOfDayTimestamp(endDate) / 1000),
      ];

      temp = this.filterEventsByDates(temp, startDate, endDate);

      /**
       * TODO: Implement locations filtering on events as well,
       * once API returns locations in response
       */
      /* if (locations?.length) {
      temp = temp.filter((x) => locations.some((y) => x.location.includes(y)));
    } */

      /**
       * filter events based on categories
       */
      if (eventCategories?.length) {
        temp = temp.filter(
          (x) => x && eventCategories.includes(x.eventCategoryName)
        );
      } else {
        temp = [];
      }

      if (
        (offerTypeCode?.toLowerCase() == 'se' ||
          offerTypeCode?.toLowerCase() == 'c') &&
        temp.length
      ) {
        const typeObj = temp.filter(
          (x) => x?.type?.toLowerCase() == offerTypeCode?.toLowerCase()
        );
        temp = typeObj;
      }

      this.showLoaderSubj.next(false);
      this.exclusiveOffersSubj.next(temp);
    }
  }

  mapEventsWithType(data: PatronEvent[]) {
    for (let index = 0; index < data.length; index++) {
      let temp = this.subCategoryArray.filter(function (x) {
        if (
          x.name?.toLowerCase() ==
            data[index].eventCategoryName?.toLowerCase() &&
          x.offerType?.length
        ) {
          return x.offerType[0].name;
        }
      });

      if (temp.length) {
        temp = temp[0].offerType[0].name;
      } else {
        temp = 'concerts';
      }

      if (temp == 'concerts') {
        data[index].isConcert = true;
      } else {
        data[index].isConcert = false;
      }
    }
    return data;
  }

  getAllCategoriesFromApi() {
    return this.http.get(this.buildUrl(`/exp/api/v1/categories`)).pipe(
      catchError((err) => {
        return throwError(err);
      }),
      map((data: any[]) => {
        if (data) {
          for (let i = 0; i < data.length; i++) {
            data[i].offerType = [];
            data[i]?.offerType.push({ name: 'concerts' });
          }
          return data;
        }
      })
    );
  }

  getAllEventCategories(): Observable<EventCategories> {
    return forkJoin({
      apiCategories: this.getAllCategoriesFromApi(),
      cmsEvents: this.buildCMSEventsQuery(),
    }).pipe(
      catchError((err) => {
        return throwError(err);
      }),
      map((res) => {
        if (res) {
          for (let i = 0; i < res.apiCategories.length; i++) {
            let temp = res.cmsEvents.data.allSubCategoriesMappings.filter(
              function (x) {
                if (
                  x.name?.toLowerCase() ==
                    res.apiCategories[i].eventCategoryName?.toLowerCase() &&
                  x?.offerType.length
                ) {
                  let val = x?.offerType[0].name;
                  return val;
                }
              }
            );

            if (temp.length) {
              res.apiCategories[i].offerType[0].name =
                temp[0].offerType[0].name;
            } else {
              res.apiCategories[i].offerType[0].name = 'concerts';
            }
          }

          return new EventCategories().deserialize(res.apiCategories);
        }
      })
    );
  }

  getAllOfferFiltersTab() {
    return this.apollo
      .query({
        query: gql`
          ${queries.exclusiveFilterTabData}
        `,
      })
      .pipe(
        catchError((err) => err),
        tap((res: any) => {
          if (res) {
            const temp = new OfferTabs().deserialize({
              offerFiltersTab: res.data.allExclusiveOffersMenus,
            });
            this.OfferMenuTabSubj.next(temp.offerTabList);
            return temp;
          }
        })
      );
  }

  getExclusiveOffersMenu(): OfferMenuTab[] {
    return this.OfferMenuTabSubj.value;
  }

  bookPatronEvent(patronId: string, data: BookPatronEventDTO): Observable<any> {
    return this.http.post(
      this.buildUrl(`/exp/api/v1/players/${patronId}/bookPatronOfferEvent`),
      data.toJSON()
    );
  }

  getPlayerOffers(): Observable<any[]> {
    const patronId = this.cookieStorage.getUserIdFromCookies();

    return this.http
      .get<any[]>(
        this.buildUrl(`/exp/api/v1/players/${patronId}/offers/ALL/ALL/false`)
      )
      .pipe(catchError((err) => throwError(err)));
  }

  getPatronOffersAvatar(): Observable<any[]> {
    const patronId = this.cookieStorage.getUserIdFromCookies();

    return this.http
      .get<any[]>(this.buildUrl(`/exp/api/v1/events?accountNumber=${patronId}`))
      .pipe(catchError((err) => throwError(err)));
  }

  mapOfferTypeToCode(offerType: string): string {
    if (!offerType) return '';

    return this.getExclusiveOffersMenu()?.find(
      (x) => x.offerType?.toLowerCase() === offerType?.toLowerCase()
    ).offerTypeCode;
  }
}
