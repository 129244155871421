import { PromotionItem } from './promotion-item';
import { get, map } from 'lodash';

interface IDeserializable {
  deserialize(input: any): this;
}

export class PromotionDetails implements IDeserializable {
  isActive?: boolean;
  heading?: string;
  subHeading?: string;
  items?: PromotionItem[];
  imageSrc: string;
  details?: string;

  constructor() {}

  deserialize(input: any): this {
    this.heading = get(input, 'heading');
    this.subHeading = get(input, 'subHeading');
    this.isActive = get(input, 'isActive');
    this.imageSrc = this.buildPromotionImage(input);
    this.details = get(input, 'details');
    var temp = this.details?.toString();
    if (temp) {
      var count = (temp.match(/<p>/g) || []).length;
      while(count !== 0) {
        temp = temp.replace('<p>','');
        temp = temp.replace('</p>','');
        count--;
      }
      this.details = temp;
    }
    this.items = this.buildPromotionItems(get(input, 'items', []));
    return this;
  }

  private buildPromotionItems(input: any[]) {
    if (!input.length) return [];

    const tempPromotion: PromotionItem[] = map(input, (category) => {
      return new PromotionItem().deserialize(category);
    });
    return tempPromotion;
  }

  private buildPromotionImage(input: any): string {
    if (!input) return '';
    if (!!input?.imageEn?.file?.publicUrl && input?.imageEn?.isActive)
      return input?.imageEn?.file?.publicUrl;
    if (!!input?.imageFr?.file?.publicUrl && input?.imageFr?.isActive)
      return input?.imageFr?.file?.publicUrl;
  }
}
