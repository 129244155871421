import { fromEvent, Subscription } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { Router } from '@angular/router';
import {
  Component,
  Inject,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  LocalStorageService,
  ModalComponent,
  ModalService,
  NavbarItem,
} from '@myclubrewards/shared';

@Component({
  selector: 'myclubrewards-mcr-account-navbar',
  templateUrl: './account-navbar.component.html',
  styleUrls: ['./account-navbar.component.scss'],
})
export class McrAccountNavbarComponent implements OnInit, OnDestroy {
  @ViewChild('movileNavbarMenu') movileNavbarMenu: ElementRef;
  private userId: string;
  private window: Window;
  subscription: Subscription;

  navBarItems: NavbarItem[];

  selectedTabIndex: number;
  showMenu: boolean = false;

  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    private modalService: ModalService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.window = this._document.defaultView;
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    this.initialiseNavBar();
    this.displayAccordingNavigation();
    this.detectTouchOutsideNavbar();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  initialiseNavBar(): void {
    this.userId = this.localStorageService.getUserId();
    this.navBarItems = [
      {
        name: 'dashboard',
        link: `/account/${this.userId}/dashboard`,
        navBarIndex: 0,
      },
      {
        name: 'offers',
        link: `/account/${this.userId}/offers/concerts`,
        navBarIndex: 1,
      },
      {
        name: 'profile',
        link: `/account/${this.userId}/profile`,
        navBarIndex: 3,
      },
      {
        name: 'reservations',
        link: `/account/${this.userId}/reservations`,
        navBarIndex: 2,
      },
    ];
    this.navBarItems.sort(
      (a: NavbarItem, b: NavbarItem) => a.navBarIndex - b.navBarIndex
    );
    this.selectedTabIndex = this.navBarItems[0]?.navBarIndex;
  }

  /*
    This method does the following thing:
    * Gets the saved filter tab
    * If the tab is saved in local storage then it will redirect to saved tab other wise default tab is selected
    * If route containes any optional parameters then paramater is added otherwise router url is selected
    */
  displayAccordingNavigation(navBarItem?: NavbarItem): void {
    const savedExclusiveOfferTab = this.localStorageService.getSavedFilterTab();
    this.navBarItems[1].link = !!savedExclusiveOfferTab
      ? `/account/${this.userId}/offers/${savedExclusiveOfferTab}`
      : `/account/${this.userId}/offers/concerts`;
    const url = navBarItem?.link || this.router.url;
    const item = this.navBarItems.find((item) => item.link.includes(url));
    this.selectedTabIndex = url.includes('offers')
      ? 1
      : item?.navBarIndex
      ? item?.navBarIndex
      : 0;
    item?.optionalRouteParams
      ? this.router.navigate([item?.link, item?.optionalRouteParams])
      : item?.link
      ? this.router.navigate([item?.link])
      : this.router.navigate([this.router.url]);
  }

  openDialog(): void {
    this.modalService.openDialog<ModalComponent>({
      title: '<p>COMING SOON</p>',
      bodyHeading: 'Offers coming soon!',
    });
  }

  onPrint() {
    this.window.print();
  }

  toggleNavMenu(): void {
    this.showMenu = !this.showMenu;
  }

  detectTouchOutsideNavbar() {
    this.subscription.add(
      fromEvent(this._document, 'touchstart').subscribe((event) => {
        if (!this.movileNavbarMenu?.nativeElement.contains(event.target)) {
          this.showMenu = false;
        } else {
          event.stopPropagation();
        }
      })
    );
  }
}
