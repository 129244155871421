export const DEFAULT_TITLE = 'My Club Rewards – Bigger. Better. More Rewards!.';

export const PAGE_TITLES = {
  HOME_PAGE_TITLE: DEFAULT_TITLE,
  REWARDS_PAGE_TITLE: DEFAULT_TITLE,
  GIFT_CARDS_PAGE_TITLE: DEFAULT_TITLE,
  EARN_REDEEM_PAGE_TITLE: DEFAULT_TITLE,
  FAQ_PAGE_TITLE: DEFAULT_TITLE,
  ABOUT_PAGE_TITLE: DEFAULT_TITLE,
  PRIVACY_POLICY_PAGE_TITLE: DEFAULT_TITLE,
  TERMS_CONDITIONS_PAGE_TITLE: DEFAULT_TITLE,
};
