<div class="wrapper">
  <div class="header">
    <div class="icon">
      <img src="assets/down-arrow.png" alt="down-arrow" />
      <img mat-dialog-close src="assets/close.png" class="close" alt="close" />
    </div>
    <p class="heading">CANCEL CONFIRMATION</p>
  </div>
  <hr class="seperator" />
  <div class="content">
    <p class="content__text">
      You have successfully cancelled your booking reservation and tickets for
      {{ data?.name }}. The amount of ${{ amountCancelled() }} will be credited
      back to your Resort Rewards account. <br /><br />If you have any questions
      or concerns with the cancelation of this booking, please contact us at
      <a class="value" href="tel:18008327529"> 1-800-832-7529 </a>. <br /><br />
      In order to receive email confirmations regarding your bookings and
      reservations, please ensure you consent to receiving email communications
      which is available on the ‘Profile’ tab.
    </p>
  </div>
  <hr class="seperator" />
  <div class="wrapper--footer">
    <div class="button-loader">
      <button class="book-btn" (click)="close()">
        OK
      </button>
    </div>
  </div>
</div>
