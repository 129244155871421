import { Injectable } from '@angular/core';
import { ModalComponent } from '../components/modal/modal.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import _ from 'lodash';

/**
 * Modal Service Wrapper.
 */
@Injectable({
  providedIn: 'root',
})
export class ModalService {
  constructor(private dialog: MatDialog) {}

  /**
   * This method is used to open (show) modal and, returns it's current reference.
   * Uses Angular Material dialog.
   */
  openDialog<C>(
    modalData: any,
    options?: any,
    component?: any
  ): MatDialogRef<C | ModalComponent> {
    return this.dialog.open(
      component || ModalComponent,
      _.assign(
        {},
        {
          width: options?.width || '',
          data: modalData || undefined,
        },
        options
      )
    );
  }
}
