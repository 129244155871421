import { Directive, ElementRef, HostListener } from '@angular/core';
import { appConstants } from '@myclubrewards/shared';

@Directive({
  selector: '[appAvoidUnnecessaryCharacterDirective]',
})
export class AvoidUnnecessaryCharacterDirective {
  constructor(private el: ElementRef) {}

  @HostListener('keypress', ['$event']) onKeyPress(e) {
    switch (this.el.nativeElement.type) {
      case 'text':
        !e.key.match(appConstants.regex.avoidAlphabetUserInput) &&
          e.keyCode !== 8 &&
          e.keyCode !== 9 &&
          e.preventDefault();
        break;
      case 'tel':
        e.key.match(appConstants.regex.avoidUnnecessaryUserInput) &&
          e.preventDefault();
        break;
      default:
        break;
    }
  }
}
