<ng-container *ngIf="headers$ | async as headers">
    <div class="doNotPrint header-wrapper" *ngIf="headers?.isActive">
        <div class="menu_button" (click)="toggleMenuButton()" [ngClass]="status ? 'change' : ''">
            <div class="bar1"></div>
            <div class="bar2"></div>
            <div class="bar3"></div>
        </div>
        <a *ngIf="(headers?.logo)[0]?.isActive" routerLink="/" (click)="scroll()" (click)="toggleLogo()" class="header-wrapper--logo"><img [src]="headers.logo[0].file.publicUrl | safeHtml: 'url'" [alt]="headers.logo[0].name" /></a>
        <div class="header-wrapper--nav">
            <div class="left-links">
                <a routerLink="{{ header.link | safeHtml: 'url' }}" (click)="scroll()" routerLinkActive="active-link" *ngFor="let header of headers.menu" class="nav--links">{{ header.name }}</a
        >
      </div>
      <div class="right-links">
        <div class="nav--links--lang not-translate">
          <p>
            <span
              [ngClass]="selectedLang === languages.EN ? 'active-lang' : ''"
              (click)="switchLang(languages.EN); scroll()"
              ><strong class="language-sign">EN</strong></span
            >
            <span>&nbsp;/&nbsp;</span>
            <span
              [ngClass]="selectedLang === languages.FR ? 'active-lang' : ''"
              (click)="switchLang(languages.FR); scroll()"
              ><strong class="language-sign">FR</strong></span
            >
          </p>
        </div>
        <div *ngIf="currentUser" class="nav--links--lang" (click)="logout()">
          LOGOUT
        </div>
      </div>
    </div>
    <button
      *ngIf="!currentUser"
      class="header-wrapper--button"
      routerLink="/account/login"
      (click)="scroll()"
    >
      LOGIN
    </button>
    <button
      *ngIf="currentUser"
      routerLink="/account/{{userId}}/dashboard"
      class="header-wrapper--button__account"
      (click)="scroll()"
    >
      MY ACCOUNT
    </button>
    <div [style.display]="status ? 'block' : 'none'" class="tab-menu-box">
      <div class="link-list">
        <ul>
          <li
            class="links"
            routerLink="{{ header.link | safeHtml: 'url' }}"
            (click)="scroll()"
            (click)="toggleNavItem()"
            routerLinkActive="active-link"
            *ngFor="let header of headers.menu"
          >
            <a>{{ header.name }}</a>
                </li>
                </ul>
                <div class="language not-translate">
                    <p>
                        <span [ngClass]="selectedLang === languages.EN ? 'active-lang' : ''" (click)="switchLang(languages.EN)" (click)="scroll()" (click)="toggleNavItem()"><strong class="language-sign">EN</strong></span
            >
            <span>&nbsp;/&nbsp;</span>
                        <span [ngClass]="selectedLang === languages.FR ? 'active-lang' : ''" (click)="switchLang(languages.FR)" (click)="scroll()" (click)="toggleNavItem()"><strong class="language-sign">FR</strong></span
            >
          </p>
        </div>
        <div class="nav-button">
          <button
            (click)="toggleNavItem()"
            *ngIf="!currentUser"
            routerLink="/account/login"
          >
            LOGIN
          </button>
          <button (click)="toggleNavItem()" *ngIf="currentUser" routerLink="/account/{{userId}}/dashboard">
            MY ACCOUNT
          </button>
        </div>
        <div
          (click)="toggleNavItem()"
          (click)="logout()"
          *ngIf="currentUser"
          class="logout"
        >
          LOGOUT
        </div>
      </div>
    </div>
  </div>
</ng-container>