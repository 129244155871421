import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { environment } from '../environments/environment';
import { PreloadModulesStrategy } from './core/strategies/preload-module.strategy';
import { PagesModule } from './core/pages/pages.module';
import { PagesComponent } from './core/pages/containers/pages/pages.component';

const appRoutes: Route[] = [
  { path: '', component: PagesComponent, data: { preload: true } },
];

/**
 * @description To enable/disable tracing of route disable the ${switchForRouteTrace}.
 */
const switchForRouteTrace = false;

@NgModule({
  imports: [
    PagesModule,
    RouterModule.forRoot(appRoutes, {
    preloadingStrategy: PreloadModulesStrategy,
    enableTracing: switchForRouteTrace && !environment.production
}),
  ],
  exports: [RouterModule],
  providers: [PreloadModulesStrategy],
})
export class AppRoutingModule {}
