<div class="container">
  <div class="counterWrapper">
    <button
      class="btn btn-bordered-black"
      (click)="decrement()"
      [class]="count === 0 ? 'btn-disable' : ''"
    >
      &#8722;
    </button>
    <span>{{ count }}</span>
    <button
      class="btn btn-bordered-black"
      (click)="increment()"
      [class]="count === getTicketsRemaining() ? 'btn-disable' : ''"
    >
      &#43;
    </button>
  </div>
  <button
    class="btn btn-theme btn-medium"
    (click)="sendCounterValue()"
    [class]="loading || data.bookedTickets === count ? 'disableBtn' : ''"
    [disabled]="loading || data.bookedTickets === count"
  >
    OK
  </button>
</div>
