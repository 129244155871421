// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IEnvironmentConfig } from "@myclubrewards/shared";
import { environment as defaultEnvironment } from "./environment.default";

export const environment: IEnvironmentConfig = {
  ...defaultEnvironment,
  production: true,
  name: "production",
  // base_url: "https://google.com",
  // production: false,
  // name: 'default',
  // base_url: 'https://google.com',
  // WEGLOT_API_KEY: '',

  // production: false,
  // GRAPHQL_SERVER: "https://dev.gatewaycasinos.ca/admin/api",
  // WEGLOT_API_KEY: "wg_bb35b298dc460011624ea6011fbf7cc54",
  // CLIENT_ID: "7da98bc5ff5e4759a6799d2dc2c5c8b1",
  // CLIENT_SECRET: "e6302b3C5f5347D48C2f221B9e67c73f",
  // BASE_URL: "https://labapi.gatewaycasinos.ca",
  // PASSWORD_RESET_TIMEOUT_SEC: "60",
  // GOOGLE_SITE_KEY: "6Le_QCEaAAAAADFVi0j2wXg6w6pOrHwakmxGwk_y",
  // CHECK_USER_INACTIVITY_MINS: 5,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
