<div class="wrapper">
    <div class="header">
        <div class="icon">
            <img src="assets/down-arrow.png" alt="down-arrow" />
            <img mat-dialog-close src="assets/close.png" class="close" alt="close" />
        </div>
        <p class="heading" [innerHTML]="data.title | safeHtml: 'html'"></p>
    </div>

    <hr class="seperator" />

    <div class="text-container">
        <p class="heading" [innerHTML]="data.bodyHeading | safeHtml: 'html'">
        </p>
    </div>
    <form [formGroup]="passwordForm" (keydown)="submitEnter($event, passwordForm)">
        <div class="feild">
            <label class="input-label" for="password">Enter Password</label>
            <div class="warning-wrapper">
                <div class="pass">
                    <input autocomplete="off" formControlName="password" [type]="fieldTextType ? 'text' : 'password'" class="input-feild" name="password" (keyup)="checkPasswords()" />
                    <span class="show" (click)="toggleFieldTextType()">{{
            !!fieldTextType ? 'Hide' : 'Show'
          }}</span>
                </div>
                <p class="warning">
                    <span [ngClass]="{
              error:
                form.password?.errors?.minlength ||
                form.password?.errors?.maxlength
            }">8-15 Characters,</span
          >
          <span
            [ngClass]="{
              error: form.password?.errors?.symbol
            }"
            >1 Symbol,</span
          ><br />
          <span
            [ngClass]="{
              error: form.password?.errors?.letter
            }"
            >Uppercase
          </span> and
                    <span [ngClass]="{
              error: form.password?.errors?.number
            }">
            1 Number</span
          >
        </p>
      </div>
    </div>

    <div class="feild">
      <label class="input-label" for="confirmPassword">Confirm Password</label>
      <div class="warning-wrapper">
        <div class="pass">
          <input
            type="password"
            (keyup)="checkPasswords()"
            [type]="fieldTextTypecon ? 'text' : 'password'"
            formControlName="confirmPassword"
            class="input-feild"
            name="confirmPassword"
            autocomplete="off"
          />
          <span class="show" (click)="toggleFieldTextTypecon()">{{
            !!fieldTextTypecon ? 'Hide' : 'Show'
          }}</span>
            </div>
            <p class="warning error-match" *ngIf="same">Does Not Match</p>
        </div>
</div>
</form>

<div class="progress-bar">
    <mat-progress-bar class="progess" mode="determinate" [value]="progressbarValue"></mat-progress-bar>
    <div>
        You have
        <span class="timer">
            <countdown #cd [config]="{ leftTime: timer-1, format: 'ss'}" (event)="timerCompleted($event)"></countdown>
        </span> seconds <span [innerHTML]="data.message | safeHtml: 'html'"></span></div>
</div>

<hr class="seperator added-margin" />

<div class="wrapper--footer">
    <div class="button-loader">
        <button type="submit" (click)="congrats()" [ngClass]="{
          'is-disabled': passwordForm.invalid || displayLoader
        }" [disabled]="passwordForm.invalid || displayLoader">
        SUBMIT
      </button>
        <div class="loader" *ngIf="displayLoader">
            <myclubshared-loader-component [loaderConfig]="setLoaderConfig"></myclubshared-loader-component>
        </div>
    </div>
    <a *ngIf="data.showGoBack" class="go-back" (click)="goBack('activate-account')">Go Back</a
    >
  </div>
</div>
