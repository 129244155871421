<div class="wrapper--header" id="content-to-print">
  <div class="title">
    <p
      *ngIf="config.heading"
      [innerHTML]="config.heading | safeHtml: 'html'"
      class="heading"
    ></p>
    <p
      *ngIf="config.title"
      class="subheading"
      [innerHTML]="config.title | safeHtml: 'html'"
    ></p>
    <img
      *ngIf="routePath !== 'promotions'"
      class="arrow"
      src="assets/right-arrow.png"
      alt="right-arrow"
    />
  </div>
  <div class="updated" *ngIf="config.updatedDate">
    <p>Updated:</p>
    <p class="not-translate">
      {{ config.updatedDate | date: config.dateFormat }}
    </p>
  </div>
</div>
