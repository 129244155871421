import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  ApiService,
  DateService,
  ENVIRONMENT_CONFIG,
  IEnvironmentConfig,
} from '@myclubrewards/shared';
import { UserProfile } from '../models/user-profile-modal';
import { ProfileService } from './profile-service.service';

@Injectable({
  providedIn: 'root',
})
export class TriggerEmailService extends ApiService {
  profile: UserProfile;
  constructor(
    protected http: HttpClient,
    @Inject(ENVIRONMENT_CONFIG) protected environment: IEnvironmentConfig,
    private dateService: DateService,
    private profileService: ProfileService
  ) {
    super(http, environment);
    this.getUserProfile();
  }

  getUserProfile() {
    this.profileService.accountProfile$.subscribe(
      (response) => (this.profile = response)
    );
  }

  sendEmail(id, event): void {
    this.triggerEmail(id, {
      createdAt: this.dateService.getCurrentDateWithFormat('MM-DD-YYYY'),
      emailType: this.getEmailType(event),
      netChangesCount:
        event.value > event.data.bookedTickets
          ? event.value - event.data.bookedTickets
          : event.data.bookedTickets - (event.data.bookedTickets - event.value),
      previousBookingCount: event.data.bookedTickets,
      destinationMail: this.profile?.email.emailAddress,
      eventName: event.data.name,
    }).subscribe();
  }

  getEmailType(event): string {
    if (event.value === 0) return 'cancelTicket';
    else if (event.data.bookedTickets === 0) return 'newTicket';
    else return 'updateTicket';
  }

  triggerEmail(id: string, data) {
    return this.post(`/exp/api/v1/players/${id}/email-trigger`, data);
  }
}
