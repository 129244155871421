import { Inject, Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthorizationService } from '../services/authorization.service';
import {
  LocalStorageService,
  appConstants,
  ENVIRONMENT_CONFIG,
  IEnvironmentConfig,
} from '@myclubrewards/shared';

/**
 * Interceptor to retrieve token from server and set into localStorage
 */
@Injectable()
export class TokenRetievalInterceptor implements HttpInterceptor {
  constructor(
    private _authService: AuthorizationService,
    private localStorageService: LocalStorageService,
    @Inject(ENVIRONMENT_CONFIG) private environment: IEnvironmentConfig
  ) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    req = req.clone({
      setHeaders: {
        'content-type': 'application/json',
        [appConstants.auth.clientId]: this.environment.CLIENT_ID,
        [appConstants.auth.clientSecret]: this.environment.CLIENT_SECRET,
      },
    });

    if (req.url.includes(`${this.environment.BASE_URL}/token`)) {
      console.log('running token reciever interceptor inside login req only');

      return next.handle(req).pipe(
        map((event: HttpEvent<any>) => {
          /* if (event instanceof HttpResponse) {
            this.localStorageService.setItem(
              appConstants.auth.accessToken,
              event.body.appConstants.auth.accessToken
            );

            this.localStorageService.setItem(
              appConstants.auth.refreshToken,
              event.body.appConstants.auth.refreshToken
            );
          } */
          return event;
        })
      );
    } else {
      console.log('running token reciver intereceptor for other requests');
      return next.handle(req);
    }
  }
}
