import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { Footerqueries } from './queries';
import { Footer } from '../models/footer';

@Injectable({
  providedIn: 'root',
})
export class FooterService {
  constructor(private apollo: Apollo) {}

  queryFooters(): Observable<Footer> {
    return this.apollo
      .query({
        query: gql`
          ${Footerqueries.allFooters}
        `,
      })
      .pipe(
        catchError((err) => {
          return err;
        }),
        map((res: any) => {
          return new Footer().deserialize(res.data.allFooters[0]);
        })
      );
  }
}
