import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'myclubrewards-layout-one',
  templateUrl: './layout-one.component.html',
  styleUrls: ['./layout-one.component.scss']
})
export class LayoutOneComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
