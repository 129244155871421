import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LocalStorageService, ModalService } from '@myclubrewards/shared';
import { fromEvent, Subscription } from 'rxjs';
import { AuthorizationService } from '../../services/authorization.service';
import { ActivateAccountComponent } from '../activate-account/activate-account.component';
import { CookieStorageService } from './../../../../../shared/services/cookie-storage.service';
import { LoginDataDTO } from './../../models/auth';

@Component({
  selector: 'myclubrewards-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  private subscription: Subscription;
  loginForm: FormGroup;
  user: any;
  fieldTextType: boolean;
  invalidCredential: boolean = false;
  setLoaderConfig: any = {
    diameter: 30,
  };
  displayLoader: boolean = false;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private authService: AuthorizationService,
    private modalService: ModalService,
    private localStorageService: LocalStorageService,
    private cookieStorageService: CookieStorageService,
    @Inject(DOCUMENT) private _document: Document
  ) {
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    this.initFormGroup();
    this.detectLanguageChangeText();
  }

  private initFormGroup() {
    this.loginForm = this.fb.group({
      accountId: this.fb.control('', [Validators.required]),
      password: this.fb.control('', [Validators.required]),
    });
  }

  get form() {
    return this.loginForm.controls;
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  resetInvalid() {
    this.invalidCredential = false;
  }

  openDialog(key: string) {
    let dialogRef;

    switch (key?.toLowerCase()) {
      case 'forgot-password':
        dialogRef = this.modalService.openDialog(
          {
            title: '<p>FORGOT PASSWORD</p>',
            context: key?.toLowerCase(),
          },
          { disableClose: false },
          ActivateAccountComponent
        );
        return;
      case 'activate-account':
        dialogRef = this.modalService.openDialog(
          {
            title: '<p>ACTIVATE YOUR ACCOUNT</p>',
            context: key?.toLowerCase(),
          },
          { disableClose: false },
          ActivateAccountComponent
        );
        return;
    }
  }

  detectLanguageChangeText(): void {
    this.subscription.add(
      fromEvent(this._document, 'click').subscribe((x) => {
        if (x.target['className'] === 'translate-here') {
          this.openDialog('activate-account');
        } 
        // else if (x.target['className'] === 'tc-text') {
        //   // this.router.navigate(['/terms-condition']);
        //   this._document.defaultView.open(
        //     `${this._document.defaultView.location.origin}/terms-condition`,
        //     '_blank'
        //   );
        // }
      })
    );
  }

  onSubmit() {
    if (this.loginForm.invalid) {
      return;
    }
    this.displayLoader = true;
    const loginData: LoginDataDTO = {
      accountId: this.loginForm.get('accountId').value.trim(),
      password: this.loginForm.get('password').value.trim(),
    };
    this.subscription.add(
      this.authService.loginUser(loginData).subscribe(
        (data) => {
          this.displayLoader = false;
          this.user = data.body;
          if (this.cookieStorageService.getUser() !== null) {
            this.authService.updateCurrentUser(
              this.cookieStorageService.getUser()
            );
          }
          this.cookieStorageService.getUserIdFromCookies();
          this.router.navigate([
            'account',
            this.cookieStorageService.getUserIdFromCookies(),
            'dashboard',
          ]);
        },
        (err) => {
          this.displayLoader = false;
          if (err.status === 400 || err.status === 401) {
            this.invalidCredential = true;
          } /* else {
            this.errorService.openDialog<ModalComponent>(err, {}, undefined);
          } */
        }
      )
    );
  }

  scroll() {
    window.scrollTo(0, 0);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
