import { AuthorizationService } from '../../services/authorization.service';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalService, CaptchaComponent } from '@myclubrewards/shared';
import { UpdatePasswordComponent } from '../update-password/update-password.component';
import { Subscription, timer } from 'rxjs';
@Component({
  selector: 'myclubrewards-activate-account',
  templateUrl: './activate-account.component.html',
  styleUrls: [
    '../../../../../shared/components/modal/modal.component.scss',
    './activate-account.component.scss',
  ],
})
export class ActivateAccountComponent
  implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('googleCaptcha') googleCaptchaRef: CaptchaComponent;
  @ViewChild('googleCaptcha') googleCaptchaRef2: ElementRef;
  private subscription: Subscription;
  captchaVerified: string;
  activateForm: FormGroup;
  pinError?: string;
  invalidCredential: boolean = false;
  setLoaderConfig: any = {
    diameter: 30,
  };
  displayLoader: boolean = false;
  showRecaptcha: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<ActivateAccountComponent>,
    private modalService: ModalService,
    private fb: FormBuilder,
    private authService: AuthorizationService
  ) {
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    this.initFormGroup();
  }
  ngAfterViewInit(): void {
    this.subscription.add(
      timer(200).subscribe(() => {
        this.showRecaptcha = true;
      })
    );
  }

  private initFormGroup() {
    this.activateForm = this.fb.group({
      accountId: this.fb.control('', [Validators.required]),
      pinNumber: this.fb.control('', [Validators.required]),
    });
  }

  submitEnter(e, form) {
    if (e.keyCode === 13 && !form.invalid) {
      this.changePassword();
    }
  }

  close() {
    this.dialogRef.close(true);
  }

  resetInvalid() {
    this.invalidCredential = false;
  }

  get form() {
    return this.activateForm.controls;
  }
  getCaptchaResponse(data) {
    if (!!data) {
      this.captchaVerified = data;
      this.changePassword();
    }
  }

  submitChangePasswordRequest() {
    if (this.googleCaptchaRef) {
      this.googleCaptchaRef.captcha.execute();
    } else {
      this.changePassword();
    }
  }

  changePassword() {
    if (
      this.activateForm.invalid ||
      (this.googleCaptchaRef && !this.captchaVerified)
    ) {
      return;
    }
    this.displayLoader = true;
    let modalData;

    if (this.data.context === 'forgot-password') {
      modalData = {
        title: '<p>RESET PASSWORD</p>',
        bodyHeading: 'Please create a new password',
        showGoBack: false,
        context: this.data.context,
        message: 'to reset your password.',
      };
    } else if (this.data.context === 'activate-account') {
      modalData = {
        title: '<p>ACTIVATE YOUR ACCOUNT</p>',
        bodyHeading: 'Please create a password',
        showGoBack: true,
        context: this.data.context,
        message: 'to create your password.',
      };
    }

    const values = this.activateForm.getRawValue();

    this.subscription.add(
      this.authService
        .forgotPassword(values.pinNumber.trim(), values.accountId.trim())
        .subscribe(
          (data: any) => {
            this.displayLoader = false;
            this.close();
            this.modalService.openDialog<UpdatePasswordComponent>(
              {
                ...modalData,
                token: data.token,
                accountId: values.accountId.trim(),
                pinNumber: values.pinNumber.trim(),
              },
              undefined,
              UpdatePasswordComponent
            );
          },
          (err) => {
            this.displayLoader = false;
            if (err.status === 400 || err.status === 401) {
              this.invalidCredential = true;
              this.captchaVerified = null;
              this.googleCaptchaRef && this.googleCaptchaRef.captcha.reset();
            } else {
              this.captchaVerified = null;
              this.googleCaptchaRef && this.googleCaptchaRef.captcha.reset();
            }
          }
        )
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
