<div
  class="wrapper"
  *ngIf="
    userSpecificDashboardDetails$ | async as dashboardDetails;
    else displayLoader
  "
>
  <div class="wrapper--header">
    <img class="arrow" src="assets/right-arrow.png" alt="right-arrow" />
    <div class="heading">DASHBOARD</div>
  </div>
  <div class="wrapper-feilds" *ngIf="!!dashboardDetails; else noData">
    <div class="combine-feild">
      <div class="feild">
        <p class="label">Tier Points</p>
        <p class="value">
          {{ dashboardDetails?.tierPoints | number: '1.0':'en-US' }}
        </p>
      </div>
      <div class="feild">
        <p class="label">Reward Points</p>
        <p class="value">
          {{ dashboardDetails?.dashboardRewardPoints | number: '1.0':'en-US' }}
        </p>
      </div>
    </div>
    <div class="combine-feild added-padding">
      <div class="feild">
        <!-- <p class="label">TICKETS EARNED</p> -->
        <p class="label">COMP $</p>
        <p class="label">PROMO $</p>
        <p class="label">RESORT REWARDS $</p>
      </div>
      <div class="feild">
        <!-- <div class="amount">{{ dashboardDetails?.earnedTickets }}</div> -->
        <div class="amount">
          {{ dashboardDetails?.dashboardRewardComp }}
        </div>
        <div class="amount">
          {{ dashboardDetails?.dashboardRewardPromo }}
        </div>
        <div class="amount">
          {{ dashboardDetails?.dashboardRewardPromo2 }}
        </div>
      </div>
    </div>
  </div>
  <ng-template #noData>
    <div class="no-data">
      Coming Soon...
    </div>
  </ng-template>
  <hr class="seperator" />
</div>
<!-- <ng-template #noData>
  <div class="no-data">
    Coming Soon...
  </div>
</ng-template> -->
<ng-template #displayLoader>
  <myclubshared-loader-component></myclubshared-loader-component>
</ng-template>
<!-- <div *ngIf="displayLoader">
  <myclubshared-loader-component
    [loaderConfig]="setLoaderConfig"
  ></myclubshared-loader-component>
</div> -->
