import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { PagesComponent } from './containers/pages/pages.component';
import { HomeComponent } from '@myclubrewards/home';
import { LoginComponent } from '@myclubrewards/account';

const appRoutes: Route[] = [
  {
    path: '',
    component: PagesComponent,
    children: [
      {
        path: '',
        component: HomeComponent,
      },
      {
        path: 'rewards',
        loadChildren: () =>
          import('@myclubrewards/rewards').then((m) => m.RewardsModule),
        data: { preload: true },
      },
      {
        path: 'faq',
        loadChildren: () =>
          import('@myclubrewards/faq').then((m) => m.FaqModule),
        data: { preload: true },
      },
      {
        path: 'about',
        loadChildren: () =>
          import('@myclubrewards/about').then((m) => m.AboutModule),
        data: { preload: true },
      },
      {
        path: 'terms-condition',
        loadChildren: () =>
          import('@myclubrewards/terms-condition').then(
            (m) => m.TermsConditionModule
          ),
        data: { preload: true },
      },
      {
        path: 'privacy-policy',
        loadChildren: () =>
          import('@myclubrewards/privacy-policy').then(
            (m) => m.PrivacyPolicyModule
          ),
        data: { preload: true },
      },
      {
        path: 'promotions',
        loadChildren: () =>
          import('@myclubrewards/promotions').then(
            (m) => m.PromotionsModule
          ),
        data: { preload: true },
      },
      {
        path: 'earn-redeem',
        loadChildren: () =>
          import('@myclubrewards/earn-redeem').then((m) => m.EarnRedeemModule),
        data: { preload: true },
      },
      {
        path: 'gift-cards',
        loadChildren: () =>
          import('@myclubrewards/gift-card').then((m) => m.GiftCardModule),
        data: { preload: true },
      },
      {
        path: 'account/login',
        component: LoginComponent,
      },
      {
        path: 'account/:accountId',
        loadChildren: () =>
          import('@myclubrewards/account').then((m) => m.AccountModule),
        data: { preload: true },
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(appRoutes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
