import { LocalStorageService } from "@myclubrewards/shared";
import { Component, OnInit } from "@angular/core";
import { AccountsService } from "../../services/accounts-service.service";
import { MembershipDetails } from "../../models/membershipDetails";
import { Observable, Subscription } from "rxjs";
import { DashboardDetails } from "../../models/dashboard-modal";

@Component({
  selector: "myclubrewards-mcr-membership-detail-component",
  templateUrl: "./membership-detail-component.component.html",
  styleUrls: ["./membership-detail-component.component.scss"],
})
export class McrMembershipDetailComponentComponent implements OnInit {
  memberName: string;
  patron_name: string;
  membership$: Observable<MembershipDetails>;
  dashboard$: Observable<DashboardDetails>;

  constructor(
    private accountsService: AccountsService,
    private localStorageService: LocalStorageService
  ) {
    this.memberName = this.localStorageService.getUserCardType();
    this.membership$ = this.accountsService.membershipDetails$;
    this.dashboard$ = this.accountsService.dashboardDetails$;
    this.patron_name = this.localStorageService.getUser();
  }

  ngOnInit() {
    this.getMembershipDetails();
  }
  getMembershipDetails() {
    this.accountsService.getUserMemmbershipDetails();
    this.accountsService.getDashboardDetails();
  }
}
