<div class="wrapper">
  <div class="header">
    <div class="icon">
      <img src="assets/down-arrow.png" alt="down-arrow" />
      <img mat-dialog-close src="assets/close.png" class="close" alt="close" />
    </div>
    <p class="heading">WE NEED YOUR CONSENT.</p>
  </div>

  <hr class="seperator" />
  <div class="disclaimer">
    <p>We would like your consent.
</p>
    <p class="text">
      Under Canada's anti-spam legislation ("CASL"), Gateway Casinos &
      Entertainment Limited ("Gateway") and Strategy9 Inc., our service provider
      that sends our emails, are seeking your consent to receive information
      about My Club Rewards by electronic means, such as email messages.
      Information may include news, offers, discounts, rewards and more.
    </p>
    <p><input type="checkbox" name="termsAndConditions" id="termsAndConditions" [(ngModel)]="acceptNorms" class="cursor" />
    <label for="termsAndConditions" class="cursor padding">
    Please send me electronic communications about My Club Rewards, including news, offers, discounts, rewards and more.</label></p>

    <p class="text">
      If you have questions about our CASL compliance, please contact
      <span class="info_text" (click)="mailTo()">info@gatewaycasinos.com</span>
      or Gateway Casinos & Entertainment 100-4400 Dominion Street, Burnaby, BC
      V5G 4G3. If you do not consent, or opt-out later, we may still send you important administrative and transactional communications
      related to your My Club Rewards account in accordance with CASL.If you wish to withdraw your consent, you may do so at any time
      by using the unsubscribe link at the bottom of any My Club Rewards email,
      or by contacting us using the contact information above with “unsubscribe”
      in the subject line (if via email) or at the top of the letter (if via
      mail).
    </p>
  </div>
  <hr class="seperator" />
  <div class="align_center">
    <button class="login-button" type="submit" [disabled]="!acceptNorms" (click)="dialogRef.close(true)" [ngClass]="{
              'is-disabled': !acceptNorms}">
      CONFIRM
    </button>
  </div>

  <hr class="seperator added-margin" />
</div>
