export const Headerqueries = {
  allHeaders: `
  query {
  allHeaders(where: { isActive: true }) {
    id
    name
    isActive
    logo {
      isActive
      name
      file {
        publicUrl
      }
    }
    menu(where: { isActive: true }, orderBy: "priority") {
      id
      name
      link
      isActive
      priority
    }
  }
}
`,
};

export const Footerqueries = {
  allFooters: `
  query {
  allFooters(where: { isActive: true }) {
    isActive
    logo {
      isActive
      name
      file {
        publicUrl
      }
    }
    phoneNumber
    description
    copyrightText
    warningText
    aboutHeading
    aboutSubHeading
    termsAndConditionsHeading
    privacyPolicyHeading
    queryHeading
    ninteenPlusImage {
      isActive
      name
      file {
        publicUrl
      }
    }
    playSmart {
      isActive
      url
      imageEn {
        isActive
        name
        file {
          publicUrl
        }
      }
      imageFr {
        isActive
        name
        file {
          publicUrl
        }
      }
    }
    gateWay {
      isActive
      url
      imageEn {
        isActive
        name
        file {
          publicUrl
        }
      }
      imageFr {
        isActive
        name
        file {
          publicUrl
        }
      }
    }
  }
}
`,
};
