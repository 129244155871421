import { get, keyBy } from 'lodash';

interface IDeserializable {
  deserialize(input: any): this;
}

export class DashboardDetails implements IDeserializable {
  dashboardRewardPoints: number;
  tierPoints: number;
  earnedTickets: number;
  dashboardRewardComp: number;
  dashboardRewardPromo: number;
  dashboardRewardPromo2: number;
  currentLevelName: string;

  deserialize(input: any): this {
    const temp = this.getDashboardDetails(input);
    Object.assign(this, temp);
    return this;
  }

  private getDashboardDetails(input: any): any {
    const temp = {
      dashboardRewardPoints: 0,
      tierPoints: 0,
      earnedTickets: 0,
      dashboardRewardComp: 0,
      dashboardRewardPromo: 0,
      dashboardRewardPromo2: 0,
      currentLevel: '',
    };
    const accountTypeObj = keyBy(
      get(input, 'dashboardDetails.playerBalance', []),
      'accountType'
    );
    temp.tierPoints = get(input, 'dashboardDetails.CurrentLevelPoints', 0);
    temp.dashboardRewardPoints = get(
      accountTypeObj,
      ['Point', 'accountBalance'],
      0
    );
    temp.currentLevel = get(input, 'dashboardDetails.CurrentClubLevel');
    temp.earnedTickets = get(accountTypeObj, ['Ballots', 'accountBalance'], 0);
    temp.dashboardRewardComp = Math.trunc(
      get(accountTypeObj, ['Comp', 'accountBalance'], 0)
    );
    temp.dashboardRewardPromo = Math.trunc(
      get(accountTypeObj, ['Promo1', 'accountBalance'], 0)
    );
    temp.dashboardRewardPromo2 = Math.trunc(
      get(accountTypeObj, ['Promo2', 'accountBalance'], 0)
    );

    return temp;
  }
}
