import { get } from 'lodash';
import { appConstants } from '@myclubrewards/shared';

interface IDeserializable {
  deserialize(input: any): this;
}

export class User implements IDeserializable {
  id?: string;
  name?: string;
  email?: string;
  userName?: string;
  cardType?: string;
  accountToken?: string;
  refreshToken?: string;

  deserialize(input: any): this {
    // body
    this.accountToken = get(input, 'body.accessToken');
    this.id = get(input, 'body.accountId');
    this.name = get(input, 'body.patronName');
    this.email = get(input, 'body.email');
    this.userName = get(input, 'body.userName');
    this.cardType = get(input, 'body.cardType');
    this.refreshToken = get(input, 'body.refreshToken');
    return this;
  }
}

export interface LoginDataDTO {
  email?: string;
  accountId: string;
  password: string;
}
