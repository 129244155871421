import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';
import { get } from 'lodash';
import { MatSliderChange } from '@angular/material/slider';

@Component({
  selector: 'myclubshared-horizontal-scroller',
  templateUrl: './horizontal-scroller.component.html',
  styleUrls: ['./horizontal-scroller.component.scss'],
})
export class HorizontalScrollerComponent implements OnInit, OnDestroy {
  @ViewChild('leftScrollBtn') private leftScrollBtn: ElementRef<any>;
  @ViewChild('rightScrollBtn') private rightScrollBtn: ElementRef<any>;
  @ViewChild('wrapper_cards_container')
  private wrapper_cards_container: ElementRef<any>;
  private subscription: Subscription;

  @Input() data: any[];
  @Input() config: any;
  @Input() context: any;
  scrollWidth: number = 0;

  constructor() {
    this.subscription = new Subscription();
  }

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    // initially set leftScrollBtn as disabled since, user haven't scrolled yet
    this.leftScrollBtn.nativeElement.disabled = true;

    this.subscription.add(
      fromEvent(this.leftScrollBtn.nativeElement, 'click').subscribe((e) => {
        this.scrollLeft();
      })
    );

    this.subscription.add(
      fromEvent(this.rightScrollBtn.nativeElement, 'click').subscribe((e) => {
        this.scrollRight();
      })
    );

    this.subscription.add(
      fromEvent(this.wrapper_cards_container.nativeElement, 'scroll').subscribe(
        (e: any) => {
          this.scroller();
        }
      )
    );
  }

  get(item: any, index: number): any {
    return get(item, `${this.config.paths[index]}`);
  }

  scrollLeft(): void {
    this.wrapper_cards_container.nativeElement.scrollLeft -= 5 * 100;
  }

  scrollRight(): void {
    this.wrapper_cards_container.nativeElement.scrollLeft += 5 * 100;
  }

  scroller(sliderChange?: MatSliderChange): void {
    const height =
      this.wrapper_cards_container.nativeElement?.scrollWidth -
      this.wrapper_cards_container.nativeElement?.clientWidth;

    this.scrollWidth = Math.floor(
      (this.wrapper_cards_container.nativeElement?.scrollLeft / height) * 100
    );

    if (sliderChange?.value <= this.scrollWidth) {
      this.scrollLeft();
    }

    if (sliderChange?.value > this.scrollWidth) {
      this.scrollRight();
    }

    if (this.scrollWidth >= 100) {
      this.leftScrollBtn.nativeElement.disabled = false;
      this.rightScrollBtn.nativeElement.disabled = true;
    } else if (this.scrollWidth < 100 && this.scrollWidth !== 0) {
      this.leftScrollBtn.nativeElement.disabled = false;
      this.rightScrollBtn.nativeElement.disabled = false;
    } else {
      this.leftScrollBtn.nativeElement.disabled = true;
    }
  }

  getMinimumValue(arr) {
    return Math.min(...arr);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
