import { get, groupBy, map } from 'lodash';
import * as moment from 'moment';
import { PatronEvent } from './patron-event';

interface IDeserializable {
  deserialize(input: any): this;
}

export enum OFFER_MAPPING {
  'free-play' = 'P',
  'table-play' = 'I',
  'food' = 'F',
  'gifts' = 'M',
  // random codes
  'concerts' = 'C',
  'special-events' = 'SE',
  'hotel' = 'H',
}

/* export enum OFFER_MAPPING {
  'free-play' = 'p',
  'table-play' = 'i',
  'food' = 'f',
  'gifts' = 'm',
  // random codes
  'concerts' = 'c',
  'special-events' = 'se',
  'hotel' = 'h',
} */

export class OffersAndEvents implements IDeserializable {
  // hashmap of exclusive-offers
  exclusiveOffers: any;
  events: PatronEvent[];

  deserialize(input: any): this {
    this.buildExclusiveOffers(get(input, 'offers', []));
    this.buildEvents(get(input, 'events', []));

    return this;
  }

  private buildExclusiveOffers(input: any[]) {
    if (!input.length) return undefined;
    const groupOffers = groupBy(input, 'offerType');
    Object.keys(groupOffers).forEach((element) => {
      groupOffers[element] = map(groupOffers[element], (item) =>
        new OfferDetails().deserialize(item)
      );
    });
    this.exclusiveOffers = groupOffers;
  }

  private buildEvents(input: any[]): void {
    if (!input.length) return;

    this.events = input.map((x: any) => {
      return new PatronEvent().deserialize(x);
    });
  }
}

// this is a mapping to map the location codes with their labelled names for GMS Offer

const locationCodes = {
  RAM: 'Casino Rama Resort',
  CHA: 'Chatham',
  CLN: 'Clinton',
  HAN: 'Hanover',
  INN: 'Innisfil',
  WES: 'London',
  PEC: 'Point Edward',
  SAR: 'Sarnia',
  SOO: 'Sault Ste. Marie',
  SUD: 'Sudbury',
  TBC: 'Thunder Bay',
  WOD: 'Woodstock',
};

export class OfferDetails implements IDeserializable {
  description: string; // raj
  errors: string;
  expiresAt: string; // expiry
  offerType: string;
  prizeCode: string;
  prizeID: number;
  ref1: string;
  ref2: string;
  resnetId: number;
  status: string;
  tranID: number;
  units: number;
  validFrom: string;
  location: string;
  validFromTs: number;
  expireFromTs: number;
  image: any;
  SiteCode: any;

  deserialize(input: any): this {
    this.description = get(input, 'description', '-');
    this.units = get(input, 'units', 0);
    this.validFromTs = Math.floor(
      moment(get(input, 'validFrom')).valueOf() / 1000
    );
    this.expireFromTs = Math.floor(
      moment(get(input, 'expiresAt')).valueOf() / 1000
    );
    this.location = get(input, 'SiteCode', []).split(', ');
    this.validFrom = moment(get(input, 'validFrom')).format('Do MMMM YYYY');
    this.expiresAt = moment(get(input, 'expiresAt')).format('Do MMMM YYYY');
    this.image = get(input, 'imageSrc');

    var temp = [];
    for (let i = 0; i < this.location.length; i++) {
      if (this.location[i] in locationCodes) {
        temp[i] = locationCodes[this.location[i]];
      }
    }
    this.SiteCode = temp;

    return this;
  }
}
