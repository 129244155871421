import { Injectable } from '@angular/core';

import { LANGUAGES } from '../constants/languages';

/**
 * Language Switcher service used to switch languages.
 * Using Weglot to make tranaslations between languages.
 */
@Injectable({
  providedIn: 'root',
})
export class LangSwitcherService {
  constructor() {}

  /**
   * Uses Weglot's switchTo method to switch between languages.
   */
  switchTo(lang: LANGUAGES) {
    window['Weglot'].switchTo(lang);
  }
}
