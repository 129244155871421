<div class="wrapper">
  <div class="float-box">
    <div class="back"></div>
    <div class="details-wrapper">
      <div class="details" *ngFor="let item of configArray">
        <p class="heading">{{ item.heading }}</p>
        <p class="subheading">
          <span
            class="data"
            [innerHTML]="item.description | safeHtml: 'html'"
          ></span>
        </p>
      </div>
    </div>
    <!-- <div class="image-wrapper">
      <img src="assets/terms-1.png" />
      <img src="assets/terms-2.png" />
      <img src="assets/terms-3.png" />
      <img src="assets/terms-4.png" />
    </div> -->
  </div>
</div>
