import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import {
  appConstants,
  CookieStorageService,
  ENVIRONMENT_CONFIG,
  IEnvironmentConfig,
  LocalStorageService,
} from '@myclubrewards/shared';
import { Observable } from 'rxjs';
import { AuthorizationService } from '../services/authorization.service';

/**
 * Interceptor to add authentication headers on every request
 */
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(
    private _authService: AuthorizationService,
    @Inject(PLATFORM_ID) private platformId,
    private localStorageService: LocalStorageService,
    @Inject(ENVIRONMENT_CONFIG) private environment: IEnvironmentConfig,
    private cookieStorageService: CookieStorageService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      this._authService.isUserLoggedIn() &&
      !req.url.includes('refresh-token')
    ) {
      console.log('authenticated user so adding token');
      const modifiedRequest = req.clone({
        setHeaders: {
          'content-type': 'application/json',
          [appConstants.auth.clientId]: this.environment.CLIENT_ID,
          [appConstants.auth.clientSecret]: this.environment.CLIENT_SECRET,
          [appConstants.auth.accessToken]: this.cookieStorageService.getItem(
            appConstants.auth.accessToken
          ),
        },
      });
      return next.handle(modifiedRequest);
    } else {
      console.log('not authenticated user so no token or a route for refresh');
      return next.handle(req);
    }
  }
}
