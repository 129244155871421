import { CommonModule } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AdminSharedModule } from '@myclubrewards/shared';
import { CountdownModule } from 'ngx-countdown';
import { NgxPrintModule } from 'ngx-print';
import { AccountRoutingModule } from './account-routing.module';
import { McrAccountNavbarComponent } from './components/account-navbar/account-navbar.component';
import { AccountComponent } from './components/account/account.component';
import { ActivateAccountComponent } from './components/activate-account/activate-account.component';
import { CongratulationsComponent } from './components/congratulations/congratulations.component';
import { McrAccountDashboardComponent } from './components/Dashboard/account-dashboard/account-dashboard.component';
import { AccountsPromotionsComponent } from './components/Dashboard/accounts-promotions/accounts-promotions.component';
import { LoginComponent } from './components/login/login.component';
import { McrMembershipDetailComponentComponent } from './components/membership-detail-component/membership-detail-component.component';
import { AccountsExclusiveOfferComponent } from './components/Offers/accounts-exclusive-offer/accounts-exclusive-offer.component';
import { McrPatronOffersComponent } from './components/Offers/patron-offers/patron-offers.component';
import { McrAccountProfileComponent } from './components/Profile/account-profile/account-profile.component';
import { ChangeProfilePasswordComponent } from './components/Profile/change-profile-password/change-profile-password.component';
import { ProfileDisclaimerComponent } from './components/Profile/profile-disclaimer/profile-disclaimer.component';
import { RetryComponent } from './components/retry/retry.component';
import { UpdatePasswordComponent } from './components/update-password/update-password.component';
import { AuthGuard } from './guards/auth.guard';
import { HttpErrorInterceptor } from './interceptors/http-error.interceptor';
import { RefreshTokenInterceptor } from './interceptors/refresh-token.interceptor';
import { TokenRetievalInterceptor } from './interceptors/token-retrieval.interceptor';
import { TokenInterceptor } from './interceptors/token.interceptor';
import { BookEventComponent } from './components/modals/book-event/book-event.component';
import { ModifyEventComponent } from './components/modals/modify-event/modify-event.component';
import { CancelBookingComponent } from './components/modals/cancel-booking/cancel-booking.component';
import { BookingConfirmationComponent } from './components/modals/booking-confirmation/booking-confirmation.component';
import { CancelConfirmationComponent } from './components/modals/cancel-confirmation/cancel-confirmation.component';
import { DefaultComponent } from './components/modals/default/default.component';

@NgModule({
    imports: [
        CommonModule,
        AccountRoutingModule,
        AdminSharedModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        NgxPrintModule,
        FormsModule,
        CountdownModule,
        MatProgressBarModule,
    ],
    declarations: [
        AccountComponent,
        McrMembershipDetailComponentComponent,
        McrAccountNavbarComponent,
        McrAccountDashboardComponent,
        McrPatronOffersComponent,
        McrAccountProfileComponent,
        AccountsPromotionsComponent,
        AccountsExclusiveOfferComponent,
        LoginComponent,
        ActivateAccountComponent,
        CongratulationsComponent,
        ChangeProfilePasswordComponent,
        UpdatePasswordComponent,
        ProfileDisclaimerComponent,
        RetryComponent,
        BookEventComponent,
        ModifyEventComponent,
        CancelBookingComponent,
        BookingConfirmationComponent,
        CancelConfirmationComponent,
    ],
    providers: [
        AuthGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenRetievalInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: RefreshTokenInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HttpErrorInterceptor,
            multi: true,
        },
    ]
})
export class AccountModule {}
