import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  OnInit,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { fromEvent, interval, merge, Observable, Subscription } from 'rxjs';
import { startWith, switchMap } from 'rxjs/operators';
import {
  appConstants,
  ENVIRONMENT_CONFIG,
  IEnvironmentConfig,
  LangSwitcherService,
  LANGUAGES,
  LocalStorageService,
  ModalComponent,
  ModalService,
} from '../../../../../../../../../../libs/shared/';
import { Header } from '../../models/header';
import { HeaderService } from '../../services/header.service';
import { AuthorizationService } from './../../../../../../../../../../libs/account/src';

@Component({
  selector: 'myclubrewards-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  private subscription: Subscription;
  private userActivitySubscription: Subscription;

  headers$: Observable<Header>;

  // isExpanded: boolean = true;
  status: Boolean = false;
  languages = LANGUAGES;
  selectedLang: LANGUAGES;
  currentUser: any;
  userId: string;

  constructor(
    private headerService: HeaderService,
    public dialog: MatDialog,
    private modalService: ModalService,
    private langSwitchService: LangSwitcherService,
    private localStorageService: LocalStorageService,
    private authService: AuthorizationService,
    private renderer: Renderer2,
    @Inject(PLATFORM_ID) private platformId,
    @Inject(ENVIRONMENT_CONFIG) private environment: IEnvironmentConfig,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.subscription = new Subscription();
    this.userActivitySubscription = new Subscription();

    // if (isPlatformBrowser(this.platformId)) {
    this.selectedLang = this.localStorageService.getWeglotLanguage() as LANGUAGES;
    this.subscription.add(
      this.authService.currentUser.subscribe((x) => {
        this.currentUser = x;
        this.userId = this.localStorageService.getUserId();
        if (x) {
          this.userActivitySubscription = this.checkUserInactivity();
        }
      })
    );
    // }
  }

  ngOnInit(): void {
    this.queryHeaders();
  }

  private queryHeaders() {
    this.headers$ = this.headerService.queryHeaders();
  }

  logout() {
    // if (isPlatformBrowser(this.platformId)) {
    const accountToken = this.localStorageService.getItem(
      appConstants.auth.accessToken
    );
    const accountId = +this.localStorageService.getUserId();
    this.authService.logoutUser(accountId, accountToken);
    // }
    this.localStorageService.clear();
    this.userActivitySubscription.unsubscribe();
  }

  openDialog(modalData) {
    this.modalService.openDialog<ModalComponent>({
      title: '<p>COMING SOON</p>',
      bodyHeading: 'My Club Rewards login coming soon!',
    });
  }

  scroll() {
    window.scrollTo(0, 0);
  }

  toggleNavItem() {
    this.status = !this.status;
    this.scroll();
  }

  toggleMenuButton() {
    this.status = !this.status;
  }

  toggleLogo() {
    this.status = false;
  }

  switchLang(lang: LANGUAGES) {
    // if (isPlatformBrowser(this.platformId)) {
    this.langSwitchService.switchTo(lang);
    this.selectedLang = lang;
    // }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.userActivitySubscription.unsubscribe();
  }

  private checkUserInactivity(): Subscription {
    if (this.currentUser) {
      const detectMouseActivity$ = fromEvent(this.document, 'mousemove');
      const detectMouseDownActivity$ = fromEvent(this.document, 'mousedown');
      const keyboardActivity$ = fromEvent(this.document, 'keyup');
      const interval$ = interval(60 * 1000);

      const touchActivity$ = fromEvent(this.document, 'touchstart');
      const touchMoveActivity$ = fromEvent(this.document, 'touchmove');

      return merge(
        detectMouseActivity$,
        detectMouseDownActivity$,
        keyboardActivity$,
        touchActivity$,
        touchMoveActivity$
      )
        .pipe(
          startWith(0),
          switchMap(() => interval$)
        )
        .subscribe((count: number) => {
          if (count >= this.environment.CHECK_USER_INACTIVITY_MINS - 1) {
            this.logout();
          }
        });
    }
  }
}
