import { IDTO } from './idto';

export class BookPatronEventDTO implements IDTO {
  protected accountNumber?: string;
  protected eventSessionGuid?: number;
  protected requestedTickets?: number;

  constructor(
    accountNumber: string,
    eventSessionGuid: number,
    requestedTickets: number
  ) {
    this.accountNumber = accountNumber;
    this.eventSessionGuid = eventSessionGuid;
    this.requestedTickets = requestedTickets;
  }

  toJSON(): Object {
    return Object.assign({}, this);
  }
}
