import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { HomeBanner } from '../../models/home-banner';
import { HomeBannerService } from '../../services/home-banner.service';
import { ModalService, ModalComponent } from '@myclubrewards/shared';

@Component({
  selector: 'myclubrewards-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit {
  homeBanner$: Observable<HomeBanner>;

  constructor(
    public dialog: MatDialog,
    private homeBannerService: HomeBannerService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.homeBanner$ = this.homeBannerService.homeBanner$;
  }

  openDialog(modalData) {
    this.modalService.openDialog<ModalComponent>(modalData);
  }
}
