import { Component, Inject, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  appConstants,
  ENVIRONMENT_CONFIG,
  IEnvironmentConfig,
  ModalService,
  regexValidator,
} from "@myclubrewards/shared";
import { CountdownComponent } from "ngx-countdown";
import { interval, Subscription } from "rxjs";
import { CongratulationsComponent } from "../../components/congratulations/congratulations.component";
import { AuthorizationService } from "../../services/authorization.service";
import { RetryComponent } from "../retry/retry.component";

@Component({
  selector: "myclubrewards-update-password",
  templateUrl: "./update-password.component.html",
  styleUrls: [
    "../../../../../shared/components/modal/modal.component.scss",
    "./update-password.component.scss",
  ],
})
export class UpdatePasswordComponent implements OnInit, OnDestroy {
  @ViewChild("cd", { static: false }) private countdown: CountdownComponent;
  private subscription: Subscription;
  private timer$: Subscription;
  timer: any;
  passwordForm: FormGroup;
  passwordError?: string;
  fieldTextType: Boolean;
  fieldTextTypecon: Boolean;
  progressbarValue = 0;
  curSec: number = 0;
  stopTime: boolean;
  same: Boolean = false;
  setLoaderConfig: any = {
    diameter: 30,
  };
  displayLoader: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private modalService: ModalService,
    public dialogRef: MatDialogRef<UpdatePasswordComponent>,
    private fb: FormBuilder,
    private authService: AuthorizationService,
    @Inject(ENVIRONMENT_CONFIG) private environment: IEnvironmentConfig
  ) {
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    this.timer = this.environment.PASSWORD_RESET_TIMEOUT_SEC;
    this.startTimer(parseInt(this.timer) - 2);
    this.initFormGroup();
  }

  startTimer(seconds: number) {
    this.timer$ = interval(1000).subscribe((sec) => {
      this.progressbarValue = 2 + (sec * 100) / seconds;
      this.curSec = sec;
    });
  }

  stopTimer() {
    this.countdown.stop();
    this.timer$.unsubscribe();
  }

  private initFormGroup() {
    this.passwordForm = this.fb.group({
      password: this.fb.control("", [
        Validators.required,
        Validators.minLength(appConstants.passwordMinLength),
        Validators.maxLength(appConstants.passwordMaxLength),
        regexValidator(new RegExp(appConstants.regex.passwordNumberRegex), {
          number: true,
        }),
        regexValidator(new RegExp(appConstants.regex.passwordSymbolRegex), {
          symbol: true,
        }),
        regexValidator(new RegExp(appConstants.regex.passworedLetterRegex), {
          letter: true,
        }),
      ]),
      confirmPassword: this.fb.control("", [Validators.required]),
    });
  }

  timerCompleted($event: any) {
    const modalData = {
      title: "TIME EXPIRED",
      bodyHeading: "",
      key: this.data.context,
      token: this.data.token,
      accountId: this.data.accountId,
      pinNumber: this.data.pinNumber,
    };

    if ($event.status === 3) {
      if (this.data.context === "forgot-password") {
        modalData.bodyHeading =
          'Your time to reset your password has <b class="error">expired</b>.  Please try again.';
      } else {
        modalData.bodyHeading =
          'Your time to create your password has <b class="error">expired</b>.  Please try again.';
      }

      this.modalService.openDialog<RetryComponent>(
        modalData,
        null,
        RetryComponent
      );

      this.close();
    }
  }

  close() {
    this.dialogRef.close(true);
  }

  submitEnter(e, form) {
    if (e.keyCode === 13 && !form.invalid) {
      this.congrats();
    }
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }
  toggleFieldTextTypecon() {
    this.fieldTextTypecon = !this.fieldTextTypecon;
  }

  get form() {
    return this.passwordForm.controls;
  }

  checkPasswords() {
    if (
      this.passwordForm.get("password").value !==
      this.passwordForm.get("confirmPassword").value
    ) {
      this.passwordForm.setErrors({ invalid: true });
      this.same = true;
    } else {
      this.same = false;
    }
  }

  goBack() {
    this.close();
  }

  congrats() {
    if (this.passwordForm.invalid) {
      return;
    }
    this.stopTime = false;
    this.stopTimer();
    this.displayLoader = true;
    // const token = this.localStorageService.getPasswordToken();
    const data = {
      password: this.passwordForm.get("password").value.trim(),
      token: this.data.token,
    };

    this.subscription.add(
      this.authService.resetPassword(data, this.data.accountId).subscribe(
        () => {
          this.displayLoader = false;
          this.close();
          this.modalService.openDialog<CongratulationsComponent>(
            { context: this.data.context },
            undefined,
            CongratulationsComponent
          );
        },
        () => {
          this.displayLoader = false;
          // this.errorService.openDialog<ModalComponent>(err, {}, undefined);
        }
      )
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.timer$.unsubscribe();
  }
}
