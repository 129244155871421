<ng-container *ngIf="this.homeMcrLocation$ | async as homeMcrLocation">
  <div class="middle-section-wrapper ">
    <div class="locations-header translate-here">
      <p class="heading">{{ homeMcrLocation.heading }}</p>
      <p class="sub-heading">{{ homeMcrLocation.subHeading }}</p>
      <img src="assets/down-arrow.png" alt="down-arrow" />
    </div>
    <!-- <div class="location-back-wrapper"> -->
    <div class="location-container tc-text">
      <div class="location-back"></div>
      <div class="location-card-image-box">
        <div class="card">
          <div class="header">
            <p
              class="heading"
              [innerHTML]="homeMcrLocation.locationHeading | safeHtml: 'html'"
            ></p>
          </div>
          <ul>
            <li *ngFor="let location of homeMcrLocation.locations">
              <a
                class="link"
                [href]="location.hyperLink | safeHtml: 'url'"
                target="_blank"
                >{{ location.name }}</a
              >
            </li>
          </ul>
        </div>
        <div
          class="image"
          *ngIf="
            (homeMcrLocation?.imagesEn)[0]?.isActive ||
            (homeMcrLocation?.imagesFr)[0]?.isActive
          "
        >
          <img
            [src]="
              (homeMcrLocation?.imagesEn)[0]?.file?.publicUrl ||
                (homeMcrLocation?.imagesFr)[0]?.file?.publicUrl
                | safeHtml: 'url'
            "
            [alt]="
              (homeMcrLocation?.imagesEn)[0]?.name ||
              (homeMcrLocation?.imagesFr)[0]?.name
            "
          />
        </div>
      </div>
    </div>
    <!-- </div> -->
    <div class="card-mobile">
      <p
        class="heading"
        [innerHTML]="homeMcrLocation.locationHeading | safeHtml: 'html'"
      ></p>
      <div class="locations">
        <ul class="links">
          <li *ngFor="let location of homeMcrLocation.locations">
            <a [href]="location.hyperLink | safeHtml: 'url'" target="_blank">{{
              location.name
            }}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</ng-container>
