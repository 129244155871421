import { Component, OnInit } from '@angular/core';
import { Footer } from '../../models/footer';
import { FooterService } from '../../services/footer.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'myclubrewards-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  footers$: Observable<Footer>;

  constructor(private footerService: FooterService) {}

  ngOnInit(): void {
    this.queryFooters();
  }

  private queryFooters() {
    this.footers$ = this.footerService.queryFooters();
  }

  scroll() {
    window.scrollTo(0, 0);
  }

}
