import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  CookieStorageService,
  LocalStorageService,
  ModalService,
} from '@myclubrewards/shared';
import { throwError } from 'rxjs';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { PatronEvent } from '../../../models/patron-event';
import { ExclusiveOfferService } from '../../../services/exclusive-offer.service';
import { PatronBookedOfferService } from '../../../services/patron-booked-offer.service';
import { TriggerEmailService } from '../../../services/trigger-email.service';
import { BookEventComponent } from '../book-event/book-event.component';
import { CancelConfirmationComponent } from '../cancel-confirmation/cancel-confirmation.component';
import { DefaultComponent } from '../default/default.component';

@Component({
  selector: 'myclubrewards-cancel-booking',
  templateUrl: './cancel-booking.component.html',
  styleUrls: [
    './cancel-booking.component.scss',
    '../../../../../../shared/components/modal/modal.component.scss',
  ],
})
export class CancelBookingComponent extends BookEventComponent
  implements OnInit, OnDestroy {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PatronEvent,
    public dialogRef: MatDialogRef<CancelBookingComponent | any>,
    protected exclusiveOfferService: ExclusiveOfferService,
    protected patronBookedOfferService: PatronBookedOfferService,
    protected localStorageService: LocalStorageService,
    protected cookieStorageService: CookieStorageService,
    protected modalService: ModalService,
    protected triggerEmailService: TriggerEmailService
  ) {
    super(
      dialogRef,
      undefined,
      modalService,
      exclusiveOfferService,
      cookieStorageService,
      patronBookedOfferService,
      data,
      localStorageService
    );
  }

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  cancelBooking(): void {
    const id = this.cookieStorageService.getUserIdFromCookies();

    this.displayLoader = true;

    this.subscription.add(
      this.exclusiveOfferService
        .bookPatronEvent(
          id,
          this.buildBookPatronEventDTO({
            accountNumber: id,
            eventSessionGuid: this.data.eventSessionGuid,
            requestedTickets: 0,
          })
        )
        .pipe(
          catchError((err) => throwError(err)),
          switchMap(() => {
            this.triggerEmailService.sendEmail(id, {
              data: this.data,
              value: 0,
            });
            return this.updateEvents();
          }),
          finalize(() => {
            this.displayLoader = false;
          })
        )
        .subscribe(
          () => {
            this.close();
            this.modalService.openDialog<CancelConfirmationComponent>(
              {
                ...this.data,
                tite: 'BOOKING MODIFIED',
              },
              {},
              CancelConfirmationComponent
            );
          },
          ({ error }) => {
            this.close();
            if (error.errors[0]?.code === -99)
              this.modalService.openDialog(
                {
                  ...this.data,
                  title: 'CANCEL BOOKING FAILED',
                  message: error.errors[0]?.message,
                },
                {},
                DefaultComponent
              );
          }
        )
    );
  }
}
