import { Injectable } from '@angular/core';

/**
 * Service to preload all assets in background.
 */
@Injectable({
  providedIn: 'root',
})
export class AssetsPreloaderService {
  constructor() {}

  preloadAssets(args: string[]) {
    if (!args.length) return;

    for (const path of args) {
      const image = new Image();
      image.hidden = true;
      image.src = path;
    }
  }
}
