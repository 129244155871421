import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalComponent } from '@myclubrewards/shared';
import { PatronEvent } from '../../../models/patron-event';

@Component({
  selector: 'myclubrewards-booking-confirmation',
  templateUrl: './booking-confirmation.component.html',
  styleUrls: [
    './booking-confirmation.component.scss',
    '../../../../../../shared/components/modal/modal.component.scss',
  ],
})
export class BookingConfirmationComponent extends ModalComponent
  implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<BookingConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PatronEvent | any
  ) {
    super(data, undefined, undefined, dialogRef, undefined);
  }

  ngOnInit(): void {}

  amount() {
    if (this.data.bookedTickets < this.data.currentBookingCount) {
      return (
        (this.data.currentBookingCount - this.data.bookedTickets) *
        this.data.ticketCostPoints
      );
    } else {
      return (
        (this.data.bookedTickets - this.data.currentBookingCount) *
        this.data.ticketCostPoints
      );
    }
  }
}
