
import { IEnvironmentConfig } from '@myclubrewards/shared';

export const environment: IEnvironmentConfig = {
  production: true,
  GRAPHQL_SERVER: 'https://www.myclubrewards.ca/admin/api',
  WEGLOT_API_KEY: 'wg_bb35b298dc460011624ea6011fbf7cc54',
  CLIENT_ID: 'b9a9519199224c39a9baf86e834deb4f',
  CLIENT_SECRET : 'F6c5E4007f734e4190B317F04565226a',
  BASE_URL: 'https://prodapi.gatewaycasinos.ca',
  PASSWORD_RESET_TIMEOUT_SEC: '60',
  GOOGLE_SITE_KEY: '6LdMlSgaAAAAAIm2sSFN60w8hR-G8QD2qeaFON_Q',
  CHECK_USER_INACTIVITY_MINS: 5
};
