import { get } from 'lodash';

interface IDeserializable {
  deserialize(input: any): this;
}

export class Header implements IDeserializable {
  id?: string;
  name?: string;
  menu?: any[];
  isActive?: boolean;
  logo?: any;

  deserialize(input: any): this {
    this.id = get(input, 'id');
    this.name = get(input, 'name');
    this.isActive = get(input, 'isActive');
    this.logo = get(input, 'logo');
    this.menu = get(input, 'menu');
    return this;
  }
}
