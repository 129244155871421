import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';
import { queries } from './queries';
import { get } from 'lodash';
import {
  ApiService,
  ENVIRONMENT_CONFIG,
  IEnvironmentConfig,
} from '@myclubrewards/shared';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class BaseOffersService extends ApiService {
  constructor(
    protected apollo: Apollo,
    protected http: HttpClient,
    @Inject(ENVIRONMENT_CONFIG) protected environment: IEnvironmentConfig
  ) {
    super(http, environment);
  }

  protected buildCMSOfferQuery(): Observable<any> {
    return this.apollo.query<any>({
      query: gql`
        ${queries.allOffers}
      `,
      variables: {
        isActive: true,
        uniqueOfferKey: {
          type: {
            name_in: ['prizecode'],
          },
        },
      },
    });
  }

  protected buildCMSEventsQuery(): Observable<any> {
    return this.apollo.query<any>({
      query: gql`
        ${queries.allEvents}
      `,
      variables: {
        isActive: true,
      },
    });
  }

  protected keyGenerator(prefix: string, value: any): string {
    if (!value) return '';
    return prefix.concat(value).trim();
  }

  protected mapCMSEventImages(res: any, path: string): any {
    res[path] = res[path]?.map((item: any) => {
      const { eventSessionGuid } = item;
      const { eventCategoryName } = item;

      const cmsEvent = res.cmsEvents.data.allOfferImageMappings.find(
        (x: any) => {
          if (!x?.offerImageMappingType) {
            if (eventSessionGuid === x?.uniqueID) {
              return null;
            }
          } else {
            if (
              x?.offerImageMappingType?.name !== 'gms' &&
              eventSessionGuid === x?.uniqueID
            ) {
              return x;
            }
          }
        }
      );

      let cmsEventCategory = res.cmsEvents.data.allOfferTypes.find((x: any) => {
        if (cmsEvent) {
          if (!get(cmsEvent, 'offerImageMappingType')) {
            const tempEvent = res.cmsEvents.data.allSubCategoriesMappings.find(
              (x) => x?.name?.toLowerCase() == eventCategoryName
            );
            if (
              x?.offerTypeCode?.toLowerCase() ===
              (get(tempEvent, 'offerType[0].name') === 'special_events'
                ? 'se'
                : 'c')
            ) {
              return x;
            }
          } else {
            if (
              x?.offerTypeCode?.toLowerCase() ===
              (get(cmsEvent, 'offerImageMappingType.name') === 'special_events'
                ? 'se'
                : 'c')
            ) {
              return x;
            }
          }
        } else {
          if (
            res.cmsEvents.data?.allSubCategoriesMappings.find(
              (x) => x.name?.toLowerCase() == eventCategoryName?.toLowerCase()
            )
          ) {
            let temp = res.cmsEvents.data?.allSubCategoriesMappings.find(
              (x) => x.name?.toLowerCase() == eventCategoryName?.toLowerCase()
            );
            temp = temp?.offerType[0]?.name == 'special_events' ? 'se' : 'c';
            if (x?.offerTypeCode?.toLowerCase() == temp) {
              return x;
            }
          } else {
            if (x?.name == 'Concerts') {
              return x;
            }
          }
        }
      });

      let imageSrc = '';
      let type;

      if (cmsEvent && cmsEvent?.offerImageMappingType?.name) {
        type =
          get(cmsEvent, 'offerImageMappingType.name') === 'special_events'
            ? 'SE'
            : 'C';
      }

      if (cmsEvent && cmsEvent?.imageSrc) {
        imageSrc = get(cmsEvent, 'imageSrc.file.publicUrl');
      } else {
        imageSrc = get(cmsEventCategory, 'categoriesimageSrc.file.publicUrl');
        if (!type) {
          type =
            get(cmsEventCategory, 'offerTypeCode')?.toLowerCase() == 'se'
              ? 'SE'
              : 'C';
        }
      }

      return Object.assign({}, item, {
        imageSrc: imageSrc,
        type: type,
      });
    });

    return res;
  }

  protected mapCMSOfferImages(res: any): any {
    // step 1: lowercase all prizeCodes
    res.expOffers = (get(res, 'expOffers') || [])?.map((x: any) => ({
      ...x,
      prizeCode: x.prizeCode?.toLowerCase(),
    }));

    // step 2: map "cmsOffers and expOffers" images together
    res.expOffers = res.expOffers?.map((item: any) => {
      const { prizeCode, offerType } = item;
      const cmsOffer = res.cmsEvents.data.allOfferImageMappings.find(
        (x: any) => {
          if (
            x?.offerImageMappingType?.name === 'gms' ||
            prizeCode?.toLowerCase() === x?.uniqueID?.toLowerCase()
          ) {
            return x;
          }
        }
      );

      const cmsOfferType = res.cmsEvents.data.allOfferTypes.find(
        (cmsOfferType: any) => {
          if (
            cmsOfferType?.offerTypeCode?.toLowerCase() ===
            offerType?.toLowerCase()
          ) {
            return cmsOfferType;
          }
        }
      );

      let imageSrc = '';

      if (cmsOffer && get(cmsOffer, 'imageSrc.file.publicUrl')) {
        imageSrc = get(cmsOffer, 'imageSrc.file.publicUrl');
      } else {
        imageSrc = get(cmsOfferType, 'categoriesimageSrc.file.publicUrl');
      }

      return Object.assign({}, item, {
        imageSrc: imageSrc,
      });
    });

    return res;
  }

  protected getUserSelections(filters: any): any {
    const data = {
      selectedLocations: [],
      selectedCategories: [],
    };

    const isCategoriesSelected = (
      (filters.eventsAndCategoriesFormArray as any[]) || []
    ).some((x) => x.isChecked);

    const isLocationsSelected = (
      (filters.locationsFormArray as any[]) || []
    ).some((x: any) => x?.isChecked);

    if (isCategoriesSelected) {
      ((filters.eventsAndCategoriesFormArray as any[]) || []).forEach(
        (x: any) => {
          if (x?.isChecked) data.selectedCategories.push(x.name);
        }
      );
    } else {
      ((filters.eventsAndCategoriesFormArray as any[]) || []).forEach(
        (x: any) => {
          data.selectedCategories.push(x.name);
        }
      );
    }

    if (isLocationsSelected) {
      ((filters.locationsFormArray as any[]) || []).forEach((x: any) => {
        if (x?.isChecked) data.selectedLocations.push(x.name);
      });
    } else {
      ((filters.locationsFormArray as any[]) || []).forEach((x: any) => {
        data.selectedLocations.push(x.name);
      });
    }

    return data;
  }
}
