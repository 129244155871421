import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, AbstractControl } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
  CookieStorageService,
  LocalStorageService,
  ModalService,
} from "@myclubrewards/shared";
import { PatronEvent } from "libs/account/src/lib/models/patron-event";
import { throwError } from "rxjs";
import { catchError, switchMap, finalize } from "rxjs/operators";
import { ExclusiveOfferService } from "../../../services/exclusive-offer.service";
import { PatronBookedOfferService } from "../../../services/patron-booked-offer.service";
import { TriggerEmailService } from "../../../services/trigger-email.service";
import { BookEventComponent } from "../book-event/book-event.component";
import { BookingConfirmationComponent } from "../booking-confirmation/booking-confirmation.component";
import { CancelBookingComponent } from "../cancel-booking/cancel-booking.component";
import { DefaultComponent } from "../default/default.component";

@Component({
  selector: "myclubrewards-modify-event",
  templateUrl: "./modify-event.component.html",
  styleUrls: [
    "./modify-event.component.scss",
    "../../../../../../shared/components/modal/modal.component.scss",
  ],
})
export class ModifyEventComponent extends BookEventComponent
  implements OnInit, OnDestroy {
  modifyEventFG: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<BookEventComponent>,
    protected fb: FormBuilder,
    protected modalService: ModalService,
    protected exclusiveOfferService: ExclusiveOfferService,
    protected patronBookedOfferService: PatronBookedOfferService,
    protected localStorageService: LocalStorageService,
    protected cookieStorageService: CookieStorageService,
    @Inject(MAT_DIALOG_DATA) public data: PatronEvent,
    protected triggerEmailService: TriggerEmailService
  ) {
    super(
      dialogRef,
      fb,
      modalService,
      exclusiveOfferService,
      cookieStorageService,
      patronBookedOfferService,
      data,
      localStorageService
    );
  }

  private initmodifyEventFG(): void {
    this.modifyEventFG = this.fb.group({
      imageSrc: [""],
      name: [""],
      eventDateFormatted: [""],
      totalTickets: [0],
      bookingCount: [0],
    });
    this.bookEventFG = this.modifyEventFG;
  }

  ngOnInit(): void {
    this.initmodifyEventFG();
    this.modifyEventFG.patchValue({
      ...this.data,
      bookingCount: this.data.bookedTickets,
    });
    this.modifyEventFG.patchValue({
      totalTickets: this.getMinimumValue([
        this.data.patronTicketsRemaining,
        this.data.availableSeats,
      ]),
    });
  }

  apply(): void {
    const id = this.cookieStorageService.getUserIdFromCookies();

    this.displayLoader = true;
    this.modifyEventFG.disable();

    this.subscription.add(
      this.exclusiveOfferService
        .bookPatronEvent(
          id,
          this.buildBookPatronEventDTO({
            accountNumber: id,
            eventSessionGuid: this.data.eventSessionGuid,
            requestedTickets: this.getRequestedTickets(),
          })
        )
        .pipe(
          catchError((err) => throwError(err)),
          switchMap(() => {
            this.triggerEmailService.sendEmail(id, {
              data: this.data,
              value: this.getRequestedTickets(),
            });
            return this.updateEvents();
          }),
          finalize(() => {
            this.displayLoader = false;
          })
        )
        .subscribe(
          () => {
            this.close();
            this.modalService.openDialog<BookingConfirmationComponent>(
              {
                ...this.data,
                title: "BOOKING MODIFIED",
                currentBookingCount: this.getRequestedTickets(),
              },
              {},
              BookingConfirmationComponent
            );
          },
          ({ error }) => {
            this.close();
            if (error.errors[0]?.code === -125) {
              const message = `<p>Unfortunately, we were unable to process your ticket request for ${this.data.name}.  At this time, you do not have a sufficient amount in your Resort Rewards account. </p>
                <p>Please contact us at 1-800-832-7529 if you have any questions or require further assistance with this booking.</p>
                <p>In order to receive email confirmations regarding your bookings and reservations, please ensure you consent to receiving email communications which is available on the ‘Profile’ tab.</p>
              `;
              this.modalService.openDialog<DefaultComponent>(
                {
                  ...this.data,
                  title: "Insufficient Resort Rewards",
                  message: message,
                },
                {},
                DefaultComponent
              );
            } else if (error.errors[0]?.code === -99)
              this.modalService.openDialog(
                {
                  ...this.data,
                  title: "BOOKING MODIFICATION FAILED",
                  message: error.errors[0]?.message,
                },
                {},
                DefaultComponent
              );
            else
              this.modalService.openDialog(
                {
                  ...this.data,
                  title: "ERROR",
                  message: error.errors[0]?.message,
                },
                {},
                DefaultComponent
              );
          }
        )
    );
  }

  cancelBooking(): void {
    this.close();
    this.modalService.openDialog<CancelBookingComponent>(
      this.data,
      {},
      CancelBookingComponent
    );
  }

  getMinimumValue(arr) {
    return Math.min(...arr);
  }

  counterModify(event: any, fc: AbstractControl, action): void {
    if (action === "decrement") {
      if (fc.value <= this.DECREMENT_STEP) {
        return;
      } else {
        fc.patchValue(fc.value - this.DECREMENT_STEP);
        return;
      }
    } else {
      if (fc.value >= this.getMaximumTicketValue()) {
        return;
      } else {
        fc.patchValue(fc.value + this.INCREMENT_STEP);
        return;
      }
    }
  }

  getMaximumTicketValue() {
    return (
      this.data.bookedTickets +
      this.getMinimumValue([
        this.data.patronTicketsRemaining,
        this.data.availableSeats,
      ])
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
