import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AccountsService } from '../../../services/accounts-service.service';
import { DashboardDetails } from '../../../models/dashboard-modal';
import { Observable, Subscription } from 'rxjs';

@Component({
  selector: 'myclubrewards-mcr-account-dashboard',
  templateUrl: './account-dashboard.component.html',
  styleUrls: ['./account-dashboard.component.scss'],
})
export class McrAccountDashboardComponent implements OnInit {
  userSpecificDashboardDetails$: Observable<DashboardDetails>;
  private subscription: Subscription;
  displayLoader: boolean = true;

  constructor(
    private accountService: AccountsService,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.userSpecificDashboardDetails$ = this.accountService.dashboardDetails$;
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    this.getUserDashboardDetails();
    this.showLoader();
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  showLoader(): void {
    this.subscription.add(
      this.accountService.userSpecificOffers$.subscribe(
        (res) => {
          this.displayLoader = false;
        },
        (error) => {
          this.displayLoader = false;
        }
      )
    );
  }

  getUserDashboardDetails(): void {
    // if (isPlatformBrowser(this.platformId)) {
    this.accountService.getDashboardDetails();
    // }
  }
}
