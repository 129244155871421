import * as _ from 'lodash';

interface IDeserializable {
  deserialize(input: any): this;
}

export class Promotions implements IDeserializable {
  heading?: string;
  subHeading?: string;
  isActive?: boolean;
  promotions: {
    primary?: PromotionDetail;
    secondary?: PromotionDetail[];
  } = {};

  constructor() {}

  deserialize(input: any): this {
    this.heading = _.get(input, 'heading');
    this.subHeading = _.get(input, 'subHeading');

    const temp = _.groupBy(_.get(input, 'promotions', []), 'promotionType');
    if (_.has(temp, 'primary')) {
      this.buildPrimaryPromotions(temp.primary[0]);
    }
    if (_.has(temp, 'secondary')) {
      this.buildSecondaryPromotions(temp.secondary);
    }

    return this;
  }

  private buildPrimaryPromotions(input: any) {
    this.promotions.primary = new PromotionDetail().deserialize(input);
  }
  private buildSecondaryPromotions(input: any[]) {
    if (!input.length) return [];

    this.promotions.secondary = _.map(input, (item) =>
      new PromotionDetail().deserialize(item)
    );
  }
}

export class PromotionDetail implements IDeserializable {
  isActive?: boolean;
  heading?: string;
  subHeading?: string;
  promotionType?: string;
  priority?: number;
  hyperLink?: string;
  hyperLinkText?: string;
  hyperLinkType?: string;
  hyperLinkColorCode?: string;
  details?: string;
  imageEn?: any;
  locations?: any;
  promotionDetails?: any;
  detailActionActive?: boolean;

  deserialize(input): this {
    this.heading = _.get(input, 'heading');
    this.subHeading = _.get(input, 'subHeading');
    this.isActive = _.get(input, 'isActive');
    this.promotionType = _.get(input, 'promotionType');
    this.priority = _.get(input, 'priority');
    this.hyperLinkText = _.get(input, 'hyperLinkText');
    this.hyperLink = _.get(input, 'hyperlink');
    this.hyperLinkType = _.get(input, 'hyperLinkType');
    this.hyperLinkColorCode = _.get(input, 'hyperLinkColorCode');
    this.details = _.get(input, 'details');
    this.imageEn = _.get(input, 'image');
    this.locations = _.get(input, 'locations');
    this.promotionDetails = _.get(input, 'promotionDetails');
    this.detailActionActive = _.get(input, 'isDetailActionActive');

    return this;
  }
}
