<ng-container *ngIf="displayLoader; else loading">
  <div
    class="wrapper"
    *ngIf="bookedOffers$ | async as offers; else noObservableData"
  >
    <div class="wrapper--header">
      <img class="arrow" src="assets/right-arrow.png" alt="right-arrow" />
      <div class="heading-container">
        <div class="heading">MY RESERVATIONS</div>
        <div class="heading">{{ offers?.length }} Reservations</div>
      </div>
    </div>
    <div *ngIf="offers.length; else noData">
      <div class="horizontal_wrapper">
        <myclubshared-horizontal-scroller
          [data]="offers"
          [config]="config"
          [context]="context"
        ></myclubshared-horizontal-scroller>
      </div>
    </div>
    <ng-template #noData>
      <div class="no-data">
        No Reservations Found
      </div>
    </ng-template>
  </div>
  <ng-template #noObservableData>
    <div class="no-data">
      No Reservations Found
    </div>
  </ng-template>
</ng-container>
<ng-template #loading>
  <myclubshared-loader-component></myclubshared-loader-component>
</ng-template>
