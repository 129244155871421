export const appConstants = {
  clubIdMinLength: 5,
  passwordMinLength: 8,
  pinMinLength: 4,
  passwordMaxLength: 15,
  regex: {
    accountIdRegex: /^[0-9]*$/,
    passwordNumberRegex: /^(?=.*[0-9])/,
    passwordSymbolRegex: /^(?=.*[@#$%^&+*!=])./,
    passworedLetterRegex: /^(?=.*[A-Z])/,
    pinRegex: /^[0-9]{4}$/,
    passwordRegex: /^(?=.{8,})(?=.*[a-z])(?=.*[0-9])(?=.*[A-Z])(?=.*[@#$%^&+*!=]).*$/,
    emailRegex: '[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[A-Za-z]{2,3}',
    contactNumberRegex: /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/,
    avoidUnnecessaryUserInput: /^[A-Z@~`!@#$%^&*_=\\\\';:\"\\/?>.<,]*$/gi,
    avoidAlphabetUserInput: /[0-9/]/gi,
  },
  auth: {
    name: 'name',
    userId: 'id',
    // headers
    accessToken: 'x-access-token',
    refreshToken: 'x-refresh-token',
    cardType: 'card-type',
    clientId: 'client_id',
    clientSecret: 'client_secret',
  },
  refreshTokenConfig: {
    accessToken: 'accessToken',
    refreshToken: 'refreshToken',
  },
  DEFAULT_USER_CARD_TYPE: 'rewards',
  PRELOAD_ASSETS: [
    'assets/close.png',
    'assets/down-arrow.png',
    'assets/right-arrow.png',
    'assets/account-locked-image.PNG',
    'assets/icon-password-changed.png',
    'assets/icon-password-change-error.png',
    'assets/image-logo-header-black.png',
    'assets/icon-profile-changed.png',
  ],
};
