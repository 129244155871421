import { get, map } from 'lodash';
import * as moment from 'moment';

interface IDeserializable {
  deserialize(input: any): this;
}

export class PatronOffer implements IDeserializable {
  id?: string;
  name?: string;
  availableSeats?: number;
  ticketCostPoints?: number;
  patronTicketsUsed?: number;
  patronTicketsRemaining: number;
  patronTicketsAllowedMax: number;
  totalTickets?: number;
  eventDate?: Date;
  eventDateFormatted?: string;
  isBuyable?: boolean;
  eventCategoryID?: string;
  eventCategoryGuid?: string;
  eventCategoryName?: string;
  eventSessionStartDate?: number;
  eventSessionEndDate?: number;
  imageSrc?: string;
  bookingCount?: number; // used for calculating booking count only at the client-side
  eventSessionId?: number;
  eventSessionGuid?: string;
  patronGuid?: string;
  bookedTickets?: number;
  eventDescription?: string;
  eventStartTime?: string;
  eventEndTime?: string;
  type?: string;
  isConcert?: boolean;

  deserialize(input: any): this {
    this.id = get(input, 'ticketMasterID');
    this.name = get(input, 'eventName');
    this.totalTickets = get(input, 'totalSeats', 0);
    this.availableSeats = get(input, 'availableSeats');
    this.ticketCostPoints = get(input, 'ticketCostPoints');
    this.patronTicketsUsed = get(input, 'patronTicketsUsed');
    this.patronTicketsRemaining = get(input, 'patronTicketsRemaining');
    this.patronTicketsAllowedMax = get(input, 'patronTicketsAllowedMax');
    this.eventDate = get(input, 'eventSessionDate');
    this.eventDateFormatted = moment(get(input, 'eventSessionDate')).format(
      'MMM D YYYY'
    );
    this.isBuyable = Boolean(get(input, 'isBuyable', false));
    this.eventCategoryID = get(input, 'eventCategoryID');
    this.eventCategoryGuid = get(input, 'eventCategoryGuid');
    this.eventCategoryName = get(input, 'eventCategoryName');
    this.eventSessionStartDate = moment(get(input, 'sessionStartDate')).unix();
    this.eventSessionEndDate = moment(get(input, 'sessionEndDate')).unix();
    this.imageSrc = get(input, 'imageSrc', 'assets/dummy.png');
    this.bookingCount = 1;
    this.eventSessionId = get(input, 'eventSessionID');
    this.type = get(input, 'type');
    this.eventSessionGuid = get(input, 'eventSessionGuid');
    this.patronGuid = get(input, 'patronGuid');
    this.bookedTickets = get(input, 'bookedTickets', 0);
    this.eventDescription = get(input, 'eventDescription', '');
    var tempStart = get(input, 'sessionStartDate')?.toString();
    var tempEnd = get(input, 'sessionEndDate')?.toString();
    // this.eventStartTime = moment(tempStart.split('T')[1], 'HH:mm:ss').format(
    //   'LT'
    // );
    // this.eventEndTime = moment(tempEnd.split('T')[1], 'HH:mm:ss').format('LT');

    return this;
  }
}

export class PatronOffers implements IDeserializable {
  reservations?: PatronOffer[];

  deserialize(input: any): this {
    this.reservations = this.buildOffers(input);
    return this;
  }
  private buildOffers(input: any[]) {
    if (!input.length) return [];

    const tempPatronOffers: PatronOffer[] = map(input, (offers) => {
      return new PatronOffer().deserialize(offers);
    });
    return tempPatronOffers;
  }
}
