<div class="update-link" *ngIf="homeBannerNotice$ | async as homeBannerNotice">
  <span
    class="update-text"
    [innerHTML]="homeBannerNotice.description | safeHtml: 'html'"
  ></span>
    <a
      *ngIf="homeBannerNotice.hyperLinkType === 'url'"
      [href]="homeBannerNotice.hyperLink | safeHtml: 'url'"
      target="_blank"
    >
      &nbsp;{{ homeBannerNotice.hyperLinkText }}</a
    >
    <a
      *ngIf="homeBannerNotice.hyperLinkType === 'phone_number'"
      href="tel:{{ homeBannerNotice.hyperLink | safeHtml: 'url' }}"
    >
      {{ homeBannerNotice.hyperLinkText }}</a
    >
</div>
