import { Component, OnInit } from '@angular/core';
import { AccountsService } from '../../../services/accounts-service.service';
import { PromotionDetails } from '../../../models/promotion-model';
import { Observable } from 'rxjs';

@Component({
  selector: 'myclubrewards-accounts-promotions',
  templateUrl: './accounts-promotions.component.html',
  styleUrls: ['./accounts-promotions.component.scss'],
})
export class AccountsPromotionsComponent implements OnInit {
  userPromotionDetails$: Observable<PromotionDetails>;

  constructor(private accountService: AccountsService) {
    this.userPromotionDetails$ = this.accountService.promotionDetails$;
  }

  ngOnInit(): void {
    this.getAllpromotions();
  }
  getAllpromotions() {
    this.accountService.getAllPromotions();
  }
}
