<div class="card">
  <div
    class="card-image"
    *ngIf="
      config?.imageEn?.isActive ||
      config?.imageFr?.isActive ||
      config?.imagesEn?.isActive ||
      config?.imagesFr?.isActive ||
      (config?.imageEn)[0]?.isActive ||
      (config?.imageFr)[0]?.isActive ||
      (config?.imagesEn)[0]?.isActive ||
      (config?.imagesFr)[0]?.isActive
    "
  >
    <img
      [src]="
        config?.imageEn?.file?.publicUrl ||
          config?.imageFr?.file?.publicUrl ||
          config?.imagesEn?.file?.publicUrl ||
          config?.imagesFr?.file?.publicUrl ||
          (config?.imageEn)[0]?.file?.publicUrl ||
          (config?.imageFr)[0]?.file?.publicUrl ||
          (config?.imagesEn)[0]?.file?.publicUrl ||
          (config?.imagesFr)[0]?.file?.publicUrl ||
          config.imgSrc | safeHtml: 'url'
      "
      [alt]="
        config?.imageEn?.name ||
        config?.imageFr?.name ||
        config?.imagesEn?.name ||
        config?.imagesFr?.name ||
        (config?.imageEn)[0]?.name ||
        (config?.imageFr)[0]?.name ||
        (config?.imagesEn)[0]?.name ||
        (config?.imagesFr)[0]?.name
      "
    />
    <p
      class="card-img-text"
      [innerHTML]="
        config?.imagesEn?.name || config?.imagesFr?.name | safeHtml: 'html'
      "
    ></p>
  </div>
  <div *ngIf="config.layout === cardLayout.DEFAULT_LAYOUT">
    <ng-template [ngTemplateOutlet]="DEFAULT_LAYOUT"></ng-template>
  </div>
  <div
    *ngIf="config.layout === cardLayout.EXPANSION_LAYOUT"
    class="list-container"
  >
  <ng-template [ngTemplateOutlet]="DEFAULT_LAYOUT"></ng-template>
  <ng-template [ngTemplateOutlet]="EXPANSION_LAYOUT"></ng-template>

  </div>
</div>

<ng-template #DEFAULT_LAYOUT>
  <div class="card-wrapper-text">
    <p
      class="heading"
      *ngIf="config.heading"
      [innerHTML]="config.heading | safeHtml: 'html'"
    ></p>
    <p
      class="sub-heading"
      *ngIf="config.subHeading"
      [innerHTML]="config.subHeading | safeHtml: 'html'"
    ></p>
    <div class="list-container" *ngIf="config.title">
      <ul *ngIf="typeCheck(config.title)">
        <li *ngFor="let data of config.title">{{ data }}</li>
      </ul>
    </div>
    <div *ngIf="config.description" class="list-container">
      <span
        class="text-list"
        [innerHTML]="config.description | safeHtml: 'html'"
      ></span>
    </div>
    <div *ngIf="config.button && config.button.length > 0">
      <a
        *ngFor="let button of config.button"
        class="card-button"
        [href]="button.url"
        target="_blank"
        >{{ button.name }}</a
      >
    </div>
  </div>
</ng-template>

<ng-template #EXPANSION_LAYOUT>
  <div class="link-container" *ngIf="config?.detailActionActive">
    <span class="link" [innerHTML]="config.hyperLinkText | safeHtml: 'html'"
      (click)="openPromotionDetailsPopup(config?.promotionDetails?.id)"></span>
  </div>

  <!-- <ng-template [ngTemplateOutlet]="DEFAULT_LAYOUT"></ng-template>
  <mat-accordion>
    <mat-expansion-panel
      (opened)="panelOpenState = true"
      (closed)="panelOpenState = false"
      hideToggle
    >
      <mat-expansion-panel-header>
        <mat-panel-title *ngIf="config?.detailActionActive">
          <span
            class="link"

            [innerHTML]="config.hyperLinkText | safeHtml: 'html'"
            (click)="redirectToPromotionDetails(config?.promotionDetails?.id)"
          ></span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <ng-container *ngIf="config?.locations?.length">
        <ul>
          <li *ngFor="let location of config?.locations">
            <a href="{{ location.hyperLink }}">
              {{ location.name }}
            </a>
          </li>
        </ul>
      </ng-container>
      <p [innerHTML]="config.details | safeHtml: 'html'"></p>
    </mat-expansion-panel>
  </mat-accordion> -->
</ng-template>
