import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ErrorService, ModalService } from '@myclubrewards/shared';
import { AuthorizationService } from '../../services/authorization.service';
import { ChangeProfilePasswordComponent } from '../Profile/change-profile-password/change-profile-password.component';
import { UpdatePasswordComponent } from '../update-password/update-password.component';

@Component({
  selector: 'myclubshared-retry',
  templateUrl: './retry.component.html',
  styleUrls: ['./retry.component.scss'],
})
export class RetryComponent implements OnInit {
  setLoaderConfig: any = {
    diameter: 30,
  };
  displayLoader: boolean = false;
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private modalService: ModalService,
    public dialogRef: MatDialogRef<RetryComponent>,
    private authService: AuthorizationService,
    private errorService: ErrorService
  ) {}

  ngOnInit(): void {}

  retry(key) {
    let modalData = {};

    if (key === 'forgot-password') {
      modalData = {
        title: '<p>RESET PASSWORD</p>',
        bodyHeading: 'Please create a new password',
        showGoBack: false,
        context: key,
        message: 'to reset your password.',
      };
      this.openRetryPassword(modalData);
    } else if (key === 'activate-account') {
      modalData = {
        title: '<p>ACTIVATE YOUR ACCOUNT</p>',
        bodyHeading: 'Please create a password',
        showGoBack: true,
        context: key,
        message: 'to create your password.',
      };
      this.openRetryPassword(modalData);
    } else {
      this.modalService.openDialog<ChangeProfilePasswordComponent>(
        undefined,
        undefined,
        ChangeProfilePasswordComponent
      );
      this.close();
    }
  }

  close() {
    this.dialogRef.close(true);
  }

  openRetryPassword(modalData) {
    this.displayLoader = true;
    this.authService
      .forgotPassword(this.data.pinNumber, this.data.accountId)
      .subscribe(
        (res: any) => {
          this.displayLoader = false;
          this.modalService.openDialog<UpdatePasswordComponent>(
            {
              ...modalData,
              token: res.token,
              accountId: this.data.accountId,
              pinNumber: this.data.pinNumber,
            },
            undefined,
            UpdatePasswordComponent
          );
          this.close();
        },
        (err) => {
          this.displayLoader = false;
          /* this.errorService.openDialog<ModalComponent>(
            err,
            {},
            undefined
          ); */
        }
      );
  }
}
