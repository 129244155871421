export const queries = {
  membershipDetails: `
  query($rewardTypeWhere: RewardTypeWhereInput, $rewardItemWhere: RewardItemWhereInput) {
    allRewardTypes(where: $rewardTypeWhere) {
      id
      isActive
      name
      heading
      subHeading
      colorCode
      imageEn {
        isActive
        file {
          publicUrl
        }
        name
      }
      imageFr {
        isActive
        file {
          publicUrl
        }
        name
      }
    }
    allRewardItems(where: $rewardItemWhere) {
      key
      rewardType {
        name
      }
    }
  }
  `,
  allDashboardPromotions: `
  query($promotionWhere: DashboardPromotionWhereInput) {
    allDashboardPromotions(where: $promotionWhere) {
      isActive
      heading
      subHeading
      private
      priority
      rewardType {
        name
      }
      imagesEn {
        isActive
        file {
          publicUrl
        }
        name
      }
      imagesFr {
        isActive
        file {
          publicUrl
        }
        name

      }
      footer {
        isActive
        body
      }
    }
  }`,
  exclusiveFilterTabData: `
query {
    allExclusiveOffersMenus(where: {
        isActive: true,
    }, sortBy: [priority_ASC]) {
        id
        name
        offerType
        offerTypeCode
        priority
        imageSrc {
            isActive
            file {
                publicUrl
            }
            name
        }
        isActive
    }
}
`,
  allOffers: `query($allOffersWhere: OfferWhereInput) {
    allOfferTypes(where: {
      isActive: true
    }) {
      name
      offerType
      offerTypeCode
      imageSrc {
        name
        file {
          publicUrl
        }
      }
    }
    allOffers(where: $allOffersWhere) {
      imageSrc {
        name
        file {
          publicUrl
        }
      }
      uniqueOfferKey {
        name
        type {
          name
        }
      }
      offerType {
        name
        offerType
        offerTypeCode
        imageSrc {
          name
          file {
            publicUrl
          }
        }
      }
    }
  }`,
  allEvents: `query {
    allOfferTypes(where: {
      isActive: true
    }) {
      name
      offerTypeCode
      categoriesimageSrc {
        name
        file {
          publicUrl
        }
      }
    }
    allOfferImageMappings(where: {
       isActive: true
      }) {
      imageSrc {
        name
        file {
          publicUrl
        }
      }
      name
      uniqueID
      offerImageMappingType {
        name
      }   
    }
    allSubCategoriesMappings(where: {
      isActive: true
    }) {
      name
      uniqueId
      priority
      offerType {
        name
      }
    }      
  }`,
};
