import { Injectable } from '@angular/core';
import {md5} from 'pure-md5';

@Injectable({
  providedIn: 'root'
})
export class EncryptService {

  constructor() { }

  encryptPassWord(password): string{
    const hash = md5(password);
    return hash;
  }
}
