import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ModalComponent } from '@myclubrewards/shared';
import { PatronEvent } from '../../../models/patron-event';

@Component({
  selector: 'myclubrewards-default',
  templateUrl: './default.component.html',
  styleUrls: [
    './default.component.scss',
    '../../../../../../shared/components/modal/modal.component.scss',
  ],
})
export class DefaultComponent extends ModalComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DefaultComponent>,
    @Inject(MAT_DIALOG_DATA) public data: PatronEvent | any
  ) {
    super(data, undefined, undefined, dialogRef, undefined);
  }

  ngOnInit(): void {}
}
