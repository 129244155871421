import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { AccountComponent } from './components/account/account.component';
import { McrAccountDashboardComponent } from './components/Dashboard/account-dashboard/account-dashboard.component';
import { AccountsPromotionsComponent } from './components/Dashboard/accounts-promotions/accounts-promotions.component';
import { AccountsExclusiveOfferComponent } from './components/Offers/accounts-exclusive-offer/accounts-exclusive-offer.component';
import { McrPatronOffersComponent } from './components/Offers/patron-offers/patron-offers.component';
import { McrAccountProfileComponent } from './components/Profile/account-profile/account-profile.component';
import { AuthGuard } from './guards/auth.guard';

export const routes: Route[] = [
  {
    path: '',
    component: AccountComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'dashboard',
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: McrAccountDashboardComponent,
            canActivate: [AuthGuard],
            outlet: 'primary',
          },
          {
            path: '',
            component: AccountsPromotionsComponent,
            canActivate: [AuthGuard],
            outlet: 'secondary',
          },
        ],
      },
      {
        path: 'offers/:offerType',
        canActivate: [AuthGuard],
        children: [
          {
            path: '',
            component: McrPatronOffersComponent,
            canActivate: [AuthGuard],
            outlet: 'primary',
          },
          {
            path: '',
            component: AccountsExclusiveOfferComponent,
            canActivate: [AuthGuard],
            outlet: 'secondary',
          },
        ],
      },
      {
        path: 'profile',
        component: McrAccountProfileComponent,
        canActivate: [AuthGuard],
        outlet: 'primary',
      },
      {
        path: 'reservations',
        component: McrPatronOffersComponent,
        canActivate: [AuthGuard],
        outlet: 'primary',
      },
    ],
  },
  // {
  //   path: '**',
  //   component: PageNotFoundComponent,
  //   canActivate: [AuthGuard],
  // },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AccountRoutingModule {}
