<mat-card class="promotion_card">
  <div class="disclaimer" *ngIf="getPromotion$ | async as promotion; else loading">
    <div class="promotion_details_icon">
      <img mat-dialog-close src="assets/close.png" alt="close" />
    </div>
    <div class="disclaimer__image_div" *ngIf="promotion?.isActive">
      <div class="disclaimer__image_div__image">
        <img  *ngIf="!!promotion?.imageSrc" [src]="promotion?.imageSrc | safeHtml: 'url'"/>
      </div>
    </div>
    <div class="disclaimer__content_div" *ngIf="promotion?.isActive">
      <!-- <p *ngIf="!!promotion?.heading">
        {{ promotion?.heading }}
      </p>
      <p *ngIf="!!promotion?.subHeading">
        {{ promotion?.subHeading }}
      </p> -->
      <ng-container *ngIf="!!promotion?.heading && !!promotion?.subHeading && !!promotion?.details">
        <p class="heading">{{ promotion?.heading }}<br>{{ promotion?.subHeading }}</p>
        <p class="subheading">{{ promotion?.details }}</p>
      </ng-container>
    </div>
  </div>
</mat-card>
<ng-template #loading>
  <myclubshared-loader-component></myclubshared-loader-component>
</ng-template>
