import { get, map } from 'lodash';

interface IDeserializable {
  deserialize(input: any): this;
}
export class OfferMenuTab implements IDeserializable {
  id: string;
  filterImageSrc: string;
  isActive: boolean;
  offerFilterName: string;
  offerType: string;
  offerTypeCode: string;
  priority: number;

  deserialize(input: any): this {
    this.id = get(input, 'id', '-');
    this.filterImageSrc = get(input, 'imageSrc.file.publicUrl', '');
    this.isActive = get(input, 'isActive', false);
    this.offerFilterName = get(input, 'name', '-');
    this.offerType = get(input, 'offerType', '-');
    this.offerTypeCode = get(input, 'offerTypeCode', '-');
    this.priority = get(input, 'priority', 0);
    return this;
  }
}

export class OfferTabs implements IDeserializable {
  offerTabList?: OfferMenuTab[];

  deserialize(input: any): this {
    this.offerTabList = this.buildFilterTab(input.offerFiltersTab);
    return this;
  }
  private buildFilterTab(input: any[]) {
    if (!input.length) return [];

    const tempOfferTabs: OfferMenuTab[] = map(input, (tab) => {
      return new OfferMenuTab().deserialize(tab);
    });
    return tempOfferTabs;
  }
}
