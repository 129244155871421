<div class="wrapper" [formGroup]="offerFilterForm">
  <div class="wrapper-header" *ngIf="true">
    <myclubrewards-editable-card-header
      [config]="config"
    ></myclubrewards-editable-card-header>
  </div>
  <div class="wrapper-container">
    <div class="back"></div>
    <div class="content" #exclusiveOfferContent>
      <div class="nav">
        <img
          class="arrow nav-end"
          src="assets/right-arrow.png"
          alt="right-arrow"
        />
        <ng-container
          *ngIf="exclusiveOfferMenus$ | async as exclusiveOfferMenu"
        >
          <div
            [ngClass]="{
              'nav-items': true,
              'nav-end': i == exclusiveOfferMenu.length - 1
            }"
            *ngFor="let navItem of exclusiveOfferMenu; let i = index"
          >
            <button
              *ngIf="navItem?.isActive"
              (click)="selectedExclusiveOffer(navItem)"
              [ngClass]="
                navItem?.offerType?.toLowerCase() ===
                selectedOfferType?.toLowerCase()
                  ? 'selected_button'
                  : ''
              "
            >
              <img
                *ngIf="!!navItem?.filterImageSrc"
                [src]="navItem?.filterImageSrc"
              />
              <p *ngIf="!!navItem?.offerFilterName">
                {{ navItem?.offerFilterName }}
              </p>
            </button>
          </div>
        </ng-container>
      </div>
      <hr class="seperator" />
      <div class="filter-wrapper">
        <p class="label">DATE RANGE / LOCATIONS</p>
        <div class="label-filter" #filterMenu>
          <div class="filter" (click)="openExclusiveOfferFilter()">
            Filter<img
              class="arrow"
              src="assets/down-arrow.png"
              alt="down-arrow"
            />
          </div>
          <div
            class="filter-box"
            *ngIf="displayFilter"
            [ngStyle]="{
              width: userSelectedLanguage === 'en' ? '260px' : '300px'
            }"
          >
            <div class="header-img">
              <img
                class="arrow"
                src="assets/right-arrow.png"
                alt="right-arrow"
              />
              <div class="feild-label">DATE RANGE</div>
            </div>
            <mat-form-field appearance="fill">
              <input
                [min]="minDate"
                matInput
                [matDatepicker]="startDate"
                formControlName="startDate"
                placeholder="DD/MM/YYYY"
                (dateInput)="updateMinDate($event)"
                autocomplete="off"
                appAvoidUnnecessaryCharacterDirective
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="startDate"
                #startDateToggle
              ></mat-datepicker-toggle>
              <mat-datepicker #startDate [startAt]="startDate"></mat-datepicker>
            </mat-form-field>
            <div class="date-label">TO</div>
            <mat-form-field appearance="fill">
              <input
                matInput
                [matDatepicker]="endDate"
                formControlName="endDate"
                [min]="offerFilterForm.value.startDate"
                placeholder="DD/MM/YYYY"
                autocomplete="off"
                appAvoidUnnecessaryCharacterDirective
              />
              <mat-datepicker-toggle
                matSuffix
                [for]="endDate"
              ></mat-datepicker-toggle>
              <mat-datepicker #endDate [startAt]="startDate"></mat-datepicker>
            </mat-form-field>
            <!-- Locations Menu -->
            <div class="location_categories">
              <div class="location-wrapper">
                <div class="location-wrapper-header">
                  <img
                    class="arrow"
                    src="assets/right-arrow.png"
                    alt="right-arrow"
                  />
                  <div class="location-wrapper-header-heading">LOCATIONS</div>
                  <!-- <img src="assets/select-on.png" /> -->
                  <div
                    class="select"
                    *ngIf="
                      selectedOfferTypeCode?.toLowerCase() == 'f' ||
                      selectedOfferTypeCode?.toLowerCase() == 'p' ||
                      selectedOfferTypeCode?.toLowerCase() == 'i' ||
                      selectedOfferTypeCode?.toLowerCase() == 'm'
                    "
                  >
                    <div class="select-all">
                      <input
                        type="checkbox"
                        name="chooseAllLocations"
                        id="chooseAllLocations"
                        formControlName="chooseAllLocations"
                        class="cursor"
                        (change)="checkIfLocationIsEmpty()"
                      />
                      <label for="chooseAllLocations" class="cursor">
                        All
                      </label>
                    </div>
                  </div>
                </div>
                <ul class="checkbox-list" formArrayName="locationsFormArray">
                  <ng-container
                    *ngFor="
                      let location of this.offerFilterForm.get(
                        'locationsFormArray'
                      )['controls'];
                      let i = index
                    "
                    formGroupName="{{ i }}"
                  >
                    <li
                      class="item"
                      *ngIf="
                        (i == 0 &&
                          selectedOfferTypeCode?.toLowerCase() !== 'c') ||
                        (i == 0 &&
                          selectedOfferTypeCode?.toLowerCase() !== 'se') ||
                        (selectedOfferTypeCode?.toLowerCase() !== 'c' &&
                          selectedOfferTypeCode?.toLowerCase() !== 'se')
                      "
                    >
                      <input
                        type="checkbox"
                        name="{{ location?.value?.name }}"
                        id="{{ location?.value?.name }}"
                        value="{{ location?.value?.name }}"
                        [checked]="
                          location?.value?.isChecked ||
                          selectedOfferTypeCode?.toLowerCase() == 'c' ||
                          selectedOfferTypeCode?.toLowerCase() == 'se'
                        "
                        (change)="selectLocations(location)"
                        class="cursor"
                        [disabled]="
                          selectedOfferTypeCode?.toLowerCase() == 'c' ||
                          selectedOfferTypeCode?.toLowerCase() == 'se'
                        "
                      />
                      <label
                        for="{{ location?.value?.name }}"
                        class="cursor padding"
                        >{{ location?.value?.name }}</label
                      >
                    </li>
                  </ng-container>
                </ul>
              </div>
            </div>
            <!-- Locations Menu End -->
            <!-- Event Categories Menu -->
            <div
              class="location_categories"
              *ngIf="
                selectedOfferTypeCode?.toLowerCase() === 'c' ||
                selectedOfferTypeCode?.toLowerCase() === 'se'
              "
            >
              <div class="location-wrapper">
                <div class="location-wrapper-header">
                  <img
                    class="arrow"
                    src="assets/right-arrow.png"
                    alt="right-arrow"
                  />
                  <div class="location-wrapper-header-heading">CATEGORIES</div>
                </div>
                <ul
                  class="checkbox-list"
                  formArrayName="eventsAndCategoriesFormArray"
                >
                  <ng-container
                    *ngFor="
                      let events of this.offerFilterForm.get(
                        'eventsAndCategoriesFormArray'
                      )['controls'];
                      let i = index
                    "
                  >
                    <li
                      class="item"
                      *ngIf="
                        selectedOfferTypeCode?.toLowerCase() === 'c' &&
                        events?.value?.type == 'concerts'
                      "
                    >
                      <input
                        type="checkbox"
                        name="{{ events?.value?.name }}"
                        id="{{ events?.value?.name }}"
                        value="{{ events?.value?.name }}"
                        [checked]="events?.value?.isChecked"
                        class="cursor"
                        (change)="selectEvents(events)"
                      />
                      <label
                        for="{{ events?.value?.name }}"
                        class="cursor padding"
                        >{{ events?.value?.name }}</label
                      >
                    </li>
                    <li
                      class="item"
                      *ngIf="
                        selectedOfferTypeCode?.toLowerCase() === 'se' &&
                        events?.value?.type == 'special-events'
                      "
                    >
                      <input
                        type="checkbox"
                        name="{{ events?.value?.name }}"
                        id="{{ events?.value?.name }}"
                        value="{{ events?.value?.name }}"
                        [checked]="events?.value?.isChecked"
                        class="cursor"
                        (change)="selectEvents(events)"
                      />
                      <label
                        for="{{ events?.value?.name }}"
                        class="cursor padding"
                        >{{ events?.value?.name }}</label
                      >
                    </li>
                  </ng-container>
                </ul>
              </div>
            </div>
            <!-- Event Categories Menu End -->
            <button
              class="apply-button"
              [disabled]="offerFilterForm.invalid"
              (click)="saveAndApplyFilter()"
              [ngClass]="{
                'is-disabled': offerFilterForm.invalid
              }"
            >
              Apply
            </button>
          </div>
        </div>
      </div>
      <div *ngIf="displayLoader || !isDataLoaded">
        <myclubshared-loader-component></myclubshared-loader-component>
      </div>
      <div class="cards" *ngIf="exclusiveOffers$ | async as exclusiveOffers">
        <ng-container
          *ngIf="
            exclusiveOffers?.length && isDataLoaded;
            then showOfferEvents;
            else noOffers
          "
        >
        </ng-container>
        <ng-template #showOfferEvents>
          <ng-container *ngFor="let item of exclusiveOffers; index as i">
            <div
              class="card"
              [id]="i"
              *ngIf="
                selectedOfferTypeCode?.toLowerCase() !== 'c' &&
                selectedOfferTypeCode?.toLowerCase() !== 'se'
              "
            >
              <div class="image">
                <img
                  class="image__block"
                  [src]="item?.image || 'assets/dummy.png'"
                />
              </div>
              <div class="text">
                <p class="title">{{ item?.description }}</p>
                <span class="points"
                  >{{ (item?.SiteCode)[0] }}
                  <button
                    *ngIf="item?.SiteCode?.length > 1"
                    class="point-button"
                  >
                    + {{ item?.SiteCode?.length - 1 }} others
                    <span class="tooltiptext">
                      <ng-container *ngFor="let location of item?.SiteCode">
                        <p
                          *ngIf="location !== (item?.SiteCode)[0]"
                          style="
                            text-align: start !important;
                            margin-bottom: 2px;
                          "
                        >
                          {{ location }} <br />
                        </p>
                      </ng-container>
                    </span>
                  </button>
                </span>
                <!-- <p class="points">{{ item?.units + ' pts' }}</p> -->
                <p class="date">
                  {{
                    (item?.validFrom.split(' '))[0] +
                      ' ' +
                      item?.validFrom.split(' ')[1].slice(0, 3) +
                      ' ' +
                      (item?.validFrom.split(' '))[2]
                  }}
                  -
                  {{
                    (item?.expiresAt.split(' '))[0] +
                      ' ' +
                      item?.expiresAt.split(' ')[1].slice(0, 3) +
                      ' ' +
                      (item?.expiresAt.split(' '))[2]
                  }}
                </p>
              </div>
            </div>
            <!-- Concerts/Events Cards -->
            <div
              class="card"
              [id]="i"
              *ngIf="
                selectedOfferTypeCode?.toLowerCase() === 'c' &&
                item.totalTickets > 0 &&
                item.isConcert
              "
            >
              <img class="image" [src]="item?.imageSrc" />
              <div class="text">
                <p class="title">{{ item?.name }}</p>
                <p class="points">Casino Rama Resort</p>
                <p class="date">
                  {{ item?.eventDateFormatted }}
                </p>
                <p class="points">
                  Tickets Left:
                  {{
                    getMinimumValue([
                      item.patronTicketsRemaining,
                      item.availableSeats
                    ])
                  }}
                </p>
                <p class="ticketCostPoints">
                  ${{ item?.ticketCostPoints
                  }}<span class="pl-5 light-text">Per ticket</span>
                </p>
              </div>
              <button
                class="btn btn-theme btn-medium w-100"
                *ngIf="!isBookButtonClicked[i]"
                (click)="bookConcertTicket(i)"
              >
                Book
              </button>
              <ng-container *ngIf="isBookButtonClicked[i]">
                <myclubrewards-counter
                  [index]="i"
                  [data]="item"
                  [loading]="loader[i]"
                  (sendCount)="updateBookedTicket($event)"
                ></myclubrewards-counter>
              </ng-container>
            </div>
            <div
              class="card"
              [id]="i"
              *ngIf="
                selectedOfferTypeCode?.toLowerCase() === 'se' &&
                item.totalTickets > 0 &&
                !item.isConcert
              "
            >
              <img class="image" [src]="item?.imageSrc" />
              <div class="text">
                <p class="title">{{ item?.name }}</p>
                <p class="points">Casino Rama Resort</p>
                <p class="date">
                  {{ item?.eventDateFormatted }}
                </p>
                <p class="points">
                  Tickets Left:
                  {{
                    getMinimumValue([
                      item.patronTicketsRemaining,
                      item.availableSeats
                    ])
                  }}
                </p>
                <p class="ticketCostPoints">
                  ${{ item?.ticketCostPoints
                  }}<span class="pl-5 light-text">Per ticket</span>
                </p>
              </div>
              <button
                class="btn btn-theme btn-medium w-100"
                *ngIf="!isBookButtonClicked[i]"
                (click)="bookConcertTicket(i)"
              >
                Book
              </button>
              <ng-container *ngIf="isBookButtonClicked[i]">
                <myclubrewards-counter
                  [index]="i"
                  [data]="item"
                  [loading]="loader[i]"
                  (sendCount)="updateBookedTicket($event)"
                ></myclubrewards-counter>
              </ng-container>
            </div>
          </ng-container>
        </ng-template>
        <ng-template #noOffers>
          <div
            class="no_offers_found"
            *ngIf="!exclusiveOffers?.length && isDataLoaded"
          >
            <img
              src="assets/icon-no-event@3x.png"
              alt="No offer image"
              class="no_offer_image"
            />
            <p>
              SORRY, CURRENTLY THERE ARE <br />
              NO OFFERS AVAILABLE.
            </p>
          </div>
        </ng-template>
      </div>
      <hr class="seperator" />
      <div class="note" *ngIf="exclusiveOffers$ | async as exclusiveOffers">
        <div *ngIf="!loading && exclusiveOffers?.length">
          Please note if you want to cancel your ticket, you will need to do it
          7 days prior to the date of the show. If you fail to cancel your
          tickets within this time frame, the Resort Rewards paid for the ticket
          will be forfeited and you will not receive a refund on this amount.
        </div>
      </div>
    </div>
  </div>
</div>
<myclubrewards-loader *ngIf="loading"></myclubrewards-loader>
