<div class="wrapper">
  <div class="header">
    <div class="icon">
      <img src="assets/down-arrow.png" alt="down-arrow" />
      <img
        mat-dialog-close
        src="assets/close.png"
        class="close"
        alt="close"
        (click)="close()"
      />
    </div>
    <p class="heading">{{ data?.title }}</p>
  </div>
  <hr class="seperator" />
  <div class="content">
    <p class="content__text" [innerHTML]="data?.message"></p>
  </div>
  <hr class="seperator" />
  <div class="wrapper--footer">
    <div class="button-loader">
      <button class="book-btn" (click)="close()">
        OK
      </button>
    </div>
  </div>
</div>
