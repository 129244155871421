import { InjectionToken } from '@angular/core';

export interface IEnvironmentConfig {
  production: boolean;
  name?: string;
  base_url?: string;
  GRAPHQL_SERVER?: string;
  WEGLOT_API_KEY?: string;
  CLIENT_ID?: string;
  CLIENT_SECRET?: string;
  BASE_URL?: string;
  PASSWORD_RESET_TIMEOUT_SEC?: string;
  GOOGLE_SITE_KEY?: string;
  CHECK_USER_INACTIVITY_MINS?: number;
}

export const ENVIRONMENT_CONFIG: InjectionToken<IEnvironmentConfig> = new InjectionToken(
  ''
);
