import { ModalService } from './../../services/modal.service';
import { PromotionDetailsComponent } from './../../../promotions/src/lib/components/promotion-details/promotion-details.component';
import { Component, Input, OnInit } from '@angular/core';
import { CARD_LAYOUT } from '../../types/card-layout';
import { PromotionDetailComponent } from 'libs/promotions/src/lib/components/promotion-detail/promotion-detail.component';

@Component({
  selector: 'myclubrewards-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit {
  cardLayout = CARD_LAYOUT;
  panelOpenState = false;
  private _defaultValue = {
    heading: '',
    subHeading: '',
    title: '',
    imgSrc: 'https://www.w3schools.com/howto/img_avatar.png',
    imageEn: '',
    imageFr: '',
    button: [],
    description: '',
    layout: CARD_LAYOUT.DEFAULT_LAYOUT,
  };

  private _config;

  @Input('config') set config(value) {
    this._config = { ...this._defaultValue, ...value };
  }

  get config() {
    if (this._config !== undefined) {
      return { ...this._defaultValue, ...this._config };
    } else {
      return this._defaultValue;
    }
  }

  constructor(private modalService: ModalService) {}

  ngOnInit(): void {}

  typeCheck(data) {
    return Array.isArray(data);
  }
  openPromotionDetailsPopup(id: string) {
    if (!!id) {
      const dialog = this.modalService.openDialog<PromotionDetailComponent>(
        {
          id:id
        },
        {
          width : 'fit-content',
        },
        PromotionDetailComponent);
    }
  }
}
