<div class="wrapper">
  <div class="header">
    <div class="icon">
      <img src="assets/down-arrow.png" alt="down-arrow" />
      <img mat-dialog-close src="assets/close.png" class="close" alt="close" />
    </div>
    <p class="heading">{{ data?.title }}</p>
  </div>
  <hr class="seperator" />
  <div class="content">
    <p class="content__text">
      <ng-container *ngIf="data.bookedTickets === 0; else increaseTicket">
        <p>
          Thank you for booking your tickets for {{ data?.name }}. The ticket
          amount of $ {{ amount() }} has been deducted from your Resort Rewards
          account.
        </p>
        <p>
          If you have any questions or concerns with this booking, please
          contact us at
          <a class="value" href="tel:18008327529"> 1-800-832-7529 </a>.
        </p>
        <p>
          In order to receive email confirmations regarding your bookings and
          reservations, please ensure you consent to receiving email
          communications which is available on the ‘Profile’ tab.
        </p>
      </ng-container>
      <ng-template #increaseTicket>
        <ng-container
          *ngIf="
            data.bookedTickets < data.currentBookingCount;
            else decreaseTicket
          "
        >
          <p>
            You have successfully increased your ticket booking for
            {{ data?.name }}. The additional amount of $ {{ amount() }} has been
            deducted from your Resort Rewards account.
          </p>
          <p>
            If you have any questions or concerns with the changes made to this
            booking, please contact us at
            <a class="value" href="tel:18008327529"> 1-800-832-7529 </a>.
          </p>
          <p>
            In order to receive email confirmations regarding your bookings and
            reservations, please ensure you consent to receiving email
            communications which is available on the ‘Profile’ tab.
          </p>
        </ng-container>
      </ng-template>
      <ng-template #decreaseTicket>
        <p>
          You have successfully decreased your ticket booking for
          {{ data?.name }}. The amount of $ {{ amount() }} has been credited
          back to your Resort Rewards account.
        </p>
        <p>
          If you have any questions or concerns with the changes made to this
          booking, please contact us at
          <a class="value" href="tel:18008327529"> 1-800-832-7529 </a>.
        </p>
        <p>
          In order to receive email confirmations regarding your bookings and
          reservations, please ensure you consent to receiving email
          communications which is available on the ‘Profile’ tab.
        </p>
      </ng-template>
    </p>
  </div>
  <hr class="seperator" />
  <div class="wrapper--footer">
    <div class="button-loader">
      <button class="book-btn" (click)="close()">
        OK
      </button>
    </div>
  </div>
</div>
