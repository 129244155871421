<ng-container *ngIf="footers$ | async as footer">
    <div class="doNotPrint footer-wrapper" *ngIf="footer?.isActive">
        <div class="upper-block">
            <div class="about-block">
                <div class="logo" *ngIf="footer?.logo?.isActive">
                    <img [src]="footer?.logo?.file?.publicUrl | safeHtml: 'url'" [alt]="footer?.logo?.name" />
                </div>
                <div class="feilds-wrapper">
                    <div class="feild">
                        <p class="label">{{ footer.aboutHeading }}</p>
                        <a class="value" (click)="scroll()" routerLink="/about">{{
              footer.aboutSubHeading
            }}</a>
                    </div>
                    <div class="feild">
                        <a class="label value" (click)="scroll()" routerLink="/terms-condition">{{ footer.termsAndConditionsHeading }}</a
            >
            <a class="value" (click)="scroll()" routerLink="/privacy-policy">{{
              footer.privacyPolicyHeading
            }}</a>
                    </div>
                    <div class="feild">
                        <p class="label">{{ footer.queryHeading }}</p>
                        <a class="value" href="tel:{{ footer.phoneNumber }}">
              Call {{ footer.phoneNumber }}
            </a>
                    </div>
                </div>
            </div>
            <p class="text">
                {{ footer.copyrightText }}
            </p>
        </div>
        <div class="lower-block">
            <div class="logo-block">
                <a *ngIf="footer.playSmart.isActive" class="logo" href="{{ footer.playSmart.url | safeHtml: 'url' }}" target="_blank">
                    <img [src]="
              footer?.playSmart?.imageEn?.file?.publicUrl ||
                footer?.playSmart?.imageFr?.file?.publicUrl | safeHtml: 'url'
            " [alt]="
              footer?.playSmart?.imageEn?.name ||
              footer?.playSmart?.imageFr?.name
            " />
                </a>
                <div class="round-number" *ngIf="footer.ninteenPlusImage.isActive">
                    <img [src]="
              footer?.ninteenPlusImage?.file?.publicUrl ||
                footer?.ninteenPlusImage?.file?.publicUrl | safeHtml: 'url'
            " [alt]="
              footer?.ninteenPlusImage?.name || footer?.ninteenPlusImage?.name
            " />
                </div>
            </div>
            <div class="text-block">
                <p class="heading" [innerHTML]="footer.warningText | safeHtml: 'html'"></p>
                <span class="paragraph" [innerHTML]="footer.description | safeHtml: 'html'"></span>
            </div>
            <div class="gateway-logo" *ngIf="footer.gateWay.isActive">
                <a href="{{ footer.gateWay.url | safeHtml: 'url' }}" target="_blank"><img *ngIf="
              footer?.gateWay?.imageEn?.isActive ||
              footer?.gateWay?.imageFr?.isActive
            " [src]="
              footer?.gateWay?.imageEn?.file?.publicUrl ||
                footer?.gateWay?.imageFr?.file?.publicUrl | safeHtml: 'url'
            " [alt]="
              footer?.gateWay?.imageEn?.name || footer?.gateWay?.imageFr?.name
            " /></a>
            </div>
        </div>
        <div class="footer-tablet">
            <div class="logo" *ngIf="footer?.logo?.isActive">
                <img [src]="footer?.logo?.file?.publicUrl | safeHtml: 'url'" [alt]="footer?.logo?.name" />
            </div>
            <div class="route-feilds">
                <a class="value" (click)="scroll()" routerLink="/about">{{
          footer.aboutHeading
        }}</a>
                <a class="label value" (click)="scroll()" routerLink="/terms-condition">{{ footer.termsAndConditionsHeading }}</a
        >
        <a class="value" (click)="scroll()" routerLink="/privacy-policy">{{
          footer.privacyPolicyHeading
        }}</a>
            </div>
            <a class="contact" href="tel:{{ footer.phoneNumber }}">{{ footer.queryHeading }}&nbsp;Call {{ footer.phoneNumber }}
      </a>
            <p class="copy-right-text">{{ footer.copyrightText }}</p>
            <p class="warning-text" [innerHTML]="footer.warningText | safeHtml: 'html'"></p>
            <span class="footer-tab-text" [innerHTML]="footer.description | safeHtml: 'html'"></span>
            <div class="gateway-logo" *ngIf="footer?.gateWay?.isActive">
                <a href="{{ footer.gateWay.url | safeHtml: 'url' }}" target="_blank"><img *ngIf="
              footer?.gateWay?.imageEn?.isActive ||
              footer?.gateWay?.imageFr?.isActive
            " [src]="
              footer?.gateWay?.imageEn?.file?.publicUrl ||
                footer?.gateWay?.imageFr?.file?.publicUrl | safeHtml: 'url'
            " [alt]="
              footer?.gateWay?.imageEn?.name || footer?.gateWay?.imageFr?.name
            " /></a>
            </div>
            <div class="play-smart-logo">
                <a *ngIf="footer?.playSmart?.isActive" class="logo" href="{{ footer.playSmart.url | safeHtml: 'url' }}" target="_blank">
                    <img [src]="
              footer?.playSmart?.imageEn?.file?.publicUrl ||
                footer?.playSmart?.imageFr?.file?.publicUrl | safeHtml: 'url'
            " [alt]="
              footer?.playSmart?.imageEn?.name ||
              footer?.playSmart?.imageFr?.name
            " />
                </a>
                <div class="round-number" *ngIf="footer?.ninteenPlusImage?.isActive">
                    <img [src]="
              footer?.ninteenPlusImage?.file?.publicUrl ||
                footer?.ninteenPlusImage?.file?.publicUrl | safeHtml: 'url'
            " [alt]="
              footer?.ninteenPlusImage?.name || footer?.ninteenPlusImage?.name
            " />
                </div>
            </div>
        </div>
    </div>
</ng-container>