<div class="wrapper">
  <div class="header">
    <div class="icon">
      <img src="assets/down-arrow.png" alt="down-arrow" />
      <img src="assets/close.png" class="close" alt="close" (click)="this.close()"/>
    </div>
    <p class="heading" [innerHTML]="data.title"></p>
  </div>

  <hr class="seperator" />
  <div class="text-container">
    <p class="heading">Please enter your Club ID & PIN</p>
  </div>
  <form
    class="feilds-wrapper"
    [formGroup]="activateForm"
    (keydown)="submitEnter($event, activateForm)"
  >
    <div class="feild">
      <label class="input-label" for="accountId">Club ID</label>
      <input
        placeholder="Enter Club ID"
        formControlName="accountId"
        class="input-feild"
        name="accountId"
        (keyup)="resetInvalid()"
      />
    </div>

    <div class="feild">
      <label class="input-label" for="pinNumber">Club PIN</label>
      <input
        placeholder="Enter Club PIN"
        type="password"
        formControlName="pinNumber"
        class="input-feild"
        name="pinNumber"
        (keyup)="resetInvalid()"
      />
    </div>
  </form>

  <p *ngIf="invalidCredential" class="invalidCredentials">
    Club ID and/or PIN is incorrect. Please try again.
  </p>
  <!-- <div class="align-captcha">
    <myclubshared-captcha (emitCaptchaResponse)="getCaptchaResponse($event)" #googleCaptcha></myclubshared-captcha>
    </div> -->
  <hr class="seperator" />

  <div class="wrapper--footer">
    <div class="button-loader">
      <button
        type="submit"
        (click)="submitChangePasswordRequest()"
        [ngClass]="{
          'is-disabled': activateForm.invalid || displayLoader
        }"
        [disabled]="activateForm.invalid || displayLoader"
      >
        NEXT
      </button>

      <div class="loader" *ngIf="displayLoader">
        <myclubshared-loader-component
          [loaderConfig]="setLoaderConfig"
        ></myclubshared-loader-component>
      </div>
    </div>
  </div>
</div>
<div *ngIf="showRecaptcha">
  <myclubshared-captcha
    (emitCaptchaResponse)="getCaptchaResponse($event)"
    #googleCaptcha
  ></myclubshared-captcha>
</div>
