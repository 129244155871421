import { Injectable } from '@angular/core';
import { Meta, MetaDefinition } from '@angular/platform-browser';

/**
 * SEO Tags update service wrapper.
 */
@Injectable({
  providedIn: 'root',
})
export class SeoService {
  constructor(private meta: Meta) {}

  /* updateTags(text) {
    this.meta.updateTag({ name: 'google-site-verification', content: text });
  } */

  /**
   * This method is used to singular SEO tag.
   * Angular Universal friendly.
   */
  updateTag(tag: MetaDefinition) {
    this.meta.updateTag(tag);
  }

  /**
   * This method is used to bulk update SEO tags.
   * Angular Universal friendly.
   */
  updateTags(tags: MetaDefinition[]): void {
    if (tags?.length) {
      tags.forEach((tag: MetaDefinition) => {
        this.meta.updateTag(tag);
      });
    }
  }
}
