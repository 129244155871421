import { get, map } from 'lodash';

interface IDeserializable {
  deserialize(input: any): this;
}

export class HomeBanner implements IDeserializable {
  id?: string;
  heading: string;
  subHeading: string;
  isActive?: boolean;
  imagesEn?: any;
  imagesFr?: any;
  page?: string;
  button?: HomeBannerButton;
  modal?: HomeBannerModal;
  cardsImageEn: any;
  cardsImageFr: any;
  gatewayImage: any;

  deserialize(input: any): this {
    this.id = get(input, 'id');
    this.heading = get(input, 'heading');
    this.subHeading = get(input, 'subHeading');
    this.isActive = get(input, 'isActive');
    this.imagesEn = get(input, 'imagesEn');
    this.imagesFr = get(input, 'imagesFr');

    if (get(input, 'button').isActive === true) {
      this.button = new HomeBannerButton().deserialize(get(input, 'button'));
    }

    if (get(input, 'modal').isActive === true) {
      this.modal = new HomeBannerModal().deserialize(get(input, 'modal'));
    }

    this.cardsImageEn = get(input, 'cardsImageEn');
    this.cardsImageFr = get(input, 'cardsImageFr');
    this.gatewayImage = get(input, 'gatewayImage');

    return this;
  }
}

export class HomeBannerButton implements IDeserializable {
  id?: string;
  isActive?: boolean;
  name?: string;
  url?: string;

  deserialize(input: any): this {
    this.id = get(input, 'id');
    this.isActive = get(input, 'isActive');
    this.name = get(input, 'name');
    this.url = get(input, 'url');

    return this;
  }
}

export class HomeBannerModal implements IDeserializable {
  id?: string;
  isActive?: boolean;
  name?: string;
  title?: string;
  bodyHeading?: string;
  bodySubHeading?: string;

  deserialize(input: any): this {
    this.id = get(input, 'id');
    this.isActive = get(input, 'isActive');
    this.name = get(input, 'name');
    this.title = get(input, 'title');
    this.bodyHeading = get(input, 'bodyHeading');
    this.bodySubHeading = get(input, 'bodySubHeading');

    return this;
  }
}
