import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { HomeBannerNotice } from '../../models/home-banner-notice';
import { HomeBannerNoticeService } from '../../services/home-banner-notice.service';

@Component({
  selector: 'myclubrewards-notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.scss'],
})
export class NoticeComponent implements OnInit {
  homeBannerNotice$: Observable<HomeBannerNotice>;

  constructor(private homeBannerNoticeService: HomeBannerNoticeService) {
    this.homeBannerNotice$ = this.homeBannerNoticeService.homeBannerNotice$;
  }

  ngOnInit(): void {}
}
